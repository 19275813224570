import Vue from 'vue'
import store from '@/store'

function list (params = {}) {
  const path = '/admin/recommendations/dataset-groups'
  return Vue.api.get(path, { params })
}

async function load (id) {
  const path = `/admin/recommendations/dataset-groups/${id}`
  const datasetGroup = await Vue.api.get(path)
  store.commit('SET_DATASET_GROUP', datasetGroup)
  return datasetGroup
}

function create (data = {}) {
  const path = '/admin/recommendations/dataset-groups'
  return Vue.api.post(path, { data })
}

function _delete (id) {
  const path = `/admin/recommendations/dataset-groups/${id}`
  return Vue.api.delete(path)
}

function products (datasetGroupId, params = {}) {
  const path = `/admin/recommendations/dataset-groups/${datasetGroupId}/products`
  return Vue.api.get(path, { params })
}

function goals (datasetGroupId, params = {}) {
  const path = `/admin/recommendations/dataset-groups/${datasetGroupId}/goals`
  return Vue.api.get(path, { params })
}

function categories (datasetGroupId, params = {}) {
  const path = `/admin/recommendations/dataset-groups/${datasetGroupId}/categories`
  return Vue.api.get(path, { params })
}

const datasets = {
  list (datasetGroupId, params = {}) {
    const path = `/admin/recommendations/dataset-groups/${datasetGroupId}/datasets`
    return Vue.api.get(path, { params })
  },

  load (datasetGroupId, id) {
    const path = `/admin/recommendations/dataset-groups/${datasetGroupId}/datasets/${id}`
    return Vue.api.get(path)
  },

  create (datasetGroupId, data = {}) {
    const path = `/admin/recommendations/dataset-groups/${datasetGroupId}/datasets`
    return Vue.api.post(path, { data })
  },

  delete (datasetGroupId, id) {
    const path = `/admin/recommendations/dataset-groups/${datasetGroupId}/datasets/${id}`
    return Vue.api.delete(path)
  },

  update (datasetGroupId, id, data = {}) {
    const path = `/admin/recommendations/dataset-groups/${datasetGroupId}/datasets/${id}`
    return Vue.api.patch(path, { data })
  }
}

export default {
  list,
  load,
  create,
  delete: _delete,
  products,
  goals,
  categories,
  datasets
}
