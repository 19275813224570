import actions from './user-notification.actions'
import getters from './user-notification.getters'
import mutations from './user-notification.mutations'
import state from './user-notification.state'

export default {
  actions,
  mutations,
  state,
  getters
}
