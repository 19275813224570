import { io } from 'socket.io-client'
import { sockets as socketsConfig } from '@/config'

const socketClient = io(socketsConfig.server, {
  path: socketsConfig.path,
  withCredentials: true,
  autoConnect: false
})

const SocketsPlugin = {
  install (Vue, options) {
    const socketPlugin = {
      client: socketClient,
      addListener (event, cb) {
        socketClient.on(event, cb)
      },
      removeListener (event) {
        socketClient.off(event)
      },
      emit (event, msg) {
        socketClient.emit(event, msg)
      },
      reconnect () {
        socketClient.disconnect()
        socketClient.connect()
      },
      addCatchAllListener (eventBus) {
        socketClient.onAny(eventBus)
      },
      removeCatchAllListeners () {
        socketClient.offAny()
      }
    }

    Vue.socket = socketPlugin
    Vue.prototype.$socket = socketPlugin
  },
  socketClient
}

export default SocketsPlugin
