<template>
  <div>
    <div class="notification-wrapper">
      <div :class="{ 'blue-dot': notification.isUnread }" />
      <div class="pfp">
        <task-notification-icon
          :status="iconStatus"
        />
      </div>
      <div class="content">
        <div class="header">
          <div class="flex items-left space-x-2">
            {{ getDateTime(notification.createdAt).date }}
          </div>
          <div class="date">
            {{ getDateTime(notification.createdAt).time }}
          </div>
        </div>
        <div
          class="text"
        >
          <div
            v-if="notification.notificationType==='task_assign'"
            class="text"
          >
            <span class="user-name">
              {{ notification.createdByUser.firstName + " " + notification.createdByUser.lastName + " " }}
            </span>
            assigned you a task {{ notification.dueDate ? 'due: '+ formatDate(notification.dueDate) :'' }}
          </div>
          <div v-else>
            <div v-if="isDueToday">
              <div class="text">
                <span class="user-name">
                  Due Today
                </span>
                your task is due today: {{ formatDate(notification.dueDate) }} &#10071;&#10071;
              </div>
            </div>
            <div
              v-else-if="isPastDue"
              class="text"
            >
              <span class="user-name">
                &#10071; Past Due &#10071;
              </span>
              Your task is past due: {{ formatDate(notification.dueDate) }}
            </div>
            <div v-else-if="isUpcoming">
              <div class="reminder-text">
                <b>Reminder</b> your task is due soon on : {{ formatDate(notification.dueDate) }}
              </div>
              <div class="reminder-text">
                <warning-icon
                  w="20"
                  h="20"
                  class="warning-icon"
                />
              </div>
            </div>
            <div v-else>
              You have a task due {{ formatDate(notification.dueDate) }}
            </div>
          </div>
          <a
            class="view-task"
            @click="goToTask"
          >View Task</a>
        </div>
      </div>
    </div>
    <div class="task-wrapper">
      <div
        :class="isExpanded?'expandable-box-show':'expandable-box'"
      >
        <p
          ref="taskBody"
          class="taskBody"
        >
          &#8220;{{ extractTextFromHtml(notification.details) }}&#8221;
        </p>
      </div>
      <div
        v-if="showExpandButton"
        class="expand-button"
        @click="toggleExpansion"
      >
        <condense-icon
          v-if="isExpanded"
          w="28"
          h="24"
        />
        <expand-icon
          v-else
          w="28"
          h="24"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ExpandIcon, CondenseIcon, TaskNotificationIcon, WarningIcon } from '@/components/icons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
export default {
  components: {
    ExpandIcon,
    CondenseIcon,
    TaskNotificationIcon,
    WarningIcon
  },
  props: {
    notification: {
      type: Object,
      required: false,
      default: null
    },
    showGroups: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isExpanded: false,
      showExpandButton: false,
      isPastDue: false,
      isUpcoming: false,
      isDueToday: false
    }
  },
  computed: {
    companyLogoURL: {
      get () {
        return this.$store.getters.getCompanyLogoURL
      }
    },
    iconStatus: {
      get () {
        if (this.notification.notificationType === 'task_assign') return 'default'
        if (this.isPastDue) return 'due'
        if (this.isUpcoming) return 'upcoming'
        if (this.isDueToday) return 'due'
        return 'default'
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const numLines = this.calculateNumLines()
      this.showExpandButton = numLines > 1
      if (this.notification.notificationType === 'task_remind') {
        const daysDifference = dayjs(this.notification.dueDate.split('T')[0]).diff(dayjs(this.notification.createdAt.split('T')[0]), 'day')
        switch (daysDifference) {
          case -3:
            this.isPastDue = true
            break
          case 0:
            this.isDueToday = true
            break
          case 7:
            this.isUpcoming = true
            break
        }
      }
    })
  },
  methods: {
    toggleExpansion () {
      this.isExpanded = !this.isExpanded
    },
    extractTextFromHtml (htmlString) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlString, 'text/html')
      const text = doc.body.textContent
      return text
    },
    goToTask () {
      this.$emit('user-task', { taskId: this.notification.notifiableId })
    },
    calculateNumLines () {
      const taskBody = this.$refs.taskBody
      if (taskBody) {
        const textHeight = taskBody.offsetHeight
        const lineHeight = parseFloat(window.getComputedStyle(taskBody).lineHeight)
        const numLines = Math.ceil(textHeight / lineHeight)
        return numLines
      }
      return 0
    },
    formatDate (datetime) {
      dayjs.extend(utc)
      dayjs.extend(timezone)
      return datetime && dayjs.tz(datetime, 'America/Toronto').format('MM/DD/YYYY')
    },
    getDateTime (timestamp) {
      const date = new Date(timestamp)

      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      const formattedDate = `${month}/${day}/${year}`
      const options = { hour: 'numeric', minute: 'numeric', hour12: true }
      const formattedDateTime = date.toLocaleString('en-US', options)
      return { date: formattedDate, time: formattedDateTime }
    }
  }
}
</script>

<style scoped>
.notification-wrapper{
  display: flex;
  padding-top:20px;
}
.pfp{
  height: auto;
  width: auto;
  margin-right:4%;
  border-radius: 50%;
}
.content{
  flex-grow:1;
}
.header{
  display:flex;
  justify-content: space-between;
  color: rgb(97, 91, 91);
}

.space-x-2 {
  column-gap: 0.5rem;
}
.text{
  padding: 10px 0 10px 0 ;
  max-width: 415px;
  line-height: 1.5;
}

.reminder-text{
  display: inline-block;
   vertical-align: middle;
}
.expandable-box {
  height: 33px;
  overflow: hidden;
  padding: 7px 22px 10px 10px;
  font-style: italic;
  line-height: 1.5;
}

.expandable-box-show {
  height: auto;
  overflow: hidden;
  padding: 10px 22px 10px 10px;
  font-style: italic;
  line-height: 1.5;

}
.task-wrapper{
  position: relative;
  left: 10%;
  margin-bottom: 10px;
  width: 90%;
  background-color: rgb(232, 232, 232);
}
.expand-button{
  position: absolute;
  top: 5px;
  left: 92%;
}
.user-name{
  font-weight: 600;
}
.blue-dot {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #2e76bc;
}
.view-task{
  cursor: pointer;
  text-decoration: underline;
}
.taskBody{
  line-height: 1.8;
}
.group-wrapper{
  display:flex;
  justify-content: space-between;
  color: rgb(97, 91, 91);
  font-style: italic;
  padding: 7px 22px 10px 40px;
  font-size: 13px;
}
.warning-icon {
fill: var(--text-warning-color);
line-height: 18px;
align-self: center;
margin-left: 5px;
margin-right: 5px;
}

</style>
