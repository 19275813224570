import Vue from 'vue'
import { pick, omit } from 'lodash-es'
import contactMethods from './contactMethods'
import comments from './comments'

function updateStoreList (response) {
  Vue.$store.state.Contact.list = response
  return response
}

function updateStoreContact (id) {
  return function (response) {
    const contacts = [...Vue.$store.state.Contact.list.data]
    const idx = contacts.findIndex((contact) => contact.id === id)
    contacts[idx] = response
    Vue.$store.state.Contact.list.data = contacts
    return response
  }
}

function create (params = {}) {
  const data = omit(params, ['companyId', 'companyGroupId'])
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api.post('/contacts', { params, data })
}

function list (params = {}) {
  return Vue.api.get('/contacts', { params })
    .then(updateStoreList)
}

function load (params = {}) {
  const { id } = params
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api
    .get(`/contacts/${id}`, { params })
    .then(updateStoreContact(id))
}

function update (params = {}) {
  const { id } = params
  const data = omit(params, ['id', 'companyId', 'companyGroupId'])
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api
    .patch(`/contacts/${id}`, { params, data })
    .then(updateStoreContact(id))
}

function _delete (params = {}) {
  const { id } = params
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api.delete(`/contacts/${id}`, { params })
}

export default {
  create,
  list,
  load,
  update,
  delete: _delete,
  contactMethods,
  comments
}
