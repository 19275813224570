<template>
  <div
    class="page-spinner-container"
    :style="containerStyle"
  >
    <div
      class="page-spinner"
      :style="spinnerStyle"
    />
  </div>
</template>

<script>

export default {
  name: 'LoadingSpinner',
  props: {
    size: {
      type: Number,
      default: 64
    },
    spinnerColor: {
      type: String,
      default: 'var(--spinner-color)'
    },
    openingColor: {
      type: String,
      default: 'var(--light-page-spinner-color)'
    },
    strokeWidth: {
      type: String,
      default: 'var(--page-spinner-border-width)'
    }
  },

  computed: {
    containerStyle () {
      return {
        height: this.size + 'px',
        width: this.size + 'px'
      }
    },

    spinnerStyle () {
      return {
        'border-width': this.strokeWidth,
        'border-style': 'solid',
        'border-right-color': this.spinnerColor,
        'border-left-color': this.spinnerColor,
        'border-bottom-color': this.spinnerColor,
        'border-top-color': this.openingColor
      }
    }
  },

  methods: {
  }
}
</script>

<style scoped>
.page-spinner-container {
    display: block;
    position: relative;
    width: var(--page-spinner-size);
    height: var(--page-spinner-size);
}

.page-spinner {
    content: "";
    position: absolute;
    left: calc(50% - (var(--button-main-spinner-size) / 2));
    top: calc(50% - (var(--button-main-spinner-size) / 2));
    width: var(--button-main-spinner-size);
    height: var(--button-main-spinner-size);

    border: var(--button-spinner-border-width) solid var(--main-button-default-spinner-color);
    border-right-color: var(--main-button-default-spinner-color);
    border-top-color: var(--light-button-default-spinner-color);

    border-radius: 50%;
    box-sizing: border-box;
    -webkit-animation: circleRotate 0.5s infinite linear;
    animation: circleRotate 0.5s infinite linear;
}

.page-spinner {
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
}

@-webkit-keyframes circleRotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes circleRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

</style>
