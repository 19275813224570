export default [
  {
    path: 'connect/authorize',
    name: 'AdminConnectAuthorize',
    component: () => import('@/views/admin/connect/Authorize.vue')
  },

  {
    path: 'connect/callback',
    name: 'AdminConnectCallback',
    component: () => import('@/views/admin/connect/Callback.vue')
  }
]
