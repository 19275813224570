<template>
  <label
    :class="classes"
    v-on="$listeners"
  >
    <slot />
  </label>
</template>

<script>
import { includes } from 'lodash-es'

const VARIANTS = [
  'default', 'checkbox', 'radio', 'medium-text', 'large-text'
]

export default {
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: type => includes(VARIANTS, type)
    }
  },

  computed: {
    classes: {
      get () {
        return ['label', 'lbl-' + this.variant]
      }
    }
  }
}
</script>

<style scoped>
label {
  font-family: var(--font-stack);
  color: var(--text-color);
}

label.lbl-default {
  display: block;
  font-size: 11px;
  line-height: 1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 5px;
}

label.lbl-checkbox,
label.lbl-radio,
label.lbl-medium-text {
  display: block;
  margin-left: 8px;
  position: relative;
  cursor: pointer;
  text-transform: none;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

label.lbl-large-text {
  display: block;
  margin-left: 8px;
  position: relative;
  cursor: pointer;
  text-transform: none;
  font-size: 13px;
  line-height: 16px;
  font-weight: 100;
}
</style>
