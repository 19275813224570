import ErrorTracking from '@advisr/frontend-ui-components/src/plugins/ErrorTracking'
import store from '@/store'
import { build } from '@/config'
const { version: buildVersion } = build

const ErrorTrackingPlugin = {
  async install (Vue, config) {
    const errorTracker = ErrorTracking.createErrorTracker(config)
    Vue.prototype.$errorTracking = errorTracker
    Vue.$errorTracking = errorTracker

    const uncaughtErrorHandler = (err) => {
      if (buildVersion !== store.state.currentVersion) {
        // User is running an out of date UI version
        // Show outdated error message
        store.commit('ADD_OUTDATED_ERROR_MESSAGE')

        // Log error anyway as a OutdatedError
        errorTracker.logError(new ErrorTracking.customErrors.OutdatedError(err))
      } else {
        // Unknown error, log
        errorTracker.logError(err)
      }
    }

    // Vue global event handler
    Vue.config.errorHandler = (err, vm, info) => {
      uncaughtErrorHandler(err)
    }

    // Window global event handler
    addEventListener('error', event => {
      uncaughtErrorHandler(event.error)
    })

    // Unhandled promise rejections
    addEventListener('unhandledrejection', event => {
      uncaughtErrorHandler(event.reason)
    })
  },

  customErrors: ErrorTracking.customErrors
}

export default ErrorTrackingPlugin
