const state = {
  list: {
    num: 0,
    campaigns: [],
    owners: [],
    teamMembers: [],
    statuses: []
  },
  campaign: {},
  sessions: {
    count: 0,
    users: 0,
    sessions: [],
    sessionCount: 0
  },
  audience: {
    categories: {
      campaignId: null,
      values: []
    },
    recommended: {
      campaignId: null,
      values: []
    },
    primary: {
      campaignId: null,
      values: []
    },
    available: {
      campaignId: null,
      values: []
    }
  },

  products: {
    primary: {},
    metadata: {},
    rankedAndRecommended: {}
  },

  geo: {
    recommended: {},
    primary: {}
  },

  campaignLocationArchives: [],
  new: {},
  submissionComment: '',
  externalLinks: [],
  campaignFields: {}
}

export default state
