export default [
  {
    path: 'client-categories',
    name: 'AdminClientCategories',
    component: () => import('@/views/admin/product/ClientCategories.vue'),
    meta: {
      headerText: 'Client Categories Admin',
      headerBackRoute: 'AdminHome'
    }
  }
]
