export default [
  {
    path: 'user',
    name: 'AdminUser',
    component: () => import('@/views/admin/user/List.vue'),
    meta: {
      headerText: 'User Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'user-admin'
    }
  },
  {
    path: 'user/edit/:id',
    name: 'AdminEditUser',
    component: () => import('@/views/admin/user/Edit.vue'),
    meta: {
      headerText: 'Edit User',
      headerBackRoute: 'AdminUser',
      subNav: 'none'
    }
  },
  {
    path: 'user/new',
    name: 'AdminNewUser',
    component: () => import('@/views/admin/user/New.vue'),
    meta: {
      headerText: 'New User',
      headerBackRoute: 'AdminUser',
      subNav: 'none'
    }
  }
]
