import backendConfig from './backendConfig'
import emailTemplateMappings from './emailTemplateMappings'
import externalIntegrationUserMappings from './externalIntegrationUserMappings'
import externalIntegrationUsers from './externalIntegrationUsers'
import externalSystems from './externalSystems'
import recommendations from './recommendations'
import tasks from './tasks'
import userNotificationTemplates from './userNotificationTemplates'
import userNotificationTypes from './userNotificationTypes'
import users from './users'

export default {
  backendConfig,
  emailTemplateMappings,
  externalIntegrationUserMappings,
  externalIntegrationUsers,
  externalSystems,
  recommendations,
  tasks,
  userNotificationTemplates,
  userNotificationTypes,
  users
}
