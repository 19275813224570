export default {

  methods: {
    processTaskDetails (cleanedTaskDetails) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = cleanedTaskDetails
      tempDiv.style.lineHeight = '1.7'
      const isFirstElementDiv = (tempDiv.firstChild && tempDiv.firstChild.nodeName.toLowerCase() === 'div')
      if (!isFirstElementDiv) {
        cleanedTaskDetails = tempDiv.outerHTML
      }
      const doc = new DOMParser().parseFromString(cleanedTaskDetails, 'text/html')
      doc.getElementsByTagName('div')[0].style.lineHeight = '1.7'
      const body = doc.body.innerHTML
      return body
    }

  }
}
