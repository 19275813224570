import Vue from 'vue'

function create (mediaAssetId, data = {}) {
  return Vue.api.post(`/media-assets/${mediaAssetId}/comments`, { data })
}

function list (mediaAssetId, data = {}) {
  return Vue.api.get(`/media-assets/${mediaAssetId}/comments`, { data })
}

function _delete (mediaAssetId, id) {
  return Vue.api.delete(`/media-assets/${mediaAssetId}/comments/${id}`)
}

export default {
  create,
  list,
  delete: _delete
}
