import Vue from 'vue'

function list (campaignId, params = {}) {
  const path = `/campaigns/${campaignId}/schedules`
  return Vue.api.get(path, { params })
}

function create (campaignId, data = {}) {
  const path = `/campaigns/${campaignId}/schedules`
  return Vue.api.post(path, { data })
}

function update (campaignId, scheduleId, data = {}) {
  const path = `/campaigns/${campaignId}/schedules/${scheduleId}`
  return Vue.api.patch(path, { data })
}

function _delete (campaignId, scheduleId) {
  const path = `/campaigns/${campaignId}/schedules/${scheduleId}`
  return Vue.api.delete(path)
}

function priority (campaignId, scheduleId, newPriority) {
  const path = `/campaigns/${campaignId}/schedules/${scheduleId}/priority`
  return Vue.api.post(path, { data: { newPriority } })
}

const outlets = {
  update (campaignId, scheduleId, outletId, data = {}) {
    const path = `/campaigns/${campaignId}/schedules/${scheduleId}/outlets/${outletId}`
    return Vue.api.patch(path, { data })
  }
}

export default {
  list,
  create,
  update,
  delete: _delete,
  priority,
  outlets
}
