import Vue from 'vue'

function list (params = {}) {
  const path = '/admin/tasks'
  return Vue.api.get(path, { params })
}

function load (id) {
  const path = `/admin/tasks/${id}`
  return Vue.api.get(path)
}

function requeue (taskId) {
  const path = `/admin/tasks/${taskId}/requeue`
  return Vue.api.post(path)
}

export default {
  list,
  load,
  requeue
}
