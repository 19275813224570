<template>
  <div class="messages">
    <div
      :class="'message message-'+(message.type)"
      @mouseover="onHover"
      @mouseout="onBlur"
    >
      <p class="message-text">
        {{ message.value }}
      </p>
      <div
        id="message_info_close"
        class="message-button"
      >
        <action-button
          type="Close"
          :variant="getVariant"
          datatest="message-close-button"
          class="close-button"
          @click="closeMessage"
        />

        <progress-ring
          v-if="shouldAutoClose"
          :radius="23"
          :stroke="3"
          :progress="autoClosePct"
          class="progress-ring"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProgressRing from './ProgressRing'

const AUTO_CLOSE_TIME = 2000
const AUTO_CLOSE_PROGRESS_INTERVAL = 50

export default {
  components: {
    ProgressRing
  },

  props: {
    message: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      autoCloseTimeLeft: AUTO_CLOSE_TIME,
      progressTimer: null
    }
  },

  computed: {
    getVariant: {
      get () {
        return this.message.type === 'info'
          ? 'primary'
          : (this.message.type === 'success' ? 'secondary' : 'danger')
      }
    },

    shouldAutoClose: {
      get () {
        return (this.message.type === 'success' || this.message.type === 'info')
      }
    },

    autoClosePct: {
      get () {
        return (AUTO_CLOSE_TIME - this.autoCloseTimeLeft) / AUTO_CLOSE_TIME * 100
      }
    }
  },

  mounted () {
    if (this.shouldAutoClose) {
      this.setAutoCloseInterval()
    }
  },

  methods: {
    setAutoCloseInterval () {
      if (this.autoCloseTimeLeft > 0) {
        this.progressTimer = setInterval(() => {
          this.autoCloseTimeLeft -= AUTO_CLOSE_PROGRESS_INTERVAL
          if (this.autoCloseTimeLeft <= 0) {
            clearInterval(this.progressTimer)
            this.progressTimer = null
            this.closeMessage()
          }
        }, AUTO_CLOSE_PROGRESS_INTERVAL)
      }
    },

    closeMessage () {
      if (this.progressTimer !== null) clearInterval(this.progressTimer)
      this.$emit('close', this.message)
    },

    onHover () {
      if (this.progressTimer !== null) clearInterval(this.progressTimer)
    },

    onBlur () {
      if (this.shouldAutoClose) {
        this.setAutoCloseInterval()
      }
    }
  }
}
</script>

<style scoped>
.messages {
  display: flex;
  align-items: center;
  justify-content: center;
}
.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 288px;
  max-width: 768px;
  padding: 15px 10px 15px 20px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.45);
  font-weight: 500;
  color: #fafafa;
}

.message-info {
  background-color: var(--message-info-background-color);
  white-space: pre-wrap;
}

.message-success {
  background-color: var(--message-success-background-color);
  white-space: pre-wrap;
}

.message-error {
  background-color: var(--message-danger-background-color);
  white-space: pre-wrap;
}

.message-button {
  position: relative;
  width: 36px;
  height: 36px;
}

.close-button {
  position: absolute;
  top: 2px;
  left: 0px;
  z-index: 2;
}

.progress-ring {
  position: absolute;
  top: -5px;
  left: -7px;
  z-index: 0;
}

.message-text {
  margin-right: 10px
}
</style>
