import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminListDBMigrations (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/list-db-migrations', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
