export default [
  {
    path: 'audience-preset',
    name: 'AdminAudiencePreset',
    component: () => import('@/views/admin/audience/AudiencePresets.vue'),
    meta: {
      headerText: 'Audience Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'audience-admin'
    }
  },
  {
    path: 'audience-configuration',
    name: 'AdminAudienceConfiguration',
    component: () => import('@/views/admin/audience/AudienceConfiguration.vue'),
    meta: {
      headerText: 'Audience Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'audience-admin'
    }
  }
]
