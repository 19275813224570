import axios from 'axios'
import config from '@/config'
import models from '@/models'
const baseURL = config.api.apiRoot

const ApiPlugin = {
  install (Vue, options) {
    const instance = axios.create({
      baseURL,
      timeout: (30 * 60 * 1000),
      withCredentials: true
    })

    function request (method, url, config = {}) {
      return instance.request({ method, url, ...config })
    }

    function get (...args) {
      return request('get', ...args)
        .then(res => res.data)
    }

    function post (...args) {
      return request('post', ...args)
        .then(res => res.data)
    }

    function put (...args) {
      return request('put', ...args)
        .then(res => res.data)
    }

    function patch (...args) {
      return request('patch', ...args)
        .then(res => res.data)
    }

    function destroy (...args) {
      return request('delete', ...args)
        .then(res => res.data)
    }

    const api = {
      get, post, put, patch, delete: destroy
    }

    Vue.api = api
    Vue.prototype.$api = models
  }
}

export default ApiPlugin
