<template>
  <svg
    :height="radius * 2"
    :width="radius * 2"
  >
    <circle
      :stroke="color"
      fill="transparent"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset }"
      :stroke-width="stroke"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</template>

<script>
export default {
  props: {
    radius: {
      type: Number,
      required: true
    },
    progress: {
      type: Number,
      required: true
    },
    stroke: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: 'white'
    }
  },

  data () {
    const normalizedRadius = this.radius - this.stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI

    return {
      normalizedRadius,
      circumference
    }
  },

  computed: {
    strokeDashoffset () {
      return this.circumference - this.progress / 100 * this.circumference
    }
  }
}
</script>
