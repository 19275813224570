import { branding } from '@/config'

export default {
  computed: {
    $branding () {
      for (const key in branding) {
        if (new RegExp(key).test(location.hostname)) {
          return branding[key]
        }
      }

      return branding.default
    }
  }
}
