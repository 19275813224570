class CustomError extends Error {
  constructor (type, error) {
    super(`${type}Error: ${error.message}`)
    this.original = error

    this.stack = this.stack.split('\n').slice(0, 2).join('\n') + '\n' + error.stack
  }
}

export default CustomError
