import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminLoadAudienceConfiguration (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/audience/load-configuration', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadAudienceValues (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/audience/load-audience', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadAudienceIndustryCategories (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/audience/load-industry-categories', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadPreset (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/audience/load-preset', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadDefaultPreset (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/audience/default-preset', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminUpdateAudienceConfiguration (context, configurationObj) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/audience/update-configuration', configurationObj)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminRestoreDefaults (context, configurationObj) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/audience/restore-defaults', configurationObj)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminSavePreset (context, presetObj) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/audience/save-preset', presetObj)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadDefaultAudience (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/audience/default-audience')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}
export default actions
