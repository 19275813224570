import actions from './company.actions'
import getters from './company.getters'
import mutations from './company.mutations'
import state from './company.state'

export default {
  actions,
  mutations,
  state,
  getters
}
