import Vue from 'vue'

function replace (mediaAssetId, companyGroupIds) {
  return Vue.api.patch(`/media-assets/${mediaAssetId}/groups`, { data: { companyGroupIds } })
}

function update (mediaAssetId, companyGroupId, data) {
  return Vue.api.patch(`/media-assets/${mediaAssetId}/groups/${companyGroupId}`, { data })
}

function _delete (mediaAssetId, companyGroupId) {
  return Vue.api.delete(`/media-assets/${mediaAssetId}/groups/${companyGroupId}`)
}

export default {
  replace,
  update,
  delete: _delete
}
