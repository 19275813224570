<template>
  <div>
    <div class="notification-wrapper">
      <div :class="{ 'blue-dot': notification.isUnread }" />
      <div class="pfp">
        <icon-link
          :icon="icon"
          w="30"
          h="30"
          :class="iconClass"
        />
      </div>
      <div class="content">
        <div class="header">
          <div class="flex items-left space-x-2">
            {{ getDateTime(notification.createdAt).date }}
          </div>
          <div class="date">
            {{ getDateTime(notification.createdAt).time }}
          </div>
        </div>
        <div class="text">
          <div
            v-if="notification.notificationType==='document_invitation_signed'"
            class="text"
          >
            <span class="bold-text">
              Signed! ✍️ {{ notification.email }}
            </span>
            has signed the
            <a
              class="campaign-name"
              @click="goToDocument"
            >
              {{ notification.campaignName }}
            </a>
            document!
          </div>
          <div
            v-if="notification.notificationType==='document_invitation_declined'"
            class="text"
          >
            <span class="bold-text">
              Attention! 🚨 {{ notification.email }}
            </span>
            has declined to sign the
            <a
              class="campaign-name"
              @click="goToDocument"
            >
              {{ notification.campaignName }}
            </a>
            document!
          </div>
          <div
            v-if="notification.notificationType==='document_complete'"
            class="text"
          >
            <span class="bold-text">
              Woohoo! 🎉
            </span>
            The
            <a
              class="campaign-name"
              @click="goToDocument"
            >
              {{ notification.campaignName }}
            </a>
            has been signed and completed!
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="notification.notificationType==='document_invitation_declined' && notification.reasonForDeclining"
      class="task-wrapper"
    >
      <div
        :class="isExpanded?'expandable-box-show':'expandable-box'"
      >
        <p
          ref="taskBody"
          class="task-body"
        >
          &#8220;{{ notification.reasonForDeclining }}&#8221;
        </p>
      </div>
      <div
        v-if="showExpandButton"
        class="expand-button"
        @click="toggleExpansion"
      >
        <condense-icon
          v-if="isExpanded"
          w="28"
          h="24"
        />
        <expand-icon
          v-else
          w="28"
          h="24"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CommentTaskNavigtion from '@/mixins/CommentTaskNavigation'
import { ExpandIcon, CondenseIcon } from '@/components/icons'

export default {
  components: {
    ExpandIcon,
    CondenseIcon
  },
  mixins: [
    CommentTaskNavigtion
  ],
  props: {
    notification: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isExpanded: false,
      showExpandButton: false
    }
  },
  computed: {
    icon: {
      get () {
        if (this.notification.notificationType === 'document_invitation_declined') return 'InviteDeclined'
        if (this.notification.notificationType === 'document_invitation_signed' || this.notification.notificationType === 'document_complete') return 'CircleCheck'
        return 'default'
      }
    },
    iconClass: {
      get () {
        if (this.notification.notificationType === 'document_invitation_declined') return 'circle-x-icon'
        if (this.notification.notificationType === 'document_invitation_signed' || this.notification.notificationType === 'document_complete') return ''
        return 'default'
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const numLines = this.calculateNumLines()
      this.showExpandButton = numLines > 1
    })
  },
  methods: {
    toggleExpansion () {
      this.isExpanded = !this.isExpanded
    },

    calculateNumLines () {
      const taskBody = this.$refs.taskBody
      if (taskBody) {
        const textHeight = taskBody.offsetHeight
        const lineHeight = parseFloat(window.getComputedStyle(taskBody).lineHeight)
        const numLines = Math.ceil(textHeight / lineHeight)
        return numLines
      }
      return 0
    },

    getDateTime (timestamp) {
      const date = new Date(timestamp)

      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      const formattedDate = `${month}/${day}/${year}`
      const options = { hour: 'numeric', minute: 'numeric', hour12: true }
      const formattedDateTime = date.toLocaleString('en-US', options)
      return { date: formattedDate, time: formattedDateTime }
    },

    goToDocument () {
      this.goToCampaignESignatureDocument({
        id: this.notification.campaignId,
        submissionId: this.notification.submissionId,
        documentType: this.notification.documentType,
        showWhere: this.notification.showWhere
      })

      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.notification-wrapper{
  display: flex;
  padding-top:20px;
}
.pfp{
  height: auto;
  width: auto;
  margin-right:4%;
  border-radius: 50%;
}
.content{
  flex-grow:1;
}
.header{
  display:flex;
  justify-content: space-between;
  color: rgb(97, 91, 91);
}

.space-x-2 {
  column-gap: 0.5rem;
}
.text{
  padding: 10px 0 10px 0 ;
  max-width: 415px;
  line-height: 1.5;
}

.blue-dot {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #2e76bc;
}
.circle-x-icon{
    padding-right: 0.5em;
    padding-top:0.1em
}
.circle-x-icon :deep(path) {
  fill: red;
}
.bold-text{
  font-weight: 600;
}
.campaign-name{
  cursor: pointer;
}
.expandable-box {
  height: 33px;
  overflow: hidden;
  padding: 7px 22px 10px 10px;
  font-style: italic;
  line-height: 1.5;
}

.expandable-box-show {
  height: auto;
  overflow: hidden;
  padding: 10px 22px 10px 10px;
  font-style: italic;
  line-height: 1.5;

}
.task-wrapper{
  position: relative;
  left: 10%;
  margin-bottom: 10px;
  width: 90%;
  background-color: rgb(232, 232, 232);
}
.expand-button{
  position: absolute;
  top: 5px;
  left: 92%;
}
.task-body{
  line-height: 1.8;
}
</style>
