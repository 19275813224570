<template>
  <a-link
    :to="to"
    :href="href"
    :target="target"
    :disabled="disabled"
    :datatest="datatest"
    v-on="$listeners"
    @router-click="clickedRouter"
  >
    <component
      :is="icons[icon + 'Icon']"
      :w="w"
      :h="h"
      :title="title"
      :class="classes"
    />
  </a-link>
</template>

<script>
import { isString, keys, includes } from 'lodash-es'
import * as Icons from '@/components/icons'

const VARIANTS = new Set(['default', 'black'])

export default {
  components: Object.assign({}, Icons),

  props: {
    datatest: {
      type: String,
      default: ''
    },

    icon: {
      type: String,
      required: true,
      validator (value) {
        return includes(keys(Icons), value + 'Icon')
      }
    },

    w: {
      type: String,
      required: true
    },

    h: {
      type: String,
      required: true
    },

    title: {
      type: String,
      default: null
    },

    to: {
      type: [String, Object],
      default: null
    },

    href: {
      type: String,
      default: null,
      validator (value) {
        return value === null || isString(value)
      }
    },

    target: {
      type: String,
      default: '',
      validator (value) {
        return includes(['_blank', 'blank', ''], value)
      }
    },

    disabled: {
      type: Boolean,
      default: false
    },

    variant: {
      type: String,
      default: 'default',
      validator: variant => VARIANTS.has(variant)
    }
  },

  computed: {
    icons: {
      get () {
        return Icons
      }
    },
    classes: {
      get () {
        return VARIANTS.has(this.variant) && this.variant !== 'default'
          ? ['icon', `icon-${this.variant}`]
          : ['icon']
      }
    }
  },

  methods: {
    clickedRouter () {
      this.$emit('router-click')
    }
  }
}
</script>

<style scoped>
.icon {
  fill: var(--link-color) !important;
  cursor: pointer;
}

.icon:hover {
  fill: var(--link-active-color);
}

.icon-black {
  fill: var(--text-color) !important;
  cursor: pointer;
}

.icon-black:hover {
  fill: var(--text-help-color);
}
</style>
