export default {
  looker: {
    instance: 'reporting.beta'
  },
  hubspot: {
    portalId: '20711712'
  },
  walkme: {
    enabled: true,
    environment: 'test'
  }
}
