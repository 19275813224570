const state = {
  filters: {
    companyId: null,
    companyGroupId: null
  },
  filterOptions: {
    companies: null,
    companyGroups: null
  },
  client: {
    activeTab: 'WorkspaceMyClients',
    pagination: {
      limit: 10,
      currentPage: 1
    },
    sort: {
      col: 'lastUpdated',
      desc: true
    },
    searchStr: '',
    filters: {
      owner: [],
      team: []
    }
  },
  campaign: {
    activeTab: {
      name: 'WorkspaceMyCampaigns',
      params: {}
    },
    pagination: {
      limit: 10,
      currentPage: 1
    },
    sort: {
      col: 'updatedAt',
      desc: true
    },
    searchStr: '',
    filters: {
      owner: [],
      team: [],
      status: [],
      test: [],
      primary: []
    }
  },
  contact: {
    activeTab: 'WorkspaceMyContacts',
    pagination: {
      limit: 10,
      page: 1
    },
    sort: {
      sortField: 'firstName',
      sortDirection: 'asc'
    },
    search: '',
    filters: {}
  },
  requestForProposal: {
    activeTab: 'WorkspaceMyRequestForProposals',
    pagination: {
      limit: 10,
      page: 1
    },
    sort: {
      sortField: 'name',
      sortDirection: 'asc'
    },
    search: '',
    filters: {}
  },
  agency: {
    activeTab: 'WorkspaceAllAgencies',
    pagination: {
      limit: 10,
      currentPage: 1
    },
    sort: {
      col: 'updatedAt',
      desc: true
    },
    searchStr: '',
    filters: {
      status: [
        {
          text: 'Active',
          value: true
        }
      ]
    }
  }
}
export default state
