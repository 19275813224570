import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminListExternalApiAccess (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/external-api-access/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateExternalApiAccess (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/external-api-access/create', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateExternalApiAccess (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/external-api-access/update', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
