const mutations = {
  SET_EXTERNAL_OPPORTUNITY (state, opportunity) {
    if (opportunity === undefined) {
      delete state.opportunity
    } else {
      state.opportunity = opportunity
    }
  },

  SET_EXTERNAL_OPPORTUNITY_PARAMS (state, opportunityParams) {
    if (opportunityParams === undefined) {
      delete state.opportunityParams
    } else {
      state.opportunityParams = opportunityParams
    }
  },

  SET_EXTERNAL_ACCOUNT (state, account) {
    if (account === undefined) {
      delete state.account
    } else {
      state.account = account
    }
  },

  SET_EXTERNAL_ACCOUNT_PARAMS (state, accountParams) {
    if (accountParams === undefined) {
      delete state.accountParams
    } else {
      state.accountParams = accountParams
    }
  },

  SET_EXTERNAL_CRM (state, crmName) {
    state.crmName = crmName
  }
}

export default mutations
