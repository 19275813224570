<template>
  <svg
    class="star-icon"
    :class="{ animate }"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="outer-border"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.99135 4.37427C5.55484 3.59165 5.75 2.62998 5.75 2H7.25C7.25 4.23789 9.11464 5.75 11 5.75V7.25C9.54808 7.25 8.65888 7.87019 8.10024 8.63128C7.51693 9.42599 7.28091 10.4006 7.24906 11.0375L5.75 11C5.75 9.44444 5.11865 8.5446 4.37427 8.00865C3.59165 7.44516 2.62998 7.25 2 7.25V5.75C3.55556 5.75 4.4554 5.11865 4.99135 4.37427ZM4.83669 6.52288C4.97693 6.60455 5.11534 6.69387 5.25073 6.79135C5.76193 7.15941 6.21957 7.63708 6.56759 8.23896C6.66499 8.07087 6.77254 7.90512 6.89101 7.74371C7.24878 7.2563 7.70336 6.81371 8.26642 6.47004C7.57277 6.04842 6.96675 5.46744 6.52334 4.7557C6.42945 4.92366 6.32485 5.08933 6.20865 5.25073C5.85614 5.74032 5.4031 6.18079 4.83669 6.52288Z"
      fill="#2E76BC"
    />
    <path
      class="inner-star-one"
      d="M4.70749 6.30886L4.34668 6.52677L4.71092 6.73889C4.84448 6.81668 4.9761 6.90163 5.10469 6.99421C5.5898 7.34349 6.02241 7.79544 6.3512 8.36408L6.56739 8.73798L6.78393 8.36429C6.87706 8.20358 6.97972 8.0454 7.09259 7.89162C7.43325 7.42751 7.86442 7.0083 8.39671 6.68341L8.74705 6.46958L8.39631 6.25639C7.7343 5.854 7.1572 5.30026 6.73557 4.62348L6.51171 4.26416L6.30515 4.6337C6.21569 4.79376 6.11617 4.95134 6.00581 5.10463C5.67126 5.56927 5.24257 5.9857 4.70749 6.30886Z"
      fill="#FBAA36"
    />
    <path
      class="outer-border"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7791 9.89177C12.8756 8.27375 13.25 6.30546 13.25 5H14.75C14.75 9.67988 18.4083 12.75 22 12.75V14.25C19.2379 14.25 17.4893 15.5183 16.3871 17.1138C15.2613 18.7433 14.8109 20.7236 14.7492 22.0353L13.25 22C13.25 18.8258 12.0194 16.9241 10.5443 15.7957C9.03387 14.6402 7.20374 14.25 6 14.25V12.75C8.93585 12.75 10.7098 11.4698 11.7791 9.89177ZM9.65642 13.5471C10.2687 13.8161 10.8795 14.1635 11.4557 14.6043C12.561 15.4499 13.517 16.6234 14.1102 18.1987C14.3808 17.5404 14.7241 16.882 15.1529 16.2612C15.9486 15.1094 17.0386 14.0912 18.4835 13.4518C16.5665 12.5546 14.9263 10.9414 14.0157 8.81737C13.7638 9.46837 13.4371 10.1191 13.0209 10.7332C12.2351 11.8928 11.1372 12.9092 9.65642 13.5471Z"
      fill="#2E76BC"
    />
    <path
      class="inner-star-two"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.55746 13.3175L9.02979 13.5449L9.55581 13.776C10.1516 14.0378 10.7449 14.3754 11.3038 14.8029C12.3745 15.622 13.3006 16.7582 13.8762 18.2868L14.1 18.881L14.3414 18.2937C14.6061 17.6498 14.9412 17.0075 15.3586 16.4033C16.1326 15.2829 17.1886 14.2981 18.5846 13.6804L19.0869 13.4581L18.5894 13.2253C16.7264 12.3534 15.1311 10.7848 14.2454 8.71886L14.0038 8.15527L13.7825 8.72714C13.5365 9.36275 13.2182 9.99629 12.8139 10.593C12.0512 11.7185 10.9887 12.7009 9.55746 13.3175Z"
      fill="#FBAA36"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 24
    },

    animate: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.star-icon .outer-border {
  fill: #2E76BC;
}

.star-icon .inner-star-one,
.star-icon .inner-star-two {
  fill: none;
}

@keyframes innerStarOneColorFade {
  0% {
    fill: #2E76BC;
  }
  50% {
    fill: #FFFFFF;
  }
  100% {
    fill: #2E76BC;
  }
}

.star-icon.animate .inner-star-one {
  animation: innerStarOneColorFade .4s infinite ease;
}

@keyframes innerStarTwoColorFade {
  0% {
    fill: #FFFFFF;
  }
  50% {
    fill: #2E76BC;
  }
  100% {
    fill: #FFFFFF;
  }
}

.star-icon.animate .inner-star-two {
  animation: innerStarTwoColorFade .4s infinite ease;
}
</style>
