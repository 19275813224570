const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})

const currencyFormatterDecimalsNoMinNoMax = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 20
})

const currencyFormatterForceTwoDecimalsNoMax = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 20
})

const CurrencyFormattersPlugin = {
  install (Vue, options) {
    Vue.prototype.$currency = {
      format (value, forceTwoDecimals = false, noMinNoMax = false) {
        if (value === null || value === undefined || value === '') return null

        if (noMinNoMax) return currencyFormatterDecimalsNoMinNoMax.format(value)

        if (forceTwoDecimals) return currencyFormatterForceTwoDecimalsNoMax.format(value)

        return currencyFormatter.format(value)
      },

      unformat (value) {
        return Number(value.replace(/[^0-9.-]+/g, ''))
      }
    }

    Vue.filter('currency', (value) => {
      if (value === null || value === undefined) return null
      return currencyFormatter.format(value)
    })
  }
}

export default CurrencyFormattersPlugin
