<template>
  <div>
    <transition name="slide-from-left">
      <div
        v-if="show"
        class="side-nav expanded"
      >
        <div class="bookmarks-holder">
          <div class="header px-4 d-flex justify-between">
            <h4>Bookmarks <span class="ml-1 count rounded-full text-base text-center">{{ bookmarks.length }}</span></h4>
            <icon-link
              icon="DoubleLeftArrow"
              w="24"
              h="24"
              class="double-left-arrow-icon mt-4"
              @click="close"
            />
          </div>
          <div
            class="list"
          >
            <div
              v-for="bm in bookmarks"
              :key="bm.id"
              class="item px-4 d-flex w-100 justify-between"
            >
              <div>
                <img
                  v-if="!bm.logoUrl"
                  src="/images/bookmark-default.svg"
                >
                <img
                  v-else
                  :src="bm.logoUrl"
                >
                <h4 class="text-sm">
                  <a-tooltip
                    v-if="bm.description"
                    position="right"
                    class="bookmark-tooltip"
                  >
                    <template #tip>
                      {{ bm.description }}
                    </template>
                    <template #hover-item>
                      {{ bm.name }}
                    </template>
                  </a-tooltip>

                  <span
                    v-else
                  >
                    {{ bm.name }}
                  </span>
                </h4>
              </div>
              <a-link
                :href="bm.url"
                target="_blank"
                rel="noopener noreferrer"
                class="external-bookmark-link "
              >
                <external-link-icon
                  w="22"
                  h="22"
                  :title="'bookmark'"
                />
              </a-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="show"
        class="overlay"
        @click="close"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ExternalLinkIcon } from '@/components/icons'

export default {
  components: {
    ExternalLinkIcon
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      bookmarks: []
    }
  },
  async created () {
    this.bookmarks = await this.listBookmarks()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    ...mapActions([
      'listBookmarks'
    ])
  }
}
</script>

<style scoped>
  .side-nav {
    z-index: 99;
    background-color: white;
    position: fixed;
    top: 60px;
    left: 55px;
    height: calc(100% - 60px);
  }
  .header {
    border-bottom: 1px solid var(--border-color);
  }
  .header h4 {
    line-height: 60px;
  }
  .count {
    background-color: #eff0f1;
    display: inline-block;
    width: 22px;
    height: 22px;
  }
  .double-left-arrow-icon {
    margin-right: -29px;
  }
  .overlay {
    position: fixed;
    top: 60px;
    left: 55px;
    width: 100%;
    height: 100%;
    z-index: 98;
    background-color:  rgba(107, 114, 128, .6);
  }
  .list {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
  .list .item {
    border-bottom: 1px solid var(--border-color);
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
  }
  .list .item h4 {
    display: inline-block;
    margin-left: 10px;
    width: 225px;
  }
  .list .item img {
    object-fit: cover;
    object-position: top center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    float: left;
    margin-top: 14px;
  }
  .external-bookmark-link {
    margin-top: 3px;
    float: right;
  }
  .bookmarks-holder {
    height: 100%;
  }

  .bookmark-tooltip {
    display: inline-block
  }
</style>
