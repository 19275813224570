import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminGetDashboardSettings (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/dashboard/load', { params: { companyId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateDashboardSettings (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/dashboard/update', data)
        .then(response => {
          context.commit('SET_COMPANY_DASHBOARD_SETTINGS', { showActivityTab: response.body?.showActivityTab })
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
