import Vue from 'vue'
import groups from './groups'
import comments from './comments'
import tags from './tags'

function list (params = {}) {
  return Vue.api
    .get('/media-assets', { params })
}

function generateLink (params = {}) {
  const { id } = params

  const path = `/media-assets/${id}/link`

  return Vue.api
    .get(path)
}

function create (data = {}, isFile = false) {
  const postData = {
    data
  }

  if (isFile) {
    postData.headers = { 'Content-Type': 'multipart/form-data' }
  }

  return Vue.api
    .post('/media-assets', postData)
}

function update (mediaAssetId, data) {
  return Vue.api
    .patch(`/media-assets/${mediaAssetId}`, { data })
}

function _delete (mediaAssetId) {
  return Vue.api
    .delete(`/media-assets/${mediaAssetId}`)
}

export default {
  list,
  generateLink,
  create,
  update,
  delete: _delete,
  groups,
  comments,
  tags
}
