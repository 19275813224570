<template>
  <nav
    class="flex-row justify-start d-flex align-items-center"
    aria-label="Breadcrumb"
  >
    <ol
      role="list"
      class="d-flex breadcrumb-list align-items-center"
    >
      <li
        v-for="(link, linkIdx) in links"
        :key="linkIdx"
        class="d-flex align-items-center"
      >
        <svg
          v-if="linkIdx > 0"
          class=""
          style="height: 16px; width: 16px; color: grey; margin: 0 8px;"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <a-link
          class="d-flex align-items-center"
          :href="link.to"
        >
          {{ link.text }}
        </a-link>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: {
    links: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.breadcrumb-list {
  padding: 0;
  list-style-type: none;
  list-style-position: outside;
}

</style>
