export default [
  {
    path: 'bookmarks',
    name: 'AdminBookmarks',
    component: () => import('@/views/admin/bookmarks/Index.vue'),
    meta: {
      headerText: 'Admin Bookmarks',
      headerBackRoute: 'AdminHome'
    }
  }
]
