export default [
  {
    path: 'submission',
    name: 'AdminSubmission',
    component: () => import('@/views/admin/submission/Index.vue'),
    children: [
      {
        path: 'list',
        alias: '',
        name: 'AdminSubmissionList',
        component: () => import('@/views/admin/submission/List.vue'),
        meta: {
          headerText: 'Submissions Admin',
          headerBackRoute: 'AdminHome'
        }
      },
      {
        path: 'new',
        name: 'AdminSubmissionNew',
        component: () => import('@/views/admin/submission/New.vue'),
        meta: {
          headerText: 'New Submission',
          headerBackRoute: 'AdminSubmissionList',
          subNav: 'none'
        }
      },
      {
        path: 'edit/:id',
        name: 'AdminSubmissionEdit',
        component: () => import('@/views/admin/submission/Edit.vue'),
        meta: {
          headerText: 'Edit Submission',
          headerBackRoute: 'AdminSubmissionList',
          subNav: 'none'
        }
      },
      {
        path: 'form',
        name: 'AdminForms',
        component: () => import('@/views/admin/form/Index.vue'),
        children: [
          {
            path: 'list',
            alias: '',
            name: 'AdminFormList',
            component: () => import('@/views/admin/form/List.vue'),
            meta: {
              headerText: 'Form Admin',
              headerBackRoute: 'AdminHome'
            }
          },
          {
            path: 'new',
            name: 'AdminFormNew',
            component: () => import('@/views/admin/form/New.vue'),
            meta: {
              headerText: 'New Form',
              headerBackRoute: 'AdminFormList',
              subNav: 'none'
            }
          },
          {
            path: 'edit/:id',
            name: 'AdminFormEdit',
            component: () => import('@/views/admin/form/Edit.vue'),
            meta: {
              headerText: 'Edit Form',
              headerBackRoute: 'AdminFormList',
              subNav: 'none'
            }
          },
          {
            path: 'view/:id',
            name: 'AdminFormView',
            component: () => import('@/views/admin/form/View.vue'),
            meta: {
              headerText: 'View Form',
              headerBackRoute: 'AdminFormList',
              subNav: 'none'
            }
          }
        ]
      },

      {
        path: 'fulfillment',
        name: 'AdminFulfillment',
        component: () => import('@/views/admin/fulfillment/Index.vue'),
        children: [
          {
            path: 'list',
            alias: '',
            name: 'AdminFulfillmentModuleList',
            component: () => import('@/views/admin/fulfillment/List.vue'),
            meta: {
              headerText: 'Fulfillment Admin',
              headerBackRoute: 'AdminHome'
            }
          },
          {
            path: 'new',
            name: 'AdminFulfillmentModuleNew',
            component: () => import('@/views/admin/fulfillment/New.vue'),
            meta: {
              headerText: 'New Fulfillment Module',
              headerBackRoute: 'AdminFulfillmentModuleList',
              subNav: 'none'
            }
          },
          {
            path: 'edit/:id',
            name: 'AdminFulfillmentModuleEdit',
            component: () => import('@/views/admin/fulfillment/Edit.vue'),
            meta: {
              headerText: 'Edit Fulfillment Module',
              headerBackRoute: 'AdminFulfillmentModuleList',
              subNav: 'none'
            }
          }
        ]
      },

      {
        path: 'vendors',
        name: 'AdminVendors',
        component: () => import('@/views/admin/vendor/Index.vue'),
        children: [
          {
            path: 'list',
            alias: '',
            name: 'AdminVendorList',
            component: () => import('@/views/admin/vendor/List.vue'),
            meta: {
              headerText: 'Vendor Admin',
              headerBackRoute: 'AdminHome'
            }
          },
          {
            path: 'new',
            name: 'AdminVendorNew',
            component: () => import('@/views/admin/vendor/New.vue'),
            meta: {
              headerText: 'New Vendor',
              headerBackRoute: 'AdminVendorList',
              subNav: 'none'
            }
          },
          {
            path: 'edit/:id',
            name: 'AdminVendorEdit',
            component: () => import('@/views/admin/vendor/Edit.vue'),
            meta: {
              headerText: 'Edit Vendor',
              headerBackRoute: 'AdminVendorList',
              subNav: 'none'
            }
          }
        ]
      },
      {
        path: 'api-logs',
        name: 'AdminAPILogs',
        component: () => import('@/views/admin/apilogs/ApiLogs.vue'),
        meta: {
          headerText: 'Submissions API Logs',
          headerBackRoute: 'AdminHome'
        }
      }
    ],
    meta: {
      subNav: 'submission-admin'
    }
  }
]
