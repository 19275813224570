const mutations = {
  /**
   * Generic workspace filters
   */
  SET_WORKSPACE_COMPANY_FILTER (state, companyId) {
    state.filters.companyId = companyId
  },

  SET_WORKSPACE_COMPANY_GROUP_FILTER (state, companyGroupId) {
    state.filters.companyGroupId = companyGroupId
  },

  SET_WORKSPACE_COMPANY_FILTER_OPTIONS (state, companies) {
    state.filterOptions.companies = companies
  },

  SET_WORKSPACE_COMPANY_GROUP_FILTER_OPTIONS (state, companyGroups) {
    state.filterOptions.companyGroups = companyGroups
  },

  /**
   * Client workspace filters
   */
  SET_WORKSPACE_CLIENT_ACTIVE_TAB (state, activeTab) {
    state.client.activeTab = activeTab
  },

  SET_WORKSPACE_CLIENT_LIMIT (state, limit) {
    state.client.pagination.limit = limit
  },

  SET_WORKSPACE_CLIENT_CURRENT_PAGE (state, currentPage) {
    state.client.pagination.currentPage = currentPage
  },

  SET_WORKSPACE_CLIENT_SORT (state, sort) {
    state.client.sort = sort
  },

  SET_WORKSPACE_CLIENT_SEARCH (state, searchStr) {
    state.client.searchStr = searchStr
  },

  SET_WORKSPACE_CLIENT_FILTERS (state, filters) {
    state.client.filters = filters
  },

  RESET_WORKSPACE_CLIENT (state) {
    state.client.pagination = {
      limit: 10,
      currentPage: 1
    }
    state.client.sort = {
      col: 'lastUpdated',
      desc: true
    }
    state.client.searchStr = ''
    state.client.filters = {
      owner: [],
      team: []
    }
  },

  /**
   * Campaign workspace filters
   */
  SET_WORKSPACE_CAMPAIGN_ACTIVE_TAB (state, activeTab) {
    state.campaign.activeTab = activeTab
  },

  SET_WORKSPACE_CAMPAIGN_LIMIT (state, limit) {
    state.campaign.pagination.limit = limit
  },

  SET_WORKSPACE_CAMPAIGN_CURRENT_PAGE (state, currentPage) {
    state.campaign.pagination.currentPage = currentPage
  },

  SET_WORKSPACE_CAMPAIGN_SORT (state, sort) {
    state.campaign.sort = sort
  },

  SET_WORKSPACE_CAMPAIGN_SEARCH (state, searchStr) {
    state.campaign.searchStr = searchStr
  },

  SET_WORKSPACE_CAMPAIGN_FILTERS (state, filters) {
    state.campaign.filters = filters
  },

  RESET_WORKSPACE_CAMPAIGN (state) {
    state.campaign.pagination = {
      limit: 10,
      currentPage: 1
    }
    state.campaign.sort = {
      col: 'updatedAt',
      desc: true
    }
    state.campaign.searchStr = ''
    state.campaign.filters = {
      owner: [],
      team: [],
      status: [],
      test: [],
      primary: []
    }
  },

  /**
   * Contact workspace filters
   */
  SET_WORKSPACE_CONTACT_ACTIVE_TAB (state, activeTab) {
    state.contact.activeTab = activeTab
  },

  SET_WORKSPACE_CONTACT_LIMIT (state, limit) {
    state.contact.pagination.limit = limit
  },

  SET_WORKSPACE_CONTACT_CURRENT_PAGE (state, page) {
    state.contact.pagination.page = page
  },

  SET_WORKSPACE_CONTACT_SORT (state, sort) {
    state.contact.sort = sort
  },

  SET_WORKSPACE_CONTACT_SEARCH (state, search) {
    state.contact.search = search
  },

  SET_WORKSPACE_CONTACT_FILTERS (state, filters) {
    state.contact.filters = filters
  },

  RESET_WORKSPACE_CONTACT (state) {
    state.contact.pagination = {
      limit: 10,
      page: 1
    }
    state.contact.sort = {
      sortField: 'firstName',
      sortDirection: 'asc'
    }
    state.contact.search = ''
    state.contact.filters = {}
  },

  /**
   * Request For Proposal workspace filters
   */
  SET_WORKSPACE_REQUEST_FOR_PROPOSAL_ACTIVE_TAB (state, activeTab) {
    state.requestForProposal.activeTab = activeTab
  },

  SET_WORKSPACE_REQUEST_FOR_PROPOSAL_LIMIT (state, limit) {
    state.requestForProposal.pagination.limit = limit
  },

  SET_WORKSPACE_REQUEST_FOR_PROPOSAL_CURRENT_PAGE (state, page) {
    state.requestForProposal.pagination.page = page
  },

  SET_WORKSPACE_REQUEST_FOR_PROPOSAL_SORT (state, sort) {
    state.requestForProposal.sort = sort
  },

  SET_WORKSPACE_REQUEST_FOR_PROPOSAL_SEARCH (state, search) {
    state.requestForProposal.search = search
  },

  SET_WORKSPACE_REQUEST_FOR_PROPOSAL_FILTERS (state, filters) {
    state.requestForProposal.filters = filters
  },

  RESET_WORKSPACE_REQUEST_FOR_PROPOSAL (state) {
    state.requestForProposal.pagination = {
      limit: 10,
      page: 1
    }
    state.requestForProposal.sort = {
      sortField: 'name',
      sortDirection: 'asc'
    }
    state.requestForProposal.search = ''
    state.requestForProposal.filters = {}
  },

  /**
   * Agency workspace filter
   */
  SET_WORKSPACE_AGENCY_ACTIVE_TAB (state, activeTab) {
    state.agency.activeTab = activeTab
  },

  SET_WORKSPACE_AGENCY_LIMIT (state, limit) {
    state.agency.pagination.limit = limit
  },

  SET_WORKSPACE_AGENCY_CURRENT_PAGE (state, currentPage) {
    state.agency.pagination.currentPage = currentPage
  },

  SET_WORKSPACE_AGENCY_SORT (state, sort) {
    state.agency.sort = sort
  },

  SET_WORKSPACE_AGENCY_SEARCH (state, searchStr) {
    state.agency.searchStr = searchStr
  },

  SET_WORKSPACE_AGENCY_FILTERS (state, filters) {
    state.agency.filters = filters
  },

  RESET_WORKSPACE_AGENCY (state) {
    state.agency.pagination = {
      limit: 10,
      currentPage: 1
    }
    state.agency.sort = {
      col: 'updatedAt',
      desc: true
    }
    state.agency.searchStr = ''
    state.agency.filters = {
      status: [
        {
          text: 'Active',
          value: true
        }
      ]
    }
  }
}
export default mutations
