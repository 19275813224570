<template>
  <router-link
    v-if="to !== null && to !== undefined"
    :to="to"
    :disabled="disabled"
    :class="classes"
    :data-test="datatest"
    @click.native="clickedRouter"
  >
    <slot />
  </router-link>

  <a
    v-else
    :href="fullHref"
    :target="target"
    :disabled="disabled"
    :class="classes"
    :data-test="datatest"
    v-on="eventListeners"
  >
    <slot />
  </a>
</template>

<script>
import { isString } from 'lodash-es'

export default {
  props: {
    datatest: {
      type: String,
      default: ''
    },

    to: {
      type: [String, Object],
      default: null
    },

    href: {
      type: String,
      default: null,
      validator (value) {
        return value === null || isString(value)
      }
    },

    disabled: {
      type: Boolean,
      default: false
    },

    showUnderline: {
      type: Boolean,
      default: false
    },

    preventDefault: {
      type: Boolean,
      default: false
    },

    target: {
      type: String,
      default: null
    }
  },

  computed: {
    fullHref: {
      get () {
        if (this.href === null) return '#'
        return this.href
      }
    },

    eventListeners: {
      get () {
        return Object.assign({},
          this.$listeners,
          {
            click: this.onClick
          }
        )
      }
    },

    classes: {
      get () {
        if (this.disabled) return ['disabled']
        if (this.showUnderline) return ['underline']
        return []
      }
    }
  },

  methods: {
    onClick (e) {
      if (this.disabled || this.href === null || this.preventDefault) {
        e.stopPropagation()
        e.stopImmediatePropagation()
        e.preventDefault()
      }

      this.$emit('click', e)
    },

    clickedRouter () {
      this.$emit('router-click')
    }
  }
}

</script>

<style scoped>
.underline {
  text-decoration: underline;
}
</style>
