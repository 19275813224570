import Vue from 'vue'

function update (campaignId, data) {
  const path = `/campaigns/${campaignId}/product-selection`
  return Vue.api.put(path, { data })
}

export default {
  update
}
