const mutations = {

  /**
   * @param state
   * @param categories
   */
  SET_INDUSTRY_CATEGORIES (state, categories) {
    state.industryCategories = categories
  },

  /**
   * @param state
   * @param companyId
   */
  SET_INDUSTRY_CATEGORIES_COMPANY_ID (state, companyId) {
    state.industryCategoriesCompanyId = companyId
  },

  /**
   * @param state
   * @param externalIndustryCategories
   */
  SET_All_EXTERNAL_INDUSTRY_CATEGORIES (state, externalIndustryCategories) {
    state.externalIndustryCategories = externalIndustryCategories
  },

  /**
   * @param state
   * @param companyId
   */
  SET_EXTERNAL_INDUSTRY_CATEGORIES_COMPANY_ID (state, companyId) {
    state.externalIndustryCategoriesCompanyId = companyId
  },

  /**
   * @param state
   * @param values
   */
  SET_CAMPAIGN_OBJECTIVES (state, values) {
    state.campaignObjectives = values
  }

}

export default mutations
