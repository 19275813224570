import state from './external.state'
import mutations from './external.mutations'
import actions from './external.actions'
import getters from './external.getters'

export default {
  state,
  mutations,
  actions,
  getters
}
