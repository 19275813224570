export default [
  {
    path: 'styles/:entity',
    name: 'AdminStyles',
    component: () => import('@/views/admin/company/Styles.vue'),
    meta: {
      headerText: 'Edit Global Styles',
      headerBackRoute: 'AdminHome',
      subNav: 'styles-admin'
    }
  },
  {
    path: 'styles-preview',
    name: 'StylesPreview',
    component: () => import('@/views/admin/company/StylesPreview.vue'),
    props: true
  }
]
