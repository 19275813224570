const state = {
  imageUploadMethod: null,
  imageDownloadMethod: null,
  imageUploadParams: {},
  templates: [],
  slides: [],
  deletedSlides: [],
  productImages: {},
  templateName: null,
  slideName: null,
  layouts: []
}

export default state
