export default [
  {
    path: 'db-migrations/:database',
    name: 'AdminDBMigrations',
    component: () => import('@/views/admin/db/List.vue'),
    meta: {
      headerText: 'Database Migration Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'db-migrations'
    }
  },

  {
    path: 'activity',
    name: 'AdminActivity',
    component: () => import('@/views/admin/activity/Index.vue'),
    meta: {
      headerText: 'Admin Activity Page',
      headerBackRoute: 'AdminHome'
    }
  },
  {
    path: 'user-sessions',
    name: 'AdminUserSessions',
    component: () => import('@/views/admin/user-sessions/Index.vue'),
    meta: {
      headerText: 'Admin Active User Sessions',
      headerBackRoute: 'AdminHome'
    }
  },
  {
    path: 'task-queue',
    component: { render: (h) => h('router-view') },
    children: [
      {
        path: '',
        name: 'AdminTaskQueueActive',
        component: () => import('@/views/admin/task-queue/Queue.vue')
      },
      {
        path: 'history',
        name: 'AdminTaskQueueHistory',
        component: () => import('@/views/admin/task-queue/History.vue')
      }
    ],
    meta: {
      headerText: 'Task Queue Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'admin-task-queue'
    }
  }
]
