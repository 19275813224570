const mutations = {
  SET_USER_SELLER_SCORE (state, userSellerScore) {
    state.userSellerScore = userSellerScore
    state.isUserSellerScoreLoaded = true
  },

  SET_GROUP_SELLER_SCORE (state, groupSellerScore) {
    state.groupSellerScore = groupSellerScore
    state.isGroupSellerScoreLoaded = true
  }
}

export default mutations
