import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  emailSupport (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/support/email', formData)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  zendeskSSO (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/support/zendesk-sso')
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  loginSupportUser (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/support/login', data)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  }
}

export default actions
