export default [
  {
    path: 'permissions',
    name: 'AdminPermission',
    component: () => import('@/views/admin/permission/List.vue'),
    meta: {
      headerText: 'Permissions Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'none'
    }
  },
  {
    path: 'permission/edit/:id',
    name: 'AdminEditPermission',
    component: () => import('@/views/admin/permission/Edit.vue'),
    meta: {
      headerText: 'Edit Permission',
      headerBackRoute: 'AdminPermission'
    }
  },
  {
    path: 'permission/group/create',
    name: 'AdminCreatePermissionGroup',
    component: () => import('@/views/admin/permission/CreateEditGroup.vue'),
    meta: {
      headerText: 'Create Permission Group',
      headerBackRoute: 'AdminPermission'
    }
  },
  {
    path: 'permission/group/edit/:id',
    name: 'AdminEditPermissionGroup',
    component: () => import('@/views/admin/permission/CreateEditGroup.vue'),
    meta: {
      headerText: 'Edit Permission Group',
      headerBackRoute: 'AdminPermission'
    }
  }
]
