import axios from 'axios'

const HttpPlugin = {
  install (Vue, options) {
    const instance = axios.create({
      withCredentials: true
    })

    if (options) {
      if (options.requestInterceptor) {
        instance.interceptors.request.use(options.requestInterceptor)
      }

      if (options.responseInterceptor) {
        instance.interceptors.response.use(options.responseInterceptor, options.responseErrorInterceptor)
      }
    }

    const makeApiRequest = async (method, url, data) => {
      try {
        const result = await instance[method](url, data)

        const { headers } = result
        // headers.get = name => headers[name.toLowerCase()]

        return {
          body: result.data,
          code: result.status,
          status: result.status,
          headers
        }
      } catch (error) {
        if (
          error.response
        ) {
          const newError = new Error(error.message)
          newError.body = error.response.data
          newError.code = error.response.code
          newError.response = error.response

          throw newError
        }
        throw error
      }
    }

    const methods = {
      get () {
        return makeApiRequest('get', ...arguments)
      },
      post () {
        return makeApiRequest('post', ...arguments)
      },
      delete () {
        return makeApiRequest('delete', ...arguments)
      },
      head () {
        return makeApiRequest('head', ...arguments)
      },
      options () {
        return makeApiRequest('options', ...arguments)
      },
      put () {
        return makeApiRequest('put', ...arguments)
      },
      patch () {
        return makeApiRequest('patch', ...arguments)
      }
    }

    Vue.http = methods
    Vue.prototype.$http = methods
  }
}

export default HttpPlugin
