import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  uploadDataSourceFile (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/datasource/new', formData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  downloadDataSourceFile (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/datasource/export/' + id)
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers.get('content-type') })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          const contentDisposition = response.headers.get('Content-Disposition')
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim()
          link.download = filename
          link.click()
          resolve('ok')
        })
        .catch(error => reject(error))
    })
  },

  adminRetrieveAllDatasets (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/datasource/list/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminDownloadDataSource (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/datasource/export/' + params.companyId + '/' + params.id)
        .then(response => {
          const data = [response.data]
          const contentType = response.headers.get('content-type')
          const contentDisposition = response.headers.get('Content-Disposition')
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim()

          resolve({ data, contentType, filename })
        })
        .catch(error => reject(error))
    })
  },

  adminDeleteDataSource (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/datasource/delete/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminDownloadSample (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/datasource/sample')
        .then(response => {
          const data = [response.data]
          const contentType = response.headers.get('content-type')
          const filename = 'dataset-template.csv'

          resolve({ data, contentType, filename })
        })
        .catch(error => reject(error))
    })
  },

  adminActivateDataSource (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/datasource/activate', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadDatasetClientCategory (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/datasource/categories/' + params.companyId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadDatasetProduct (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/datasource/products/' + params.companyId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
