import { assets } from '@/config'
const { assetsRoot, groupLogoAssetPath, companyLogoAssetPath } = assets

const getters = {

  /**
   * @param state
   */
  getGroupLogoURL: state => {
    if (state.group.logo) return assetsRoot + groupLogoAssetPath + state.group.logo
    return null
  },

  /**
   * @param state
   */
  getCompanyLogoURL: state => {
    if (state.company.logo) return assetsRoot + companyLogoAssetPath + state.company.logo
    return null
  },

  /**
   * @param state
   */
  getCompanyNonVariableStyles: state => {
    if (!state.company || !state.company.styles) return null

    return state.company.styles.reduce((styles, style) => {
      if (!style.isVariable) {
        styles[style.name] = style.value
      }
      return styles
    }, {})
  }

}

export default getters
