const AsyncPlugin = {
  install (Vue, options) {
    Vue.prototype.$asyncForEach = async (array, callback) => {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    }
  }
}

export default AsyncPlugin
