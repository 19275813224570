import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  /*
   * User admin actions
   */
  adminRetrieveUsers (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/user/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadUser (context, userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/user/load', { params: { id: userId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateUser (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/create', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminResetUserPassword (context, userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/reset-password', { id: userId })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateUser (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/update', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateUserCompanyGroup (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/update-company-group', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUserLoadByEmail (context, email) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/load-email', { email })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUserListForGroup (context, companyGroupId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/user/list-for-group', { params: { companyGroupId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminUserUploadAvatar (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/upload-avatar', formData)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  adminUserRemoveAvatar (context, userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/remove-avatar', { id: userId })
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  adminSpoofUser (context, userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/spoof-user', { userId })
        .then(response => {
          context.dispatch('getUser')
            .then(() => {
              resolve()
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error.body))
    })
  },

  adminUnspoofUser (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/unspoof-user')
        .then(response => {
          context.dispatch('getUser')
            .then(() => {
              resolve()
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error.body))
    })
  },

  adminUserLoadSSOProviders (context, userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/user/load-sso-providers', { params: { id: userId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUserLoadAvailableSSOProviders (context, userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/user/load-available-sso-providers', { params: { id: userId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUserSaveSSOProviders (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/user/update-sso-providers', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
