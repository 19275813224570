export default [
  {
    path: 'goals',
    name: 'AdminGoals',
    component: () => import('@/views/admin/goal/List.vue'),
    meta: {
      headerText: 'Goals Admin',
      headerBackRoute: 'AdminHome'
    }
  },
  {
    path: 'goal/new',
    name: 'AdminNewGoal',
    component: () => import('@/views/admin/goal/New.vue'),
    meta: {
      headerText: 'New Goal',
      headerBackRoute: 'AdminGoals'
    }
  },
  {
    path: 'goal/edit/:id',
    name: 'AdminEditGoal',
    component: () => import('@/views/admin/goal/Edit.vue'),
    meta: {
      headerText: 'Edit Goal',
      headerBackRoute: 'AdminGoals'
    }
  }
]
