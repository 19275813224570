import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  /*
   * Permission admin actions
   */
  adminLoadPermissions (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/permission/list')
        .then(response => {
          context.commit('SET_PERMISSIONS', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  adminGetPermission (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/permission/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminUpdatePermission (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/permission/update/' + params.id, params.groups)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  adminLoadPermissionGroups (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/permission/group/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGetPermissionGroup (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/permission/group/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadAvailablePermissionGroupsForCompany (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/permission/group/company-list', { params: { companyId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadDefaultPermissionGroupsForCompany (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/permission/group/company-default-list', { params: { companyId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminAddNewPermissionGroup (context, role) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/permission/group/create', role)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  adminUpdatePermissionGroup (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/permission/group/edit/' + params.id, params.data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  adminDeletePermissionGroup (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/permission/group/delete/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminEnablePermissionGroup (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/permission/group/enable', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminDisablePermissionGroup (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/permission/group/disable', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
