import Vue from 'vue'

const actions = {
  adminListBookmarks (context, params) {
    const path = '/admin/bookmarks'
    return Vue.api.get(path, { params })
  },

  adminLoadBookmark (context, id) {
    return Vue.api.get(`/admin/bookmarks/${id}`)
  },

  adminCreateBookmark (
    context,
    {
      companyId,
      companyGroupId,
      description,
      disabledCompanyGroups,
      includeInNewGroups,
      isActive,
      name,
      logoFile,
      order,
      url
    } = { }) {
    const path = '/admin/bookmarks'
    const data = {
      companyId,
      companyGroupId,
      description,
      disabledCompanyGroups,
      includeInNewGroups,
      isActive,
      name,
      logoFile,
      order,
      url
    }
    return Vue.api.post(path, { data })
  },

  adminEditBookmark (
    context,
    {
      description,
      disabledCompanyGroups,
      id,
      includeInNewGroups,
      isActive,
      logoFile,
      name,
      url
    } = { }) {
    const path = `/admin/bookmarks/${id}`
    const data = {
      description,
      disabledCompanyGroups,
      includeInNewGroups,
      isActive,
      logoFile,
      name,
      url
    }
    return Vue.api.patch(path, { data })
  },

  adminReorderBookmarks (context, { order }) {
    const path = '/admin/bookmarks/order'
    const data = { order }
    return Vue.api.post(path, { data })
  }
}

export default actions
