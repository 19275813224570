import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  googleIndustryCategory (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/data/google-industry', {
          params
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadIndustryCategories (context, companyId = null) {
    const params = {}
    if (companyId) params.companyId = companyId

    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/data/industry-tree', { params })
        .then(response => {
          context.commit('SET_INDUSTRY_CATEGORIES', response.body)
          context.commit('SET_INDUSTRY_CATEGORIES_COMPANY_ID', companyId)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadAllExternalIndustryCategories (context, companyId = null) {
    const params = {}
    if (companyId) params.companyId = companyId

    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/data/load-all-external-industry-category', { params })
        .then(response => {
          context.commit('SET_All_EXTERNAL_INDUSTRY_CATEGORIES', response.body)
          context.commit('SET_EXTERNAL_INDUSTRY_CATEGORIES_COMPANY_ID', companyId)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  imageSearch (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/data/image-search', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body.message))
    })
  },

  loadCampaignObjectives (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/data/campaign-objectives', { params })
        .then(response => {
          context.commit('SET_CAMPAIGN_OBJECTIVES', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  }

}

export default actions
