import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  /*
   * Company admin actions
   */
  adminUploadCompanyLogo (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/company/upload-logo', formData)
        .then(response => {
          context.commit('SET_ADMIN_COMPANY_LOGO', response.body.logo)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateCompany (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/company/update', data)
        .then(response => {
          context.commit('SET_ADMIN_COMPANY', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCompanyList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/company/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  adminCompanyActivityList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/company/activity-list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  adminCompanyGroupAllocationList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/company/group-allocation-list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  adminCompanyUserAllocationList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/company/user-allocation-list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminRetrieveAllCompanies (context) {
    return Vue.http
      .get(apiRoot + '/admin/company/list-all')
      .then(response => response.body)
  },

  adminLoadCompanyToAdmin (context, companyId) {
    return Vue.http
      .get(apiRoot + '/admin/company/get', { params: { id: companyId } })
      .then(({ body }) => { context.commit('SET_ADMIN_COMPANY', body) })
  },

  adminCreateCompany (context, company) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/company/create', company)
        .then(response => {
          context.commit('SET_ADMIN_COMPANY', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadCompany (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/company/get', {
          params: { id: companyId }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGetCompanyStyles (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/company/list-style', {
          params: { companyId }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminUpdateCompanyStyles (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/company/update-style', data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  adminDeleteCompanyStyles (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/company/delete-style', data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
