import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  getAssociatedTagObjectsForTag (context, tagId) {
    return new Promise((resolve, reject) => {
      Vue.http.post(apiRoot + '/admin/tags/get-associated-objects', { tagId })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  adminAddTagsToSlides (context, { userId, slides, tags }) {
    return new Promise((resolve, reject) => {
      // Vue.http.post(apiRoot + '/admin/tags/tag-slides', { userId, slides, tags })
      Vue.http.post(apiRoot + '/admin/presentation/tag-slides', { userId, slides, tags })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminCreateTag (context, tag) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/tags/create-tag', tag)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminEditTag (context, tag) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/tags/edit-tag', tag)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminDeleteTag (context, tag) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/tags/delete-tag', tag)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminDeleteTagGroup (context, tagGroup) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/tags/delete-tag-group', tagGroup)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminCreateTagGroup (context, tagGroup) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/tags/create-tag-group', tagGroup)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminEditTagGroup (context, tagGroup) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/tags/edit-tag-group', tagGroup)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminListTags (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/tags/list-tags', {
          params: data
        })
        .then(response => {
          context.commit('SET_ADMIN_TAGS', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  adminListTagGroups (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/tags/list-tag-groups', {
          params: { companyId }
        })
        .then(response => {
          context.commit('SET_ADMIN_TAG_GROUPS', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminFindTag (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/tags/find-tag', {
          params: data
        })
        .then(response => {
          context.commit('SET_ADMIN_TAGS', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
