const isValidBase64Image = /^(data:image\/(jpeg|png|gif);base64,)([a-z0-9!$&',()*+,;=\-._~:@/?%\s]*)$/ig

const ImageUtilsPlugin = {
  install (Vue, options) {
    Vue.prototype.$imageUtils = {
      getBase64Image (imgData) {
        return new Promise((resolve, reject) => {
          if (imgData instanceof File || imgData instanceof Blob) {
            const reader = new FileReader()

            reader.addEventListener('loadend', () => {
              const base64 = reader.result
              if (base64.match(isValidBase64Image)) return resolve(base64)
              return reject(new Error('Invalid image uploaded'))
            }, false)

            reader.addEventListener('error', (err) => {
              return reject(new Error('Invalid image uploaded: ' + err.message))
            }, false)

            reader.readAsDataURL(imgData)
          } else if (typeof imgData === 'string') {
            if (imgData.match(isValidBase64Image)) return resolve(imgData)
            return reject(new Error('Invalid image uploaded'))
          } else {
            return reject(new Error('Invalid image uploaded'))
          }
        })
      }
    }
  }
}

export default ImageUtilsPlugin
