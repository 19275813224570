export default [
  {
    path: 'reporting',
    name: 'AdminReporting',
    component: () => import('@/views/admin/reporting/Index.vue'),
    children: [
      {
        path: 'groups',
        alias: '',
        name: 'AdminReportingGroups',
        component: () => import('@/views/admin/reporting/Groups.vue'),
        meta: {
          headerText: 'Reporting Group Admin',
          headerBackRoute: 'AdminHome'
        }
      },
      {
        path: 'group/new',
        name: 'AdminReportingGroupNew',
        component: () => import('@/views/admin/reporting/NewEditGroup.vue'),
        meta: {
          headerText: 'Create Reporting Group',
          headerBackRoute: 'AdminReportingGroups'
        }
      },
      {
        path: 'group/edit/:id',
        name: 'AdminReportingGroupEdit',
        component: () => import('@/views/admin/reporting/NewEditGroup.vue'),
        meta: {
          headerText: 'Edit Reporting Group',
          headerBackRoute: 'AdminReportingGroups'
        }
      },
      {
        path: 'dashboards',
        name: 'AdminReportingDashboards',
        component: () => import('@/views/admin/reporting/Dashboards.vue'),
        meta: {
          headerText: 'Reporting Dashboard Admin',
          headerBackRoute: 'AdminHome'
        }
      }
    ],
    meta: {
      subNav: 'reporting-admin'
    }
  }
]
