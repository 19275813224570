export default {
  app: {
    twoFactorAuthenticationEnforcement: false
  },
  google: {
    maps: {
      apiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY
    }
  },
  mapbox: {
    apiKey: import.meta.env.VITE_MAPBOX_API_KEY
  }
}
