import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  /*
   * Support user admin actions
   */

  adminListSupportUsers (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/support-user/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadSupportUser (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/support-user/load/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadSupportUserPassword (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/support-user/load-password/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateSupportUser (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/support-user/create', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminUpdateSupportUser (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/support-user/update', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminDeleteSupportUser (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/support-user/delete', { id })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }

}

export default actions
