import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminGoalsLoadAll (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/campaign-goal/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalCreate (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-goal/create', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalCopy (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-goal/copy', { id })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalAddCategory (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-goal/add-category', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalRemoveCategory (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-goal/remove-category', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalDelete (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-goal/delete', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalLoad (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/campaign-goal/load/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalUpdate (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-goal/update', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalInputsLoadAll (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/campaign-goal/inputs/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalInputCreate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-goal/inputs/new', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalInputEdit (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-goal/inputs/update', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGoalInputDelete (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/campaign-goal/inputs/' + data.id, { params: { companyId: data.companyId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
