const mutations = {
  /**
   * @param state
   * @param list
   */
  SET_CAMPAIGN_LIST (state, list) {
    state.campaign = {}
    state.list = list
  },

  /**
   * @param state
   * @param client
   */
  SET_CAMPAIGN (state, campaign) {
    state.campaign = campaign
  },

  SET_SESSIONS (state, sessions) {
    state.sessions = sessions
  },

  /**
   * @param state
   * @param data
   */
  SET_NEW_CAMPAIGN_DATA (state, data) {
    state.new = data
  },

  /**
   * @param state
   * @param location
   */
  SET_CAMPAIGN_LOCATION_ARCHIVES (state, locations) {
    state.campaignLocationArchives = locations
  },

  /**
   * @param state
   * @param client
   */
  SET_CAMPAIGN_SUBMISSION_COMMENT (state, submissionComment) {
    state.submissionComment = submissionComment
  },

  /**
   * @param state
   * @param locations
   */
  SET_CAMPAIGN_LOCATION (state, locations) {
    state.campaign.addresses = locations
  },

  /**
   * @param state
   * @param locationRadiusesCache
   */
  SET_LOCATION_RADIUSES_CACHE (state, locationRadiusesCache) {
    state.locationRadiusesCache = locationRadiusesCache
  },

  /**
   * @param state
   * @param externalLinks
   */
  SET_CAMPAIGN_EXTERNAL_LINKS (state, externalLinks) {
    state.externalLinks = externalLinks
  },

  /**
   * Campaign Audience Mutations
   */
  SET_CAMPAIGN_AUDIENCE_CATEGORIES (state, audienceCategories) {
    state.audience.categories = audienceCategories
  },

  SET_CAMPAIGN_AUDIENCE_RECOMMENDED (state, recommendedSegments) {
    state.audience.recommended = recommendedSegments
  },

  SET_CAMPAIGN_AUDIENCE_PRIMARY (state, primarySegments) {
    state.audience.primary = primarySegments
  },

  SET_CAMPAIGN_AUDIENCE_AVAILABLE (state, availableCategories) {
    state.audience.available = availableCategories
  },

  /**
   * Campaign Products Mutations
   */
  SET_CAMPAIGN_PRODUCTS_PRIMARY (state, primarySelection) {
    state.products.primary = primarySelection
  },

  SET_CAMPAIGN_PRODUCTS_METADATA (state, metadata) {
    state.products.metadata = metadata
  },

  SET_CAMPAIGN_PRODUCTS_RANKED_AND_RECOMMENDED (state, rankedAndRecommended) {
    state.products.rankedAndRecommended = rankedAndRecommended
  },

  /**
    *  Campaign Geo Mutations
   */

  // loads campaign geo recommendations
  //   {
  //     "campaignId": 207,
  //     "recommended": [
  //         {
  //             "id": 2067,
  //             "campaignId": 207,
  //             "type": "congressionalDistrict",
  //             "isPrimary": false,
  //             "createdAt": "2023-03-27T13:53:13.453Z",
  //             "updatedAt": "2023-03-27T13:53:13.453Z",
  //             "geos": [
  //                 {
  //                     "campaignGeoRecommendedId": 2067,
  //                     "geoId": "165",
  //                     "name": "Mississippi: 3rd District"
  //                 }
  //             ]
  //         },
  //         {
  //             "id": 2061,
  //             "campaignId": 207,
  //             "type": "dma",
  //             "isPrimary": false,
  //             "createdAt": "2023-03-27T13:53:13.453Z",
  //             "updatedAt": "2023-03-27T13:53:13.453Z",
  //             "geos": [
  //                 {
  //                     "campaignGeoRecommendedId": 2061,
  //                     "geoId": "718",
  //                     "name": "Jackson, MS"
  //                 }
  //             ]
  //         }
  //     ],
  //     "primaryRecommended": {
  //         "id": 2063,
  //         "campaignId": 207,
  //         "type": "city",
  //         "isPrimary": true,
  //         "geos": [
  //             {
  //                 "campaignGeoRecommendedId": 2063,
  //                 "geoId": "13234",
  //                 "name": "Flowood, MS"
  //             }
  //         ]
  //     }
  // }
  SET_CAMPAIGN_GEO_RECOMMENDED (state, geo) {
    state.geo.recommended = geo
  },

  // loads campaign geo primary
  //   {
  //     "campaignId": 207,
  //     "recommended": [
  //         {
  //             "id": 2067,
  //             "campaignId": 207,
  //             "type": "congressionalDistrict",
  //             "isPrimary": false,
  //             "createdAt": "2023-03-27T13:53:13.453Z",
  //             "updatedAt": "2023-03-27T13:53:13.453Z",
  //             "geos": [
  //                 {
  //                     "campaignGeoRecommendedId": 2067,
  //                     "geoId": "165",
  //                     "name": "Mississippi: 3rd District"
  //                 }
  //             ]
  //         },
  //         {
  //             "id": 2063,
  //             "campaignId": 207,
  //             "type": "city",
  //             "isPrimary": true,
  //             "geos": [
  //                 {
  //                     "campaignGeoRecommendedId": 2063,
  //                     "geoId": "13234",
  //                     "name": "Flowood, MS"
  //                 }
  //             ]
  //         }
  //     ],
  //     "primaryRecommended": {
  //         "id": 2063,
  //         "campaignId": 207,
  //         "type": "city",
  //         "isPrimary": true,
  //         "geos": [
  //             {
  //                 "campaignGeoRecommendedId": 2063,
  //                 "geoId": "13234",
  //                 "name": "Flowood, MS"
  //             }
  //         ]
  //     }
  // }
  SET_CAMPAIGN_GEO_PRIMARY (state, geo) {
    state.geo.primary = geo
  },
  SET_CAMPAIGN_FIELDS (state, campaignFields) {
    state.campaignFields = campaignFields
  }
}

export default mutations
