import store from '@/store'

const clientEditAccessLevels = ['owner', 'edit']
const clientFullCampaignViewAccessLevel = ['edit', 'view']
const clientFullCampaignEditAccessLevel = ['edit']

const hasRoute = route => {
  const { isUserAuthenticated, User } = store.state
  return !!(isUserAuthenticated && User.user.permissionRoutes?.includes(route))
}

const hasPermission = permission => {
  const { isUserAuthenticated, User } = store.state
  return !!(isUserAuthenticated && User.user.permissions?.includes(permission))
}

const defaultRoute = () => {
  // Was WorkspaceMyClients, but now that page will redirect away based on company settings
  return 'Workspace'
}

const hasClientEdit = client => {
  const { user } = store.state.User
  const clientCompanyGroupId = client.companyGroup ? client.companyGroup.id : client.companyGroupId
  const clientCompanyId = client.companyGroup ? client.companyGroup.companyId : client.companyId
  return clientEditAccessLevels.indexOf(client.accessLevel) >= 0 ||
    hasPermission('ClientEditAll') ||
    (hasPermission('ClientEditForOwnCompany') && user.companyGroup.companyId === clientCompanyId) ||
    (hasPermission('ClientEditForOwnGroup') && user.companyGroup.id === clientCompanyGroupId)
}

const hasClientCreate = client => {
  const { user } = store.state.User
  const clientCompanyGroupId = client.companyGroup ? client.companyGroup.id : client.companyGroupId
  const clientCompanyId = client.companyGroup ? client.companyGroup.companyId : client.companyId
  return clientEditAccessLevels.indexOf(client.accessLevel) >= 0 ||
    hasPermission('ClientCreateForAll') ||
    (hasPermission('ClientCreateForOwnCompany') && user.companyGroup.companyId === clientCompanyId) ||
    (hasPermission('ClientCreateForOwnGroup') && user.companyGroup.id === clientCompanyGroupId)
}

const hasCampaignView = (client, campaign) => {
  const { user } = store.state.User
  const campaignOwnerUserId = campaign.ownerUserId || campaign.owner.userId

  const isOwner = campaignOwnerUserId === user.id
  const hasViewAll = hasPermission('CampaignViewAll')
  const hasViewForCompany = (
    (
      hasPermission('CampaignViewForOwnCompany') ||
      hasPermission('CampaignEditForOwnCompany')
    ) && client.companyGroup.companyId === user.companyGroup.company.id
  )
  const hasViewForGroup = (
    (
      hasPermission('CampaignViewForOwnGroup') ||
      hasPermission('CampaignEditForOwnGroup')
    ) && client.companyGroup.id === user.companyGroup.id
  )
  const hasClientFullCampaignView = clientFullCampaignViewAccessLevel.indexOf(client.fullCampaignAccessLevel) >= 0

  const hasClientTeamViewAccess = client.teamMembers.some((member) => {
    return (
      member.id === user.userCompanyGroupId &&
      clientFullCampaignViewAccessLevel.indexOf(member.clientCampaignTeamsAccess) >= 0
    )
  })

  const hasCampaignTeamViewAccess = campaign.teamMembers.some((member) => {
    return (
      member.id === user.userCompanyGroupId &&
      clientFullCampaignViewAccessLevel.indexOf(member.campaignTeamsAccess) >= 0
    )
  })

  return isOwner ||
    hasViewAll ||
    hasViewForCompany ||
    hasViewForGroup ||
    hasClientFullCampaignView ||
    hasClientTeamViewAccess ||
    hasCampaignTeamViewAccess
}

const hasCampaignEdit = (client, campaign) => {
  const { user } = store.state.User
  const campaignOwnerUserId = campaign.ownerUserId || campaign.owner.userId

  const isOwner = campaignOwnerUserId === user.id
  const hasEditAll = hasPermission('CampaignEditAll')
  const hasEditForCompany = (hasPermission('CampaignEditForOwnCompany') && client.companyGroup.companyId === user.companyGroup.company.id)
  const hasEditForGroup = (hasPermission('CampaignEditForOwnGroup') && client.companyGroup.id === user.companyGroup.id)
  const hasClientFullCampaignEdit = clientFullCampaignEditAccessLevel.indexOf(client.fullCampaignAccessLevel) >= 0

  const hasClientTeamEditAccess = client.teamMembers.some((member) => {
    return (
      member.id === user.userCompanyGroupId &&
      clientFullCampaignEditAccessLevel.indexOf(member.clientCampaignTeamsAccess) >= 0
    )
  })

  const hasCampaignTeamEditAccess = campaign.teamMembers.some((member) => {
    return (
      member.id === user.userCompanyGroupId &&
      clientFullCampaignEditAccessLevel.indexOf(member.campaignTeamsAccess) >= 0
    )
  })

  return isOwner ||
    hasEditAll ||
    hasEditForCompany ||
    hasEditForGroup ||
    hasClientFullCampaignEdit ||
    hasClientTeamEditAccess ||
    hasCampaignTeamEditAccess
}

const hasPresentationEdit = (client, campaign) => {
  const { user } = store.state.User
  const campaignOwnerUserId = campaign.ownerUserId || campaign.owner.userId

  const isOwner = campaignOwnerUserId === user.id
  const hasEditAll = hasPermission('CampaignEditAll')
  const hasEditForCompany = (hasPermission('CampaignEditForOwnCompany') && client.companyGroup.companyId === user.companyGroup.company.id)
  const hasEditForGroup = (hasPermission('CampaignEditForOwnGroup') && client.companyGroup.id === user.companyGroup.id)
  const hasClientFullCampaignEdit = clientFullCampaignEditAccessLevel.indexOf(client.fullCampaignAccessLevel) >= 0

  const hasClientTeamEditAccess = client.teamMembers.some((member) => {
    return (
      member.id === user.userCompanyGroupId &&
      clientFullCampaignEditAccessLevel.indexOf(member.clientCampaignTeamsAccess) >= 0
    )
  })

  const hasClientTeamPresentationEditAccess = client.teamMembers.some((member) => {
    return (
      member.id === user.userCompanyGroupId &&
      member.clientCampaignTeamsAccess === 'view' &&
      member.allowClientCampaignPresentationEdit
    )
  })

  const hasCampaignTeamEditAccess = campaign.teamMembers.some((member) => {
    return (
      member.id === user.userCompanyGroupId &&
      clientFullCampaignEditAccessLevel.indexOf(member.campaignTeamsAccess) >= 0
    )
  })

  const hasCampaignTeamPresentationEditAccess = campaign.teamMembers.some((member) => {
    return (
      member.id === user.userCompanyGroupId &&
      member.campaignTeamsAccess === 'view' &&
      member.allowCampaignPresentationEdit
    )
  })

  return isOwner ||
    hasEditAll ||
    hasEditForCompany ||
    hasEditForGroup ||
    hasClientFullCampaignEdit ||
    hasClientTeamEditAccess ||
    hasCampaignTeamEditAccess ||
    hasClientTeamPresentationEditAccess ||
    hasCampaignTeamPresentationEditAccess
}

const permissionMethods = {
  hasRoute,
  hasPermission,
  defaultRoute,
  hasClientCreate,
  hasClientEdit,
  hasCampaignView,
  hasCampaignEdit,
  hasPresentationEdit
}

const PermissionPlugin = {
  install (Vue, options) {
    Vue.permission = permissionMethods
    Vue.prototype.$permission = permissionMethods
  }
}

export default PermissionPlugin
