import Rollbar from 'rollbar'
import customErrors from '../errors'

const shouldIgnoreError = (error) => {
  return error.name === 'NavigationDuplicated'
}

const createErrorTracker = (config) => {
  if (config.accessToken) {
    const rollbar = new Rollbar({
      accessToken: config.accessToken,
      captureUncaught: false,
      captureUnhandledRejections: false,

      payload: {
        environment: config.environment,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: config.version,
            guess_uncaught_frames: true
          }
        }
      }
    })

    return {
      logError (error, extraData = null) {
        if (!shouldIgnoreError(error)) rollbar.error(error, extraData)
      },

      logCustomError (type, error, extraData = null) {
        rollbar.error(`${type}: ${error.message}`, error, extraData)
      },

      setUser (user) {
        if (user && user.userCompanyGroupId) {
          const person = {
            id: user.userCompanyGroupId,
            email: user.email
          }

          rollbar.configure({
            payload: {
              person
            }
          })
        }
      }
    }
  }

  return {
    logError (error, extraData = null) {
      // eslint-disable-next-line no-console
      if (!shouldIgnoreError(error)) console.error(error, extraData)
    },
    logCustomError (type, error, extraData = null) {
      // eslint-disable-next-line no-console
      console.error(`${type}: ${error.message}`, error, extraData)
    },
    setUser () {}
  }
}

const ErrorTrackingPlugin = {
  install (Vue, config) {
    const errorTracker = createErrorTracker(config)
    Vue.prototype.$errorTracking = errorTracker
    Vue.$errorTracking = errorTracker

    Vue.config.errorHandler = async (err, vm, info) => {
      // Unknown error, log and throw
      vm.$errorTracking.logError(err)
      throw err
    }
  },

  createErrorTracker,

  customErrors
}

export default ErrorTrackingPlugin
