const mutations = {
  /**
   * @param state
   * @param list
   */
  SET_GROUP (state, group) {
    state.group = group
    state.company = group.company
  },

  /**
   * @param state
   * @param list
   */
  SET_PRODUCTS (state, products) {
    state.products = products
  },

  /**
   * @param state
   * @param list
   */
  SET_PRODUCT (state, product) {
    state.product = product
  }

}

export default mutations
