export default {
  app: {
    twoFactorAuthenticationEnforcement: false,
    isTestMode: true
  },
  google: {
    oauth: {
      enabled: false
    }
  }
}
