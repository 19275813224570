const state = {
  list: {
    num: 0,
    clients: [],
    owners: [],
    teamMembers: []
  },
  client: {},
  logo: null,
  new: {},
  externalLinks: []
}

export default state
