import Vue from 'vue'

function list (clientId) {
  const path = `clients/${clientId}/products`
  return Vue.api.get(path)
}

export default {
  list
}
