import Vue from 'vue'

function loadCampaignBudgetRecommendation (data = {}) {
  return Vue.api.post('/recommendation/campaign-budget', { data })
}

export default {
  campaignBudget: {
    load: loadCampaignBudgetRecommendation
  }
}
