<template>
  <div :class="taskContainerBorderClasses">
    <div>
      <div
        class="d-flex flex-row"
        :class="isExpanded?'expandable-box-show':'expandable-box'"
      >
        <div>
          <input
            v-if="activeTab==='completed'"
            type="checkbox"
            checked="true"
            class="border h-4 w-4 text-blue-500 border-gray-300 rounded checkbox-completed"
          >
          <input
            v-else
            type="checkbox"
            class="border h-4 w-4 text-blue-500 border-gray-300 rounded checkbox"
            @click="$emit('complete')"
          >
        </div>
        <div
          ref="wrapper"
          class="text-wrapper"
        >
          <div
            class="d-flex flex-column text-line-height"
            :class="{'strike-through':activeTab==='completed'}"
          >
            <div class="section-wrapper ">
              🌟 Welcome to Tasks!
            </div>
            <div class="section-wrapper ">
              Create, manage, and assign tasks effortlessly. Track progress in the Assigned and Completed tabs.
            </div>
            <div>
              Let's begin by marking this task as complete 🚀
            </div>
          </div>
        </div>

        <div
          v-if="showExpandButton"
          class="expand-button"
          @click="toggleExpansion"
        >
          <task-condense-icon
            v-if="isExpanded"
            w="28"
            h="24"
          />
          <task-expand-icon
            v-else
            w="28"
            h="24"
          />
        </div>
      </div>
    </div>
    <div class="footer d-flex flex-row">
      <div
        class="assignee-row"
      >
        <div
          class="d-flex flex-row align-items-center"
        >
          <div class="left-column">
            <div
              class="avatar"
            >
              <advisr-white-icon />
            </div>
          </div>
          <div class="center-column">
            <div
              class="name"
            >
              Advisr
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { TaskExpandIcon, TaskCondenseIcon, AdvisrWhiteIcon } from '@/components/icons'
export default {
  components: {
    TaskExpandIcon, TaskCondenseIcon, AdvisrWhiteIcon
  },
  props: {
    activeTab: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isExpanded: false,
      showExpandButton: true
    }
  },
  computed: {
    taskContainerBorderClasses: {
      get () {
        const classes = []

        if (this.isExpanded) {
          classes.push('task-container-show')
        } else {
          classes.push('task-container')
        }

        if (this.activeTab === 'completed') {
          classes.push('border-left-completed')
        } else {
          classes.push('border-left-pending')
        }
        return classes
      }
    }
  },
  methods: {
    toggleExpansion () {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

  <style scoped>
   .task-container {
      border: 1px solid rgba(221, 221, 221, 1);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;
      margin-left: 1em;
      width: 100%;
      max-width: 33em;
      margin-right: 1em;
  }
  .task-container-show {
      border: 1px solid rgba(46, 118, 188, 1) ;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;
      margin-left: 1em;
      margin-right: 1em;
      background: rgba(248, 250, 253, 1);
      width: 100%;
      max-width: 33em;
  }
  @media (max-width: 3000px) {
    .task-container {
      max-width: 50em;
  }

    .task-container-show  {
      max-width: 50em;

    }
  }
  @media (max-width: 2700px) {
    .task-container {
      max-width: 46em;
  }

    .task-container-show  {
      max-width: 46em;

    }
  }
  @media (max-width: 2500px) {
    .task-container {
      max-width: 43em;
  }

    .task-container-show  {
      max-width: 43em;

    }
  }
  @media (max-width: 2000px) {
    .task-container {
      max-width: 34em;
  }

    .task-container-show  {
      max-width: 34em;

    }
  }
  @media (max-width: 1900px) {
    .task-container {
      max-width: 33em;
  }

    .task-container-show  {
      max-width: 33em;

    }
  }
  @media (max-width: 1800px) {
    .task-container {
      max-width: 30em;
  }

    .task-container-show  {
      max-width: 30em;

    }
  }
  @media (max-width: 1680px) {
    .task-container {
      max-width: 29em;
  }

    .task-container-show  {
      max-width: 29em;

    }
  }
  @media (max-width: 1600px) {
    .task-container {
      max-width: 25em;
  }

    .task-container-show  {
      max-width: 25em;

    }
  }
  @media (max-width: 1450px) {
    .task-container {
      max-width: 24em;
  }

    .task-container-show  {
      max-width: 24em;

    }
  }
  @media (max-width: 1350px) {
    .task-container {
      max-width: 23em;
  }

    .task-container-show  {
      max-width: 23em;

    }
  }
  @media (max-width: 1300px) {
    .task-container {
      max-width: 22em;
  }

    .task-container-show  {
      max-width: 22em;

    }
  }
  @media (max-width: 1250px) {
    .task-container {
      max-width: 21em;
  }

    .task-container-show  {
      max-width: 21em;

    }
  }

  @media (max-width: 1200px) {
    .task-container {
      max-width: 20em;
  }

    .task-container-show  {
      max-width: 20em;

    }
  }
  @media (max-width: 1050px) {
    .task-container {
      max-width: 16em;
  }

    .task-container-show  {
      max-width: 16em;

    }
  }
  .border-left-past-due{
    border-left: 8px solid rgba(215, 59, 67, 1) ;
  }
  .border-left-due-soon{
    border-left: 8px solid rgba(225, 153, 48, 1) ;
  }
  .border-left-completed{
    border-left: 8px solid rgba(67, 160, 71, 1) ;

  }
  .border-left-pending{
    border-left: 8px solid rgba(46, 118, 188, 1) ;
  }
  .header, .footer {
    padding: 10px;
    align-items: center;
    height: 4em;
  }
  .header {
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .footer {
    border-top: 1px solid #ccc;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(79, 108, 136, 1);
    justify-content: space-between;
  }
  .expandable-box {
    height: 44px;
    padding: 10px 10px 10px 10px;
    justify-content: space-between;
  }
  .expandable-box-show {
    height: auto;
    padding: 10px 10px 10px 10px;
    justify-content: space-between;
  }
  .text-blue-500 {
    color: var(--link-color);
  }
  .checkbox {
    accent-color: var(--checkbox-checked-background-color);

  }
  .checkbox-completed {
    pointer-events: none; /* Prevents the element from receiving pointer events */
    cursor: not-allowed;
    accent-color: var(--checkbox-checked-background-color);
  }

  .text-wrapper {
    position: relative;
    width: 90%;
    overflow:hidden;
    text-overflow: ellipsis;
  left: 5px;
  }

  .assignee-row {
    display: flex;
    height: 45px;
    align-items: center;
  }
  .left-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 35px;
    padding-left: 0.3rem;
  }
  .avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(217, 217, 217, 1);
    background-size: cover;
    background-position: 50% 50%;
  }
  .center-column {
    flex-grow: 2;
    width: 14em;
  }
  .name {
    font-weight: 500;
    display: flex;
    line-height: 1.3em;
  }

  .text-line-height{
    line-height:1.7
  }
  .strike-through {
  text-decoration: line-through;
}
  </style>
