import Vue from 'vue'

function list (params = { }) {
  const path = '/admin/email-template-mappings'
  return Vue.api.get(path, { params })
}

function create (data = { }) {
  const path = '/admin/email-template-mappings'
  return Vue.api.post(path, { data })
}

function update (data = { }) {
  const { id } = data
  delete data.id
  const path = `/admin/email-template-mappings/${id}`
  return Vue.api.patch(path, { data })
}

function save (data = { }) {
  return data.id ? update(data) : create(data)
}

export default {
  list,
  update,
  create,
  save
}
