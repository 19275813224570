import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  adminInternalCRMListAllCompanies (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/internal-crm/list-all-companies/')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminInternalCRMSearchCompanyByName (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/internal-crm/search-company-by-name/', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminInternalCRMCreateCompany (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/internal-crm/create-company/', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminInternalCRMUpdateCompany (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/internal-crm/update-company/', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminInternalCRMUpdateContact (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/internal-crm/update-contact/', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }

}

export default actions
