import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  getUser (context) {
    context.commit('SET_USER_IS_LOADED', false)
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/user/get')
        .then(response => {
          const user = response.body
          context.commit('SET_USER', user)
          if (user.companyGroup) context.commit('SET_GROUP', response.body.companyGroup)
          if (!user.acceptedTermsOfService) context.commit('SET_SHOW_TERMS_OF_SERVICE', true)

          // Enforce 2fa enrollment if required
          if (user['2faRequired'] && !user['2faEnabled']) {
            context.commit('SET_2FA_ENROLLMENT_REQUIRED', true)
            context.commit('SET_2FA_VALIDATION_REQUIRED', false)
          }

          Vue.$errorTracking.setUser(user)
          context.commit('SET_IS_USER_AUTHENTICATED', true)
          context.commit('SET_USER_IS_LOADED', true)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loginUser (context, user) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/login', {
          email: user.email,
          password: user.password
        })
        .then(response => {
          if (response.body['2fa'] === true) {
            context.commit('SET_2FA_VALIDATION_REQUIRED', true)
            context.commit('SET_2FA_ENROLLMENT_REQUIRED', false)
            resolve()
          } else {
            context.commit('SET_2FA_VALIDATION_REQUIRED', false)
            context.commit('SET_2FA_ENROLLMENT_REQUIRED', false)
            context.dispatch('getUser')
              .then(() => {
                resolve()
              })
              .catch(error => reject(error))
          }
        })
        .catch(error => reject(error))
    })
  },

  loginSSOProviders (context, email) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/load-sso-providers', {
          email
        })
        .then(response => {
          return resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loginSSOProviderByKey (context, key) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/load-sso-provider', {
          key
        })
        .then(response => {
          return resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  validateUser2fa (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/validate-2fa', data)
        .then(response => {
          context.commit('SET_2FA_VALIDATION_REQUIRED', false)

          context.dispatch('getUser')
            .then(() => {
              resolve()
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  },

  newUser2faToken (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/auth/new-2fa-token')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  enableUser2fa (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/enable-2fa', data)
        .then(response => {
          const { user } = response.body
          context.commit('SET_USER', user)
          context.commit('SET_2FA_ENROLLMENT_REQUIRED', false)
          context.commit('SET_2FA_VALIDATION_REQUIRED', false)
          context.commit('SET_IS_USER_AUTHENTICATED', true)

          resolve()
        })
        .catch(error => reject(error))
    })
  },

  disableUser2fa (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/disable-2fa', data)
        .then(response => {
          context.commit('SET_USER', response.body)
          if (response.body['2faRequired'] && !response.body['2faEnabled']) {
            context.commit('SET_2FA_ENROLLMENT_REQUIRED', true)
          }
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loginGoogleUser (context, user) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/google-login', {
          email: user.email,
          token: user.token
        })
        .then(response => {
          context.dispatch('getUser')
            .then(() => {
              resolve()
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  },

  loginOktaUser (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/okta-login', data)
        .then(response => {
          context.dispatch('getUser')
            .then(() => {
              resolve()
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  },

  loginAzureUser (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/azure-login', data)
        .then(response => {
          context.dispatch('getUser')
            .then(() => {
              resolve()
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  },

  loginSSOUser (context, user) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/sso-login', {
          email: user.email,
          token: user.token,
          key: user.key
        })
        .then(response => {
          context.dispatch('getUser')
            .then(() => {
              resolve()
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  },

  refreshAuthToken (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/refresh-token')
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  logout (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/logout')
        .then(response => {
          context.commit('SET_IS_USER_AUTHENTICATED', false)
          context.commit('RESET_STATE')
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  setUserUnauthenticated (context) {
    context.commit('SET_IS_USER_AUTHENTICATED', false)
  },

  forgotPassword (context, email) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/forgot-password', { email })
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  validatePasswordResetToken (context, token) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/validate-reset-token', { token })
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body.message))
    })
  },

  resetPassword (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/reset-password', params)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body.message))
    })
  },

  updateUser (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/update', params)
        .then(response => {
          context.commit('SET_USER', response.body)
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  updatePassword (context, password) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/update-password', { password })
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  disableUserInitialSetup (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/disable-initial-setup')
        .then(response => {
          context.commit('DISABLE_USER_INITIAL_SETUP')
          context.dispatch('getUser')
            .then(() => {
              resolve()
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error.body))
    })
  },

  acceptTermsOfService (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/accept-terms-of-service')
        .then(response => {
          context.commit('ACCEPT_TERMS_OF_SERVICE')
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  uploadUserAvatar (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/upload-avatar', formData)
        .then(response => {
          context.commit('SET_USER_AVATAR', response.body)
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  resetUserAvatar (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/reset-avatar')
        .then(response => {
          context.commit('SET_USER', response.body)
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  switchCompanyGroup (context, companyGroupId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/auth/switch-company-group', {
          companyGroupId
        })
        .then(response => {
          context.commit('RESET_STATE')
          context.dispatch('getUser')
            .then(() => {
              resolve()
            })
            .catch(error => reject(error.body))
        })
        .catch(error => reject(error.body))
    })
  },

  userCreateCustomSlide (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/slides/new', data)
        .then(response => resolve(response))
        .catch(err => reject(err))
    })
  },

  userUpdateCustomSlide (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/user/slides/' + data.id, data)
        .then(response => resolve(response))
        .catch(err => reject(err))
    })
  },

  userDeleteCustomSlide (context, slideId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/user/slides/' + slideId)
        .then(response => resolve(response))
        .catch(err => reject(err))
    })
  },

  userHasSeenSupportAlert (context) {
    context.commit('USER_HAS_SEEN_SUPPORT_ALERT')

    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/update', { hasSeenSupportAlert: true })
        .then(response => {
          context.commit('SET_USER', response.body)
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },
  userHasCompletedWelcomeTask (context) {
    context.commit('USER_HAS_COMPLETED_WELCOME_TASK')

    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/update', { hasCompletedWelcomeTask: true })
        .then(response => {
          context.commit('SET_USER', response.body)
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  userDismissNotification (context, notification) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user/dismiss-notification', { notification })
        .then(response => {
          context.commit('SET_USER', response.body)
          resolve()
        })
        .catch(error => reject(error.body))
    })
  }

}

export default actions
