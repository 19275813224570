export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },
  {
    path: '/enroll-2fa',
    name: 'EnrollTwoFactor',
    component: () => import('@/views/auth/TwoFactorEnrollment.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },
  {
    path: '/validate-2fa',
    name: 'ValidateTwoFactor',
    component: () => import('@/views/auth/TwoFactorValidation.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },
  {
    path: '/google-login',
    name: 'GoogleLogin',
    component: () => import('@/views/auth/GoogleLogin.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },

  {
    path: '/okta-login/callback',
    component: () => import('@/views/auth/OktaLoginCallback.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },

  {
    path: '/okta-login/initiate',
    component: () => import('@/views/auth/OktaLoginAuto.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },

  {
    path: '/sso-login',
    name: 'SSOLogin',
    component: () => import('@/views/auth/SSOLogin.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/auth/Logout.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      noAuth: true,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  },

  {
    path: '/switch-organization',
    name: 'SwitchGroup',
    component: () => import('@/views/auth/SwitchGroup.vue'),
    meta: {
      noAuth: false,
      hideHeader: true,
      hideNav: true,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width-no-padding'
    }
  }
]
