export default [
  {
    path: 'config',
    name: 'AdminBackendConfig',
    component: { render: (h) => h('router-view') },
    children: [
      {
        path: 'full',
        alias: '',
        name: 'AdminBackendConfigFull',
        component: () => import('@/views/admin/backend-config/Full.vue')
      },
      {
        path: 'live',
        alias: '',
        name: 'AdminBackendConfigLive',
        component: () => import('@/views/admin/backend-config/Live.vue'),
        children: [
          {
            path: 'config',
            alias: '',
            name: 'AdminBackendConfigLiveConfig',
            component: () => import('@/views/admin/backend-config/LiveConfig.vue'),
            meta: {
              name: 'AdminBackendConfigLive'
            }
          },
          {
            path: 'settings',
            name: 'AdminBackendConfigLiveSettings',
            component: () => import('@/views/admin/backend-config/LiveSettings.vue'),
            meta: {
              name: 'AdminBackendConfigLive'
            }
          }
        ]
      }
    ],
    meta: {
      headerText: 'Backend Config Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'admin-backend-config'
    }
  }
]
