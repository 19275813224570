<template>
  <div style="display: none;" />
</template>
<script>

export default {
  name: 'DeeplinkHandler',

  mounted () {
    this.$bus.$on('deeplink:comments', this.routeToComment)
    this.$bus.$on('deeplink:contacts', this.routeToContact)
    this.$bus.$on('deeplink:request-for-proposals', this.routeToRequestForProposal)
  },

  beforeDestroy () {
    this.$bus.$on('deeplink:comments')
  },

  methods: {
    routeToComment (params = {}) {
      switch (params.commentableType) {
        case 'CampaignFile':
          this.$router.push({
            name: 'CampaignFiles',
            params: { id: params.campaignId },
            query: {
              campaignFileId: params.commentableId,
              commentId: params.commentId
            }
          })
          break

        case 'Campaign':
          this.$router.push({
            name: 'CampaignSummary',
            params: { id: params.commentableId },
            query: {
              campaignCommentId: params.commentId
            }
          })
          break

        case 'MediaAsset':
          this.$router.push({
            name: 'AssetLibrary',
            query: {
              mediaAssetId: params.commentableId,
              mediaAssetCommentId: params.commentId
            }
          })
          break

        case 'Contact':
          this.$router.push({
            name: 'WorkspaceAllContacts',
            params: { id: params.commentableId },
            query: {
              contactId: params.commentableId,
              contactCommentId: params.commentId
            }
          })
          break

        case 'RequestForProposal':
          this.$router.push({
            name: 'EditRequestForProposal',
            params: { id: params.commentableId },
            query: {
              commentId: params.commentId
            }
          })
          break
      }
    },

    routeToContact (params = {}) {
      this.$router.push({
        name: 'WorkspaceAllContacts',
        query: {
          contactId: params.contactId
        }
      })
    },

    routeToRequestForProposal (params = {}) {
      this.$router.push({
        name: 'EditRequestForProposal',
        params: { id: params.requestForProposalId },
      })
    }
  }
}
</script>
