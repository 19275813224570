import Vue from 'vue'

function list ({
  externalIntegrationInstallationId,
  companyId
} = {}) {
  const path = `/admin/external-integration-installations/${externalIntegrationInstallationId}/external-integration-users`

  const params = { companyId }
  return Vue.api.get(path, { params })
}

export default {
  list
}
