import { findIndex, each } from 'lodash-es'

const mutations = {
  /**
   * @param state
   * @param list
   */
  SET_CLIENT_LIST (state, list) {
    state.list = list
  },

  /**
   * @param state
   * @param client
   */
  SET_CLIENT (state, client) {
    const { geoProfiles } = client
    client.geoProfiles = geoProfilesFormat(geoProfiles)
    state.client = client
    state.logo = client.logo
  },

  /**
   * @param state
   * @param logo
   */
  SET_CLIENT_LOGO (state, logo) {
    state.logo = logo
  },

  SET_CLIENT_BILLING_DETAILS (state, billingDetails) {
    state.client.billingDetailId = billingDetails ? billingDetails.id : null
    state.client.billingDetails = billingDetails
  },

  SET_CLIENT_TERMS_AND_CONDITIONS (state, termsAndConditions) {
    state.client.termsAndConditions = termsAndConditions
  },

  /**
   * @param state
   * @param data
   */
  SET_NEW_CLIENT_DATA (state, data) {
    state.new = data
  },

  /**
   * @param state
   * @param logo
   */
  SET_NEW_CLIENT_LOGO (state, logo) {
    state.new.logo = logo
  },

  /**
   * @param state
   * @param index
   */
  REMOVE_NEW_CLIENT_GEO (state, index) {
    state.new.geoProfiles.splice(index, 1)
  },

  /**
   * @param state
   * @param index
   */
  REMOVE_GEO_FROM_CLIENT (state, geoId) {
    const index = findIndex(state.client.geoProfiles, ['id', geoId])
    state.client.geoProfiles.splice(index, 1)
  },

  /**
   * @param state
   * @param geo
   */
  EDIT_NEW_CLIENT_GEO (state, geo) {
    if (geo.value.default) {
      const index = findIndex(state.new.geoProfiles, 'default')
      if (index !== -1 && geo.index !== index) state.new.geoProfiles[index].default = false
    }
    state.new.geoProfiles[geo.index] = geo.value
  },

  /**
   * @param state
   * @param geo
   */
  EDIT_CLIENT_GEO (state, geo) {
    // if (geo.value.default) {
    //   const index = findIndex(state.new.geoProfiles, 'default')
    //   if (index !== -1 && geo.index !== index) state.new.geoProfiles[index].default = false
    // }
    state.client.geoProfiles = geoProfilesFormat(geo)
  },

  /**
   * @param state
   * @param geo
   */
  RESET_NEW_CLIENT_GEO (state, geo) {
    state.new.geoProfiles[geo.index] = geo.value
  },

  /**
   * @param state
   * @param geo
   */
  RESET_CLIENT_GEO (state, geo) {
    state.client.geoProfiles[geo.index] = geo.value
  },

  /**
   * @param state
   * @param location
   */
  ADD_LOCATION_TO_CLIENT (state, locations) {
    state.client.addresses.push(...locations)
  },

  /**
   * @param state
   * @param id
   */
  REMOVE_LOCATION_FROM_CLIENT (state, ids) {
    each(ids, id => state.client.addresses.splice(findIndex(state.client.addresses, (loc) => { return loc.id === id }), 1))
  },

  /**
   * @param state
   * @param data
   */
  REPLACE_LOCATION_ON_CLIENT (state, data) {
    state.client.addresses.splice(findIndex(state.client.addresses, loc => { return loc.id === data.id }), 1, data.newLocation)
  },

  /**
   * @param state
   * @param externalLinks
   */
  SET_CLIENT_EXTERNAL_LINKS (state, externalLinks) {
    state.externalLinks = externalLinks
  }

}

const geoProfilesFormat = geoProfiles => {
  return geoProfiles.map(geo => {
    return {
      id: geo.id,
      name: geo.name,
      type: geo.type,
      dma: geo.type === 'dma' ? geo.data : [],
      zip: geo.type === 'zip' ? geo.data : [],
      city: geo.type === 'city' ? geo.data : [],
      state: geo.type === 'state' ? geo.data : [],
      county: geo.type === 'county' ? geo.data : [],
      country: geo.type === 'country' ? geo.data : [],
      congressionalDistrict: geo.type === 'congressionalDistrict' ? geo.data : [],
      canadaProvince: geo.type === 'canadaProvince' ? geo.data : [],
      canadaCity: geo.type === 'canadaCity' ? geo.data : [],
      canadaPostalcode: geo.type === 'canadaPostalcode' ? geo.data : [],
      default: geo.default
    }
  })
}

export default mutations
