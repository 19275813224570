export default [
  {
    path: 'support-user',
    name: 'AdminSupportUser',
    component: () => import('@/views/admin/supportuser/Index.vue'),
    children: [
      {
        path: 'list',
        alias: '',
        name: 'AdminSupportUserList',
        component: () => import('@/views/admin/supportuser/List.vue'),
        meta: {
          headerText: 'Support User Admin',
          headerBackRoute: 'AdminHome'
        }
      },
      {
        path: 'new',
        name: 'AdminSupportUserNew',
        component: () => import('@/views/admin/supportuser/New.vue'),
        meta: {
          headerText: 'New Support User',
          headerBackRoute: 'AdminSupportUserList'
        }
      },
      {
        path: 'edit/:id',
        name: 'AdminSupportUserEdit',
        component: () => import('@/views/admin/supportuser/Edit.vue'),
        meta: {
          headerText: 'Edit Support User',
          headerBackRoute: 'AdminSupportUserList'
        }
      }
    ]
  }
]
