import Vue from 'vue'

const actions = {
  getUserTasks (context, params) {
    return Vue.api.get('/user-tasks', { params })
  },

  getUserTask (context, id) {
    return Vue.api.get(`/user-tasks/${id}`)
  },

  createUserTask (context, data) {
    return Vue.api.post('/user-tasks', { data })
  },

  updateUserTask (context, data) {
    const { id } = data
    delete data.id

    return Vue.api.patch(`/user-tasks/${id}`, { data })
  },

  deleteUserTask (context, id) {
    return Vue.api.delete(`/user-tasks/${id}`)
  },

  completeUserTask (context, id) {
    return Vue.api.post(`/user-tasks/${id}/complete`)
  }
}

export default actions
