import { mapMutations, mapActions } from 'vuex'
import { sortBy } from 'lodash-es'

const CLIENT_CREATE_FLOWS = ['quickCreate']

export default {
  data () {
    return {
      companyGroups: [],
      selectedCompanyGroupIdForClientCreation: this.$store.state.User.user.companyGroup.id
    }
  },

  created () {
    this.populateGroupList()
  },

  computed: {
    canCreateClientForCompany () {
      return this.permissions.clientCreateForAll ||
        (
          this.permissions.clientCreateForOwnCompany &&
          this.selectedCompanyId === this.currentUserCompany.id
        )
    },

    companyGroupOptionsForClientCreation () {
      const options = []
      if (this.canCreateClientForCompany && this.companyGroups) {
        this.companyGroups.forEach(group => {
          if (
            group.value !== null &&
            group.value !== this.currentUserCompanyGroup.id
          ) {
            options.push({ value: group.id, text: group.name })
          }
        })
      } else if (
        this.canCreateClientForGroup &&
        this.selectedCompanyId === this.currentUserCompany.id
      ) {
        options.push({
          value: this.currentUserCompanyGroup.id,
          text: this.currentUserCompanyGroup.name
        })
      }

      return options
    },

    companyGroupOptions () {
      return this.$store.state.Workspace.filterOptions.companyGroups
    },

    canCreateClientForGroup () {
      return this.permissions.clientCreateForAll ||
        (
          (
            this.permissions.clientCreateForOwnCompany ||
            this.permissions.clientCreateForOwnGroup
          ) &&
          this.selectedCompanyId === this.currentUserCompany.id
        )
    },

    currentUserCompanyGroup: {
      get () {
        return this.currentUser.companyGroup
      }
    },

    currentUserCompany: {
      get () {
        return this.currentUserCompanyGroup.company
      }
    },

    currentUser: {
      get () {
        return this.$store.state.User.user
      }
    },

    permissions () {
      return {
        clientCreateForAll: this.$permission.hasPermission('ClientCreateForAll'),
        clientCreateForOwnCompany: this.$permission.hasPermission('ClientCreateForOwnCompany'),
        clientCreateForOwnGroup: this.$permission.hasPermission('ClientCreateForOwnGroup'),
        viewAll: this.$permission.hasPermission('ClientViewAll'),
        viewOwnCompany: this.$permission.hasPermission('ClientViewForOwnCompany')
      }
    },

    selectedCompanyId () {
      return this.$store.state.Workspace.filters.companyId ||
             this.currentUserCompany.id
    },

    selectedCompanyGroupId () {
      return this.$store.state.Workspace.filters.companyGroupId ||
             this.currentUserCompanyGroup.id
    },

  },

  methods: {
    async addClient (flow = null) {
      if (this.companyGroupOptionsForClientCreation.length === 0) {
        this.$messaging.addErrorMessage('No permission to create a client for this group. Please check with support if you believe you should be allowed to.')
        return
      }

      if (this.companyGroupOptionsForClientCreation.length === 1) {
        this.selectedCompanyGroupIdForClientCreation = this.companyGroupOptionsForClientCreation[0].value
      }

      try {
        const [company, group] = await Promise.all([
          this.loadCompany(this.selectedCompanyId),
          this.loadGroup(this.selectedCompanyGroupIdForClientCreation)
        ])

        const enabledCountries = (group.enabledCountries)
        // enabled countries set on group level
          ? group.enabledCountries
          : (company && company.enabledCountries)
              // enabled countries set on company
              ? company.enabledCountries
              // Use US if can't find setting from group or company
              : ['US']

        this.setNewClientData({
          companyId: group.companyId,
          companyGroupId: group.id,
          companyGroupName: group.name,
          companyGroupEnableTeamsForClients: group.enableTeamsForClients,
          companyGroupUseGroupTeamSettings: group.useGroupTeamSettings,
          companyEnableTeamsForClients: company.enableTeamsForClients,
          enabledCountries,
          disableGeo: company.disableGeo,
          disableAudience: company.disableAudience
        })

        this.setExternalAccountParams(undefined)
        this.setExternalAccount(undefined)
        this.setExternalOpportunityParams(undefined)
        this.setExternalOpportunity(undefined)
        this.setHeaderBackRoute(null)

        const route = {
          name: 'NewClient',
        }
        if (flow && CLIENT_CREATE_FLOWS.includes(flow)) {
          route.query = { flow }
        }
        this.$router.push(route)
      } catch (error) {
        this.$messaging.addErrorMessage('No permission to create a client for this group. Please check with support if you believe you should be allowed to.')
      }
    },

    async populateGroupList () {
      try {
        if (this.permissions.viewAll || this.permissions.viewOwnCompany) {
          const allGroups = await this.retrieveAllGroups(this.selectedCompanyId)
          this.companyGroups = sortBy(allGroups, group => group.name.toLowerCase())
        } else {
          this.companyGroups = [this.currentUserCompanyGroup]
        }
      } catch (error) {
        this.$messaging.addErrorMessage('An error occurred. Please try again and contact support if the issue continues.')
      }
    },

    ...mapMutations({
      setNewClientData: 'SET_NEW_CLIENT_DATA',
      setExternalAccountParams: 'SET_EXTERNAL_ACCOUNT_PARAMS',
      setExternalAccount: 'SET_EXTERNAL_ACCOUNT',
      setExternalOpportunityParams: 'SET_EXTERNAL_OPPORTUNITY_PARAMS',
      setExternalOpportunity: 'SET_EXTERNAL_OPPORTUNITY',
      setHeaderBackRoute: 'SET_HEADER_BACK_ROUTE'
    }),

    ...mapActions([
      'loadCompany',
      'loadGroup',
      'retrieveAllGroups'
    ])
  }
}
