import state from './group.state'
import mutations from './group.mutations'
import actions from './group.actions'
import getters from './group.getters'

export default {
  state,
  mutations,
  actions,
  getters
}
