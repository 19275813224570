export default [
  {
    path: 'custom-fields',
    name: 'AdminCustomFields',
    component: () => import('@/views/admin/customfields/List.vue'),
    meta: {
      subNav: 'custom-fields',
      headerText: 'Custom Fields',
      headerBackRoute: 'AdminHome'
    }
  },
  {
    path: 'custom-fields/categories',
    name: 'AdminCustomFieldsCategories',
    component: () => import('@/views/admin/customfields/Categories.vue'),
    meta: {
      subNav: 'custom-fields',
      headerText: 'Custom Fields',
      headerBackRoute: 'AdminHome'
    }
  },
  {
    path: 'custom-fields/ui-configuration',
    name: 'AdminCustomFieldsUIConfiguration',
    component: () => import('@/views/admin/customfields/UIConfiguration.vue'),
    meta: {
      subNav: 'custom-fields',
      headerText: 'Custom Fields',
      headerBackRoute: 'AdminHome'
    }
  }
]
