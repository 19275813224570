import actions from './agency.actions'
import getters from './agency.getters'
import mutations from './agency.mutations'
import state from './agency.state'

export default {
  actions,
  mutations,
  state,
  getters
}
