import Vue from 'vue'

const actions = {
  listBookmarks (context, params) {
    const path = '/bookmarks'
    return Vue.api.get(path)
  }
}

export default actions
