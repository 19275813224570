import Vue from 'vue'

const actions = {
  loadOrdersByCampaignId (context, campaignId) {
    const path = `/campaigns/${campaignId}/external-orders`

    return Vue.api.get(path).then(res => res.list)
  },

  hasExternalOrders (context, campaignId) {
    const path = `/campaigns/${campaignId}/external-orders`
    const params = { limit: 0 }

    return Vue.api.get(path, { params }).then((res) => res.num > 0)
  },

  async loadOrderByOrderId (context, params) {
    const { id } = params
    delete params.id
    const path = `/external-order/load-external-order/${id}`

    return Vue.api.get(path, { params })
  },

  async loadItemsByOrderId (context, params) {
    const path = `/external-orders/${params.orderId}/external-order-items`

    return Vue.api.get(path)
  },

  async loadNotesByItemId (context, params) {
    const path = `/external-orders/${params.orderId}/external-order-items/${params.itemId}/external-order-item-notes`

    return Vue.api.get(path)
  },

  async loadProofsByItemId (context, params) {
    const path = `/external-orders/${params.orderId}/external-order-items/${params.itemId}/external-order-item-proofs`

    return Vue.api.get(path)
  },

  createOrderItemNote (context, params) {
    const { note } = params
    note.type = 'default'
    const path = `/external-orders/${params.orderId}/external-order-items/${params.itemId}/external-order-item-notes`

    return Vue.api.post(path, { data: note })
  },

  requestCancellation (context, params) {
    const { note } = params
    note.type = 'cancellation'
    const path = `/external-orders/${params.orderId}/external-order-items/${params.itemId}/external-order-item-notes`

    return Vue.api.post(path, { data: note })
  },

  requestProductionHoldResolution (context, params) {
    const { note } = params
    note.type = 'resolveProductionHold'
    const path = `/external-orders/${params.orderId}/external-order-items/${params.itemId}/external-order-item-notes`

    return Vue.api.post(path, { data: note })
  },

  uploadOrderItemNoteFile (context, data) {
    const externalCRMId = data.get('externalCRMId')
    data.delete('externalCRMId')
    const path = `/external-crms/${externalCRMId}/attachments`

    return Vue.api.post(path, { data })
  },

  generateSSOURL (context, data) {
    const { externalCRMId } = data
    delete data.externalCRMId
    const path = `/external-crms/${externalCRMId}/single-sign-on-urls`

    return Vue.api.post(path, { data })
  }
}

export default actions
