<template>
  <a-dialog
    v-if="messages.length > 0"
    ref="dialog"
    overlay="dark"
    type="info"
    vertical-height="grow"
    horizontal-width="large"
    :primary-button="false"
    class="dialog"
    @close-dialog="close"
  >
    <template #header>
      Messages
    </template>

    <template #content>
      <div
        v-for="message in messages"
        :key="message.timestamp"
        class="message"
      >
        <div class="message-time d-flex flex-row justify-content-between">
          <span>
            {{ getDate(message.timestamp) }}
          </span>
          <span>
            {{ getTime(message.timestamp) }}
          </span>
        </div>
        <span v-html="message.msg" />
      </div>
    </template>
  </a-dialog>
</template>

<script>
import dayjs from 'dayjs'

const EMIT_NOTIFICATION_EVENT = 'global-message'

export default {
  data () {
    return {
      messages: []
    }
  },

  created () {
    this.$globalEvent.addEventListener(EMIT_NOTIFICATION_EVENT, this.newMessageHandler)
  },

  beforeDestroy () {
    this.$globalEvent.removeEventListener(EMIT_NOTIFICATION_EVENT)
  },

  methods: {
    newMessageHandler (msg) {
      this.messages.push(msg)
    },

    close () {
      this.messages.splice(0, this.messages.length)
    },

    getDate (timestamp) {
      return dayjs(timestamp).format('M/D/YYYY')
    },

    getTime (timestamp) {
      return dayjs(timestamp).format('h:mm a')
    }
  }
}

</script>

<style scoped>
.dialog :deep(.dialog-content) {
  max-height: 90vh;
}

.message {
  border: 1px solid #DDD;
  background-color: #f8fafd;
  padding: 16px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 1.3rem;
  color: #333;
}

.message:not(:first-child) {
  margin-top: 10px;
}

.message-time {
  font-size: 11px;
  color: #4f6c88;
  margin-bottom: 10px;
}
</style>
