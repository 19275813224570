import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminLoadAllCompanyProductOptions (context, params = null) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/addons/options', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminUpdateCompanyProductOption (context, companyOption) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/addons/options/' + companyOption.id, companyOption)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateNewCompanyProductOption (context, companyOption) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/addons/options', companyOption)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateNewCompanyProductOptionValue (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/addons/options/value', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteCompanyOptionValue (context, valueId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/addons/options/value/' + valueId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteCompanyProductOption (context, optionId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/addons/options/' + optionId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadValueListSettings (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/addons/list-settings', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminUpdateValueListSettings (context, listSetting) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/addons/list-settings/' + listSetting.id, listSetting)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminCreateValueListSettings (context, createObj) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/addons/list-settings', createObj)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminDeleteValueListSettings (context, listSettingId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/addons/list-settings/' + listSettingId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadAllTacticSets (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/addons/tactic-set', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteTacticSet (context, tacticSetId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/addons/tactic-set/' + tacticSetId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadTacticSetDetails (context, tacticSetId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/addons/tactic-set/' + tacticSetId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateTacticSet (context, tacticSetData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/addons/tactic-set', tacticSetData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateTacticSet (context, tactSetEditObj) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/addons/tactic-set/' + tactSetEditObj.id, tactSetEditObj)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadTacticDetails (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/addons/tactics/' + data.id, { params: { companyId: data.companyId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateTactic (context, updateObj) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/addons/tactics/' + updateObj.data.id, updateObj)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminUpdateTacticOption (context, reqObj) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/addons/tactics/options/' + reqObj.data.id, reqObj)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminOrderTacticOptions (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/addons/tactics/options/reorder', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateNewTacticOption (context, tacticOption) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/addons/tactics/options', tacticOption)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateNewTactic (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/addons/tactics')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminFetchAllRateTypes (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/addons/reserved-values')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
