export default [
  {
    path: 'company',
    name: 'AdminCompany',
    component: () => import('@/views/admin/company/Index.vue'),
    children: [
      {
        path: 'company-list',
        alias: '',
        name: 'CompanyList',
        component: () => import('@/views/admin/company/Company.vue')
      },
      {
        path: 'group-list',
        alias: '',
        name: 'GroupList',
        component: () => import('@/views/admin/company/Group.vue')
      },
      {
        path: 'activity',
        alias: '',
        name: 'Activity',
        component: () => import('@/views/admin/company/Activity.vue')
      }
    ],
    meta: {
      headerText: 'Company Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'company-admin'
    }
  },
  {
    path: 'company/new',
    name: 'AdminNewCompany',
    component: () => import('@/views/admin/company/New.vue'),
    meta: {
      headerText: 'New Company',
      headerBackRoute: 'CompanyList',
      subNav: 'company',
      permRequired: true
    }
  },
  {
    path: 'company/edit/:id',
    name: 'AdminEditCompany',
    component: () => import('@/views/admin/company/edit/Index.vue'),
    children: [
      {
        path: 'company-settings',
        name: 'AdminEditCompanySettings',
        component: () => import('@/views/admin/company/edit/CompanySettings.vue')
      },
      {
        path: 'workflow-settings',
        name: 'AdminEditCompanyWorkflowSettings',
        component: () => import('@/views/admin/company/edit/WorkflowSettings.vue')
      }
    ],
    meta: {
      headerText: '{{COMPANY}}',
      headerBackRoute: 'CompanyList',
      subNav: 'company',
      permRequired: true
    }
  },
  {
    path: 'company/group/new',
    name: 'AdminNewGroup',
    component: () => import('@/views/admin/group/New.vue'),
    meta: {
      headerText: 'New Group',
      headerBackRoute: 'GroupList',
      subNav: 'group',
      permRequired: true
    }
  },
  {
    path: 'company/group/edit/:id',
    name: 'AdminEditGroup',
    component: () => import('@/views/admin/group/edit/Index.vue'),
    children: [
      {
        path: 'group-settings',
        name: 'AdminEditGroupSettings',
        component: () => import('@/views/admin/group/edit/GroupSettings.vue')
      },
      {
        path: 'workflow-settings',
        name: 'AdminEditGroupWorkflowSettings',
        component: () => import('@/views/admin/group/edit/WorkflowSettings.vue')
      }
    ],
    meta: {
      headerText: '{{GROUP}}',
      headerBackRoute: 'GroupList',
      subNav: 'group',
      permRequired: true
    }
  }
]
