export default [
  {
    path: 'product',
    name: 'AdminProduct',
    component: () => import('@/views/admin/product/Products.vue'),
    meta: {
      headerText: 'Product Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'product-admin'
    }
  },
  {
    path: 'dataset',
    name: 'AdminDataset',
    component: () => import('@/views/admin/product/Datasets.vue'),
    meta: {
      headerText: 'Product Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'product-admin'
    }
  },
  {
    path: 'product-options',
    name: 'AdminProductOptions',
    component: () => import('@/views/admin/product/Options.vue'),
    meta: {
      headerText: 'Product Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'product-admin'
    }
  },
  {
    path: 'edit-tactic-set/:id',
    name: 'AdminEditTacticSet',
    component: () => import('@/views/admin/product/EditTacticSet.vue'),
    meta: {
      headerText: 'Edit Sub-Product Set',
      headerBackRoute: 'AdminProductOptions'
    }
  },
  {
    path: 'product/new',
    name: 'AdminNewProduct',
    component: () => import('@/views/admin/product/NewProduct.vue'),
    meta: {
      headerText: 'New Product',
      headerBackRoute: 'AdminProduct',
      subNav: 'product-config'
    }
  },
  {
    path: 'product/edit/:id',
    name: 'AdminEditProduct',
    component: () => import('@/views/admin/product/edit/Index.vue'),
    children: [
      {
        path: 'product-details',
        alias: '',
        name: 'AdminProductDetails',
        component: () => import('@/views/admin/product/edit/EditProduct.vue')
      },
      {
        path: 'configuration',
        alias: '',
        name: 'AdminTacticsAndOptions',
        component: () => import('@/views/admin/product/edit/EditTacticsAndOptions.vue')
      },
      {
        path: 'collateral-and-documents',
        alias: '',
        name: 'AdminCollateralAndDocuments',
        component: () => import('@/views/admin/product/edit/EditCollateralAndDocuments.vue')
      },
      {
        path: 'submissions',
        alias: '',
        name: 'AdminSubmissions',
        component: () => import('@/views/admin/product/edit/EditSubmissions.vue')
      },
      {
        path: 'external-mappings',
        alias: '',
        name: 'AdminExternalMappings',
        component: () => import('@/views/admin/product/edit/EditExternalMappings.vue')
      },
      {
        path: 'recommendation-setting',
        alias: '',
        name: 'AdminRecommendationSettings',
        component: () => import('@/views/admin/product/edit/EditRecommendationSetting.vue')
      },
      {
        path: 'reco-v2-settings',
        alias: '',
        name: 'AdminRecoV2Settings',
        component: () => import('@/views/admin/product/edit/EditRecoV2Settings.vue')
      },
      {
        path: 'edit-slide/:templateId',
        name: 'AdminProductEditSlide',
        component: () => import('@/views/admin/presentation/EditSlide.vue'),
        meta: {
          hideHeader: false,
          hideNav: true,
          hideHeaderRight: true,
          subNav: 'none',
          headerText: 'Edit Slide',
          headerBackRoute: 'AdminCollateralAndDocuments',
          contentExtraClass: 'edit-presentation'
        }
      }
    ],
    meta: {
      headerText: '{{PRODUCT}}',
      headerBackRoute: 'AdminProduct',
      subNav: 'product-config'
    }
  },

  {
    path: 'external-product-mappings',
    name: 'AdminProductExternalMappings',
    component: () => import('@/views/admin/product/ExternalProductMappings.vue'),
    meta: {
      headerText: 'External Product Mappings',
      headerBackRoute: 'AdminProduct',
      subNav: 'product-admin'
    }
  },

  {
    path: 'recommendation-configuration',
    name: 'AdminProductRecommendation',
    component: () => import('@/views/admin/product/RecommendationConfiguration.vue'),
    meta: {
      headerText: 'Product Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'product-admin'
    }
  }
]
