export default {
  maps: {
    apiKey: ''
  },
  analytics: {
    apiKey: ''
  },
  oauth: {
    clientId: '409352233542-4tu1mrc9vlqeblivc29fij6kgd1at1g4.apps.googleusercontent.com',
    enabled: true
  }
}
