const mutations = {
  /**
   * @param state
   * @param permissions
   */
  SET_PERMISSIONS (state, permissions) {
    state.permissions = permissions
  },

  /**
   * @param state
   * @param permissionGroups
   */
  SET_PERMISSION_GROUPS (state, permissionGroups) {
    state.permissionGroups = permissionGroups
  },

  /**
   * @param state
   * @param company
   */
  SET_ADMIN_COMPANY (state, company) {
    state.company = company
    state.group = null
  },

  SET_ADMIN_PRODUCT_FILTER_GROUP (state, productFilterGroup) {
    state.productFilterGroup = productFilterGroup
  },

  SET_ADMIN_PRODUCT_FILTER_ACTIVE (state, productFilterActive) {
    state.productFilterActive = productFilterActive
  },

  SET_ADMIN_PRODUCT_FILTER_SEARCH (state, productFilterSearch) {
    state.productFilterSearch = productFilterSearch
  },

  SET_ADMIN_PRODUCT_FILTER_PAGE (state, productFilterPage) {
    state.productFilterPage = productFilterPage
  },

  /**
   * @param state
   * @param company
   */
  SET_ADMIN_COMPANY_LOGO (state, logo) {
    state.company.logo = logo
  },

  /**
   * @param state
   * @param showNewCompanyPane
   */
  SET_SHOW_NEW_COMPANY_PANE (state, showNewCompanyPane) {
    state.showNewCompanyPane = showNewCompanyPane
  },

  /**
   * @param state
   * @param group
   */
  SET_ADMIN_GROUP (state, group) {
    state.group = group
  },

  /**
   * @param state
   * @param showNewGroupPane
   */
  SET_SHOW_NEW_GROUP_PANE (state, showNewGroupPane) {
    state.showNewGroupPane = showNewGroupPane
  },

  RESET_ADMIN_STATE (state) {
    state.company = null
    state.group = null
  },

  SET_ADMIN_TAGS (state, tags) {
    state.tags = tags
  },

  SET_ADMIN_TAG_GROUPS (state, tagGroups) {
    state.tagGroups = tagGroups
  },

  SET_DATASET_GROUP (state, datasetGroup) {
    state.datasetGroup = datasetGroup
  },

  SET_ADMIN_COMPANIES (state, companies) {
    state.companies = companies
  },

  SET_ADMIN_GROUPS (state, groups) {
    state.groups = groups
  },

  RESET_ADMIN_STRATEGY_PREVIEW (state, strategyId) {
    state.strategyPreview = {
      strategyId,
      filters: {
        customFields: []
      }
    }
  },

  SET_ADMIN_STRATEGY_PREVIEW_FILTERS (state, filters) {
    state.strategyPreview.filters = filters
  }
}

export default mutations
