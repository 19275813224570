export default [
  {
    path: 'notification',
    name: 'AdminNotifications',
    component: { render: (h) => h('router-view') },
    redirect: { name: 'AdminNotificationsEmail' },
    children: [
      {
        path: 'email',
        name: 'AdminNotificationsEmail',
        component: () => import('@/views/admin/notifications/Email.vue')
      }, {
        path: 'slack',
        name: 'AdminNotificationsSlack',
        component: () => import('@/views/admin/notifications/Slack.vue')
      }
    ],
    meta: {
      headerText: 'Notification Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'AdminNotifications'
    }
  },

  {
    path: 'notification-configurations',
    name: 'AdminNotificationConfiguration',
    component: { render: (h) => h('router-view') },
    redirect: { name: 'AdminNotificationConfigurationUserNotificationType' },
    children: [
      {
        path: 'email-template-mapping',
        name: 'AdminNotificationConfigurationEmail',
        component: () => import('@/views/admin/notification-configurations/EmailTemplateMapping.vue')
      },
      {
        path: 'user-notification-types',
        name: 'AdminNotificationConfigurationUserNotificationType',
        component: () => import('@/views/admin/notification-configurations/UserNotificationTypes.vue')
      },
      {
        path: 'user-notification-templates',
        name: 'AdminNotificationConfigurationUserNotificationTemplate',
        component: () => import('@/views/admin/notification-configurations/UserNotificationTemplates.vue')
      }
    ],
    meta: {
      headerText: 'Notification Configuration',
      headerBackRoute: 'AdminHome',
      subNav: 'AdminNotificationConfiguration'
    }
  }
]
