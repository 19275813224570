import Vue from 'vue'

function create (campaignId, data = {}) {
  const path = `/campaigns/${campaignId}/versions`
  return Vue.api.post(path, { data })
}

function update (campaignId, versionId, data = {}) {
  const path = `/campaigns/${campaignId}/versions/${versionId}`
  return Vue.api.patch(path, { data })
}

const status = {
  update (campaignId, versionId, data = {}) {
    const path = `/campaigns/${campaignId}/versions/${versionId}/is-active`
    return Vue.api.patch(path, { data })
  }
}

export default {
  create,
  update,
  status
}
