import Vue from 'vue'

function list (params = {}) {
  const path = '/admin/user-notification-templates'
  return Vue.api.get(path, { params })
}

function create (data = {}) {
  const path = '/admin/user-notification-templates'
  return Vue.api.post(path, { data })
}

function update (data = { }) {
  const { id } = data
  delete data.id
  const path = `/admin/user-notification-templates/${id}`
  return Vue.api.patch(path, { data })
}

function _delete (id) {
  const path = `/admin/user-notification-templates/${id}`
  return Vue.api.delete(path)
}

function save (data = {}) {
  return data.id ? update(data) : create(data)
}

export default {
  update,
  save,
  list,
  delete: _delete
}
