export default {
  looker: {
    instance: 'reporting'
  },
  hubspot: {
    portalId: '7362570'
  },
  walkme: {
    enabled: true,
    environment: 'prod'
  }
}
