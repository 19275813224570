<template>
  <span
    class="mr-2 cursor-pointer rfp-name"
    v-html="highlightQuery(text)"
  />
</template>

<script>
export default {
  props: {
    searchString: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },

  methods: {
    highlightQuery (string) {
      if (!this.searchString) return string

      const escapedSearchString = this.searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(escapedSearchString, 'gi')

      return string.replace(regex, (match) => `<span class="highlight-search-text">${match}</span>`)
    }
  }
}
</script>
