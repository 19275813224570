<template>
  <div>
    <div class="notification-wrapper">
      <div :class="{ 'blue-dot': notification.isUnread }" />
      <div
        class="pfp"
      >
        <advisr-notification-icon
          w="35"
          h="35"
        />
      </div>
      <div class="content">
        <div
          class="text"
        >
          {{ notification.text }}
          {{ notification.subText }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AdvisrNotificationIcon } from '@/components/icons'
export default {
  components: {
    AdvisrNotificationIcon
  },
  props: {
    notification: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
    }
  }
}
</script>

  <style scoped>
  .notification-wrapper{
    display: flex;
    padding-top:20px;
  }
  .pfp{
    height: auto;
    width: auto;
    margin-right:4%;
    border-radius: 50%;
  }
  .content{
    flex-grow:1;
  }
  .header{
    display:flex;
    justify-content: space-between;
    color: rgb(97, 91, 91);
  }

  .space-x-2 {
    column-gap: 0.5rem;
  }
  .text{
    padding: 0px 0 10px 0 ;
    max-width: 415px;
    line-height: 1.5;
  }
  .expandable-box {
    height: 33px;
    overflow: hidden;
    transition: all 0.2s;
    padding: 10px 22px 10px 10px;
    font-style: italic;
    line-height: 1.5;

  }

  .expandable-box-show {
    height: auto;
    overflow: hidden;
    padding: 10px 22px 10px 10px;
    font-style: italic;
    line-height: 1.5;

  }
  .comment-wrapper{
    position: relative;
    left: 10%;
    margin-bottom: 20px;
    width: 90%;
    background-color: rgb(232, 232, 232);
  }
  .expand-button{
    position: absolute;
    top: 5px;
    left: 92%;
  }
  .user-name{
    font-weight: 600;
  }
  .blue-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 10px;
    margin-right: 8px;
    background-color: #2e76bc;
  }

  </style>
