export default {
  looker: {
    instance: 'reporting.demo'
  },
  hubspot: {
    isEnabled: false
  },
  walkme: {
    enabled: true,
    environment: 'demo'
  }
}
