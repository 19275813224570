import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminFormList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/form/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminFormValidate (context, form) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/form/validate', { form })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminFormCreate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/form/create', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminFormCopy (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/form/copy', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminFormLoad (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/form/load/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminFormUpdate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/form/update', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminFormDelete (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/form/delete', { id })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
