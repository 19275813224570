import { mapMutations } from 'vuex'

export default {
  computed: {
    $messaging: {
      get () {
        return {
          addErrorMessage: this.addErrorMessage,
          addInfoMessage: this.addInfoMessage,
          addSuccessMessage: this.addSuccessMessage
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      addErrorMessage: 'ADD_ERROR_MESSAGE',
      addInfoMessage: 'ADD_INFO_MESSAGE',
      addSuccessMessage: 'ADD_SUCCESS_MESSAGE'
    })
  }
}
