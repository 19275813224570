import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  getCampaignsForDashboard (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/dashboard/monthly-dashboard-campaigns', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getClientsForDashboard (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/dashboard/monthly-dashboard-clients', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getPresentationsForDashboard (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/dashboard/monthly-dashboard-presentations', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getCountsForWidgets (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/dashboard/initial-monthly-dashboard-numbers', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getCurrentDashboard (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/dashboard/current-dashboard', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  updateGoal (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .patch(apiRoot + '/dashboard/update-goal', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getUserCompanyDashboardSettings (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/dashboard/company-dashboard-settings')
        .then(response => {
          context.commit('SET_COMPANY_DASHBOARD_SETTINGS', { showActivityTab: response.body.showActivityTab ?? true })
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
