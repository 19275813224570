import { merge } from 'lodash-es'

const defaultBranding = {
  assets: {
    loginLogo: '/images/logos/advisr-horizontal.png',
    loginSubLogo: null
  },
  auth: {
    showAdvisrLogin: true,
    showForgotPassword: true,
    showGoogleLogin: true,
    ssoProvider: false
  },
  okta: false
}

const init = (brand) => merge({}, defaultBranding, brand)

const branding = {
  default: defaultBranding,

  localhost: init({
    okta: {
      issuer: 'https://dev-52376643.okta.com/oauth2/default',
      clientId: '0oa8d1ea8rtlYHC9p5d7'
    }
  }),

  cmglocalsolutions: init({
    assets: {
      loginLogo: '/images/cmg/logo.png',
      loginSubLogo: '/images/logos/powered-by-advisr.png'
    }
  }),

  mcclatchy: init({
    assets: {
      loginLogo: '/images/mcclatchy/logo.png'
    },
    auth: {
      showForgotPassword: false,
      showAdvisrLogin: false,
      showGoogleLogin: false,
      ssoProvider: 'mcclatchy-okta'
    }
  }),

  okta: init({
    assets: {
      loginLogo: '/images/mcclatchy/logo.png'
    },
    auth: {
      showForgotPassword: false,
      showAdvisrLogin: false,
      ssoProvider: 'advisr-okta'
    }
  }),

  toastedcollective: init({
    assets: {
      loginLogo: '/images/toasted-collective/logo.png',
      loginSubLogo: '/images/logos/powered-by-advisr.png'
    }
  })
}

export default branding
