import Vue from 'vue'

function create (contactId, data = {}) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api.post(`/contacts/${contactId}/comments`, { params, data })
}

function list (contactId, data = {}) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api.get(`/contacts/${contactId}/comments`, { params, data })
}

function _delete (contactId, id) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api.delete(`/contacts/${contactId}/comments/${id}`, { params })
}

export default {
  create,
  list,
  delete: _delete
}
