import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  /*
   * Product admin actions
   */
  adminRetrieveProductTree (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/tree', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminEditProduct (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/product/edit/', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminEditCompanyProduct (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/edit-company-product', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminEditGroupProduct (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/edit-group-product', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminDeleteCompanyProduct (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/delete-company-product', params)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  adminDeleteGroupProduct (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/delete-group-product', params)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  adminUploadProductLogo (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/upload-logo', formData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUploadProductMedia (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/upload-media', formData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminEditProductMedia (context, formData) {
    return new Promise((resolve, reject) => {
      resolve()
      Vue.http
        .post(apiRoot + '/admin/product/edit-media', formData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteProductMedia (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/delete-media', data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  adminSetProductMediaOrder (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/set-media-order', data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  adminUploadProductDocument (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/upload-document', formData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminEditProductDocument (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/edit-document', formData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteProductDocument (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/delete-document', data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductLoadAll (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminProductListCategories (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/list-categories', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminCreateProduct (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/new', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadProduct (context, productId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/load/' + productId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadProductByInternalName (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/load-by-internal-name', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminCopyProductSameCompany (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/copy/same-company', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminCopyProductAnotherCompany (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/copy/another-company', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminCreateProductCategory (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/new-category', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminSetProductRecommendation (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/recommendation-settings', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteProductCategory (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/delete-category/' + data.id, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminEditProductCategory (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/edit-category', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductDisableGroup (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/disable-group', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductEnableGroup (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/enable-group', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadRelatedProducts (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/related-products/' + params.productId + '/' + params.dsProductId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadRelatedProductsV2 (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/related-products-v2/' + data.productId, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadRecoConfigProductList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/reco-config-list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateRecoConfigProductList (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/update-reco-config', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminRecoConfigCampaignGoalList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/reco-config-campaign-goal-list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateProductAddonSettings (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/product/addons-settings/' + data.id, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminGetProductOptions (context, productId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/options/' + productId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminAddNewProductOption (context, productObj) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/options/', productObj)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminEditProductOption (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/product/options/' + data.id, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteProductOption (context, prodOptionId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/product/options/' + prodOptionId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminOrderProductOptions (context, orderedList) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/product/options/reorder', orderedList)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminGetProductTactics (context, prodId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/tactics/' + prodId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateProductTactics (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/tactics/' + data.id, data.tactics)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCopyProductTactic (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/tactics/copy', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateProductTactics (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/product/tactics/' + data.id, data.tactics)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductCopyFromTacticSet (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/addons/copy/from/tactic-set', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductCopyToTacticSet (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/addons/copy/to/tactic-set', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductGetSlides (context, prodId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/slides/' + prodId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductUpdateSlideOrder (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/update-slide-order', data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductUpdateSlideVisibility (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/update-slide-visibility', data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductRemoveSlide (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/remove-slide', data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductAdpointMediaChannels (context, externalCRMId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/adpoint/media-channels/' + externalCRMId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductAdpointMedia (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/adpoint/media/' + params.externalCRMId, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductAdpointAdSizes (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/adpoint/ad-sizes/' + data.externalCRMId + '/' + data.mediaId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductAdpointInsertTypes (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/adpoint/insert-types/' + params.externalCRMId, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductAdpointPlacements (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/adpoint/placements/' + params.externalCRMId, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductSaveAdpointMappings (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/adpoint/save', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminProductLoadAdpointMappings (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/product/adpoint/load', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
