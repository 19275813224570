<template>
  <div class="attachment">
    <icon-link
      class="attachment-remove"
      icon="Delete"
      w="16"
      h="16"
      title="Delete"
      @click="remove"
    />
    <div class="attachment-name">
      {{ fileName }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    fileName: {
      type: String,
      required: true
    }
  },
  methods: {
    remove () {
      this.$emit('remove', this.fileName)
    }
  }
}

</script>

<style scoped>

.attachment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.attachment-name {
  vertical-align: middle;
  margin-right: 8px;
}

.attachment-remove {
  margin: 2px 5px 0 0;
}

</style>
