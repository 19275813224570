import { assets } from '@/config'
const { assetsRoot, userAvatarAssetPath } = assets

const getters = {

  /**
   * @param state
   */
  getUserAvatarURL: state => {
    if (state.user.avatar) return assetsRoot + userAvatarAssetPath + state.user.avatar
    return null
  }

}

export default getters
