import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  adminLoadCustomField (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/custom-fields/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListAllCustomFields (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/custom-fields', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateCustomField (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/custom-fields', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCheckUniqueCustomField (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/custom-fields/already-exists', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadCustomFieldSettings (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/custom-fields/settings', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  loadCustomFieldSettingsForPage (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/custom-fields/settings', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  loadCustomFieldsForPresentation (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/custom-fields/load-for-presentation', { params: { companyId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateCustomFieldSettings (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/custom-fields/settings', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateCustomField (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/custom-fields/' + data.id, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteCustomField (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/custom-fields/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListAllCustomFieldCategories (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/custom-fields/categories', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateCustomFieldCategory (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/custom-fields/categories', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateCustomFieldCategory (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/custom-fields/categories/' + data.id, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteCustomFieldCategory (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/custom-fields/categories/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListCustomFieldsForRuleByCompany (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/custom-fields/list-for-rules', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListCustomFieldsForDatasetsByCompany (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/custom-fields/list-for-datasets', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListCustomFieldsForPresentationByCompany (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/custom-fields/list-for-presentations', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
