class OutdatedError extends Error {
  constructor (error, message = error.message) {
    super(`OutdatedError: ${message}`)
    this.original = error

    this.stack = this.stack.split('\n').slice(0, 2).join('\n') + '\n' + error.stack
  }
}

export default OutdatedError
