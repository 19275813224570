import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  saveNewAgency (context, agency) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/agency/new', { agency })
        .then(response => {
          context.commit('SET_AGENCY', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  updateAgency (context, agency) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/agency/update', { agency })
        .then(response => {
          context.commit('SET_AGENCY', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },
  getAgencyListForWorkspace (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/agency/list-for-workspace', {
          params
        })
        .then(response => {
          context.commit('SET_AGENCY_LIST', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },
  findAgencyByNameAndCompany (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/agency/find-by-name-and-company', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  getAgencyListForCampaign (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/agency/list-for-campaign', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  getCampaignsPerAgencyInClient (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/agency/list-campaigns-per-agency', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  linkAgenciesToClient (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/agency/link-agencies-to-clients', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }

}
export default actions
