import Vue from 'vue'

function create ({
  companyId,
  externalIntegrationInstallationId,
  externalIntegrationUserSourceId,
  userId
} = {}) {
  const path = `/admin/external-integration-installations/${externalIntegrationInstallationId}/external-integration-user-mappings`

  const params = { companyId }
  const data = { userId, externalIntegrationUserSourceId }
  return Vue.api.post(path, { params, data })
}

function list ({
  companyId,
  externalIntegrationInstallationId
} = {}) {
  const path = `/admin/external-integration-installations/${externalIntegrationInstallationId}/external-integration-user-mappings`

  const params = { companyId }
  return Vue.api.get(path, { params })
}

function _delete ({
  companyId,
  externalIntegrationInstallationId,
  id
} = {}) {
  const path = `/admin/external-integration-installations/${externalIntegrationInstallationId}/external-integration-user-mappings/${id}`

  const params = { companyId }
  return Vue.api.delete(path, { params })
}

export default {
  list,
  create,
  delete: _delete
}
