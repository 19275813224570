import state from './campaign.state'
import mutations from './campaign.mutations'
import actions from './campaign.actions'
import getters from './campaign.getters'

export default {
  state,
  mutations,
  actions,
  getters
}
