import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  loadCampaignStatuses (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/load-statuses`)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadCampaignStatuses (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/campaign-status/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminAddCampaignStatus (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-status/new', data)
        .then(response => {
          resolve('ok')
        })
        .catch(error => reject(error.body))
    })
  },

  adminEditCampaignStatus (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-status/update/' + data.id, data)
        .then(response => {
          resolve('ok')
        })
        .catch(error => reject(error.body))
    })
  },

  adminReorderCampaignStatuses (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-status/reorder', data)
        .then(response => {
          resolve('ok')
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
