export default {
  assetsRoot: import.meta.env.VITE_ASSETS_URL,
  userAvatarAssetPath: '/uploads/avatars/',
  groupLogoAssetPath: '/uploads/groups/',
  companyLogoAssetPath: '/uploads/companies/',
  productLogoAssetPath: '/uploads/products/',
  clientLogoAssetPath: '/uploads/clients/',
  productMediaAssetPath: '/uploads/product_media/',
  productDocumentAssetPath: '/uploads/product_documents/',
  vendorLogoAssetPath: '/uploads/vendors/',
  presentationMediaPath: '/uploads/presentation_media/',
  slideTemplatePath: '/slide_template/',
  ioExportLogoPath: '/uploads/io_exports/',
  layoutTemplatePath: '/layout_template/',
  campaignAssetsPath: '/campaign-assets/'
}
