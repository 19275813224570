<template>
  <div
    id="app"
    class="d-flex justify-content-center"
    :style="overrideStyles"
  >
    <transition name="header-transition">
      <app-header
        v-if="!hideHeader && headerText"
        :header-text="headerText"
        :hide-nav="hideNav"
      />
    </transition>

    <transition name="header-transition">
      <sub-nav
        v-if="!hideHeader && subNav"
        :nav="subNav"
      />
    </transition>

    <app-nav v-if="!hideNav" />

    <div
      id="main-container"
      :class="mainContainerClasses"
      data-test="main-container"
    >
      <router-view
        :class="mainContentClasses"
      />
    </div>
    <deeplink-handler />
    <messaging />
    <terms-of-service-modal v-if="showTermsOfServiceModal" />
    <new-version-checker />
    <walkme />
    <global-messaging />
  </div>
</template>

<script>
import { findLast } from 'lodash-es'
import AppHeader from '@/components/layout/AppHeader'
import AppNav from '@/components/layout/AppNav'
import SubNav from '@/components/layout/SubNav'
import GlobalMessaging from '@/components/notifications/GlobalMessaging'
import { mapGetters } from 'vuex'
import DeeplinkHandler from '@/components/DeeplinkHandler'

export default {
  components: {
    AppHeader,
    AppNav,
    SubNav,
    GlobalMessaging,
    DeeplinkHandler
  },

  computed: {
    ...mapGetters(['getOverrideStyles']),

    hideHeader () {
      if (!this.$store.state.isUserAuthenticated) return true
      return !!this.findLastRoute('hideHeader')
    },

    hideNav () {
      if (!this.$store.state.isUserAuthenticated) return true
      return !!this.findLastRoute('hideNav')
    },

    contentExtraClass () {
      return this.findLastRoute('contentExtraClass')?.meta?.contentExtraClass
    },

    mainContentClass () {
      return this.findLastRoute('mainContentClass')?.meta?.mainContentClass
    },

    headerText () {
      return this.findLastRoute('headerText')?.meta?.headerText
    },

    subNav () {
      const route = this.findLastRoute('subNav')
      return route?.meta?.subNav !== 'none' && route?.meta?.subNav
    },

    mainContentClasses () {
      return this.mainContentClass || ['main-content']
    },

    mainContainerClasses () {
      const classes = ['main-container']

      if (!this.hideNav) classes.push('nav-visible')
      if (this.headerText) classes.push('header-visible')
      if (this.subNav) classes.push('subnav-visible')
      if (this.contentExtraClass) classes.push(this.contentExtraClass)

      return classes
    },

    company () {
      return this.$store.state.Group.company
    },

    overrideStyles () {
      const styles = {}

      Object.entries(this.getOverrideStyles).forEach(([name, style]) => {
        if (style.isVariable) {
          styles['--' + name] = style.value
        }
      })

      return styles
    },

    appTitle () {
      return this.overrideStyles['--app-title']
    },

    showTermsOfServiceModal () {
      return this.$store.state.showTermsOfService
    },

    deeplink () {
      return this.$store.state.deeplink
    }
  },

  watch: {
    appTitle (val) {
      document.title = val
    },
    deeplink: {
      deep: true,
      immediate: true,
      handler (deeplink) {
        if (deeplink) {
          const { topic, params } = deeplink
          this.$bus.$ready(topic, params)
          this.$store.commit('clearDeeplink')
        }
      }
    }
  },

  mounted () {
    const webfonts = document.createElement('link')
    webfonts.setAttribute('href', 'https://fonts.googleapis.com/css?family=Poppins:400,500,600,700')
    webfonts.rel = 'stylesheet'
    document.head.appendChild(webfonts)
  },

  methods: {
    findLastRoute (property) {
      return findLast(this.$route.matched, route => route.meta[property])
    }
  }
}
</script>

<style scoped>
#app {
  padding: 0px;
  margin: 0px;
  min-height: 100vh;
}

.main-container {
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 0;
  left: 0px;
  overflow-y: auto;
  padding: 30px 30px 0px 30px;
  /* transition: all var(--app-layout-transition-time) ease; */
}

.main-container.no-content-padding-y {
  padding: 0px 30px;
}

.main-container.no-content-padding {
  padding: 0;
}

.main-container.nav-visible {
  left: var(--app-nav-width);
}

.main-container.header-visible {
  top: var(--app-header-height);
}

.main-container.header-visible.subnav-visible {
  top: calc(var(--app-header-height) + var(--app-sub-nav-height));
}

.main-container.edit-presentation {
  top: calc(var(--app-header-height) + 40px);
  padding: 0 !important;
}

.main-container.reporting {
  padding: 0 !important;
}

.main-content {
  width: 100%;
  min-height: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  padding-bottom: 30px;
}

.main-content-full-width {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.main-content-full-width-no-padding {
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
}

.main-container.edit-presentation .main-content {
  max-width: 100vw;
}

.main-container.reporting .main-content {
  padding-bottom: 0px;
  max-width: 100%;
}

.main-container.workspace .main-content {
  max-width: 100%;
}

@media(max-width: 768px) {
  .main-content {
    padding: 0 1px 30px 1px
  }

  .main-container {
    padding: 30px 10px;
  }

  .main-container.nav-visible {
    left: 0px
  }
}
</style>
