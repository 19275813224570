import budgetProfiles from './budgetProfiles'
import datasetGroups from './datasetGroups'
import factors from './factors'
import rules from './rules'
import strategies from './strategies'

export default {
  budgetProfiles,
  datasetGroups,
  factors,
  rules,
  strategies
}
