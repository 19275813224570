export default {
  isEnabled: true,
  baseURL: 'https://app.hubspot.com/contacts/',
  portalId: '7362570',
  companySection: '/company/',
  contactSection: '/contact/',
  companyURL: function () {
    return this.baseURL + this.portalId + this.companySection
  },
  contactURL: function () {
    return this.baseURL + this.portalId + this.contactSection
  }
}
