export default [
  {
    path: 'campaign-statuses',
    name: 'AdminStatus',
    component: () => import('@/views/admin/campaignstatuses/List.vue'),
    meta: {
      headerText: 'Status Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'status-admin'
    }
  },
  {
    path: 'statuses-display',
    name: 'StatusesDisplay',
    component: () => import('@/views/admin/campaignstatuses/Display.vue'),
    meta: {
      headerText: 'Status Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'status-admin'
    }
  }
]
