export default [
  {
    path: 'external-api-access/list',
    name: 'AdminExternalApiAccessList',
    component: () => import('@/views/admin/externalapi/List.vue'),
    meta: {
      headerText: 'External API Access Admin',
      headerBackRoute: 'AdminHome'
    }
  }
]
