export default [
  {
    path: 'recommendation',
    name: 'AdminRecommendation',
    component: { render: (h) => h('router-view') },
    children: [
      {
        path: 'rules',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '',
            name: 'AdminRules',
            component: () => import('@/views/admin/recommendation/rules/List.vue')
          },
          {
            path: 'new',
            name: 'AdminAddRule',
            component: () => import('@/views/admin/recommendation/rules/AddEditRule.vue'),
            meta: {
              headerText: 'New Rule',
              subNav: 'none',
              headerBackRoute: 'AdminRules'
            }
          },

          {
            path: ':id',
            name: 'AdminEditRule',
            component: () => import('@/views/admin/recommendation/rules/AddEditRule.vue'),
            meta: {
              headerText: 'Edit Rule',
              subNav: 'none',
              headerBackRoute: 'AdminRules'
            }
          }
        ]
      },
      {
        path: 'factors',
        component: { render: (h) => h('router-view') },
        children: [
          {
            name: 'AdminFactors',
            path: '',
            component: () => import('@/views/admin/recommendation/factors/List.vue')
          },
          {
            path: 'new',
            name: 'AdminAddFactor',
            component: () => import('@/views/admin/recommendation/factors/AddFactor.vue'),
            meta: {
              headerText: 'New Factor',
              subNav: 'none',
              headerBackRoute: 'AdminFactors'
            }
          },
          {
            path: ':id',
            name: 'AdminEditFactor',
            component: () => import('@/views/admin/recommendation/factors/EditFactor.vue'),
            meta: {
              headerText: 'Edit Factor',
              subNav: 'none',
              headerBackRoute: 'AdminFactors'
            }
          }
        ]
      },
      {
        path: 'dataset-groups',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '',
            name: 'AdminDatasetAdmin',
            component: () => import('@/views/admin/recommendation/datasets/DataSetAdmin.vue')
          },
          {
            path: ':id',
            component: () => import('@/views/admin/recommendation/datasets/EditDatasetGroup.vue'),
            children: [
              {
                path: '',
                name: 'AdminDatasetList',
                component: () => import('@/components/admin/datasetsv2/DatasetList.vue')
              },
              {
                path: 'new',
                name: 'AdminAddDatasetToGroup',
                component: () => import('@/views/admin/recommendation/datasets/NewDataset.vue'),
                meta: {
                  headerText: 'New Dataset',
                  headerBackRoute: 'AdminDatasetList',
                  subNav: 'none'
                }
              },
              {
                path: 'products',
                name: 'AdminDatasetProducts',
                component: () => import('@/components/admin/datasetsv2/DatasetProducts.vue')
              },
              {
                path: 'goals',
                name: 'AdminDatasetGoals',
                component: () => import('@/components/admin/datasetsv2/DatasetGoals.vue')
              },
              {
                path: 'categories',
                name: 'AdminDatasetCategories',
                component: () => import('@/components/admin/datasetsv2/DatasetClientCategories.vue')
              }

            ],
            meta: {
              headerText: '{{DATASETGROUP}}',
              headerBackRoute: 'AdminDatasetAdmin',
              subNav: 'dataset-group'
            }
          }
        ]
      },
      {
        path: 'product-tiers',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '',
            name: 'AdminProductTiers',
            component: () => import('@/views/admin/recommendation/product-tiers/List.vue')
          },
          {
            path: 'new',
            name: 'AdminAddProductTier',
            component: () => import('@/views/admin/recommendation/product-tiers/AddEdit.vue'),
            meta: {
              headerText: 'New Product Tier',
              subNav: 'none',
              headerBackRoute: 'AdminProductTiers'
            }
          },

          {
            path: ':id',
            name: 'AdminEditProductTier',
            component: () => import('@/views/admin/recommendation/product-tiers/AddEdit.vue'),
            meta: {
              headerText: 'Edit Product Tier',
              subNav: 'none',
              headerBackRoute: 'AdminProductTiers'
            }
          }
        ]
      },
      {
        path: 'strategies',
        alias: '',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '',
            name: 'AdminStrategies',
            component: () => import('@/views/admin/recommendation/strategies/List.vue')
          },
          {
            path: ':strategyId',
            component: () => import('@/views/admin/recommendation/strategies/Edit.vue'),
            children: [
              {
                path: '',
                name: 'AdminEditStrategyPreview',
                component: () => import('@/views/admin/recommendation/strategies/Preview.vue')
              },
              {
                path: 'rules',
                name: 'AdminEditStrategyRules',
                component: () => import('@/views/admin/recommendation/strategies/Rules.vue')
              },
              {
                path: 'rank-factors',
                name: 'AdminEditStrategyRankFactors',
                component: () => import('@/views/admin/recommendation/strategies/Factors.vue')
              },
              {
                path: 'spend-factors',
                name: 'AdminEditStrategySpendFactors',
                component: () => import('@/views/admin/recommendation/strategies/Factors.vue')
              },
              {
                path: 'budget-baseline-factors',
                name: 'AdminEditStrategyBudgetBaselineFactors',
                component: () => import('@/views/admin/recommendation/strategies/Factors.vue')
              },
              {
                path: 'budget-influence-factors',
                name: 'AdminEditStrategyBudgetInfluenceFactors',
                component: () => import('@/views/admin/recommendation/strategies/Factors.vue')
              },
              {
                path: 'adjustments',
                component: () => import('@/views/admin/recommendation/strategies/adjustments/Index.vue'),
                children: [
                  {
                    path: '',
                    name: 'AdminEditStrategyAdjustments',
                    component: () => import('@/views/admin/recommendation/strategies/adjustments/List.vue')
                  },
                  {
                    path: 'new',
                    name: 'AdminEditStrategyNewAdjustment',
                    component: () => import('@/views/admin/recommendation/strategies/adjustments/AddEdit.vue'),
                    props: true,
                    meta: {
                      subNav: 'none',
                      headerText: 'New Adjustment',
                      headerLogo: 'client',
                      headerBackRoute: 'AdminEditStrategyPreview'
                    }
                  },
                  {
                    path: ':id',
                    name: 'AdminEditStrategyEditAdjustment',
                    component: () => import('@/views/admin/recommendation/strategies/adjustments/AddEdit.vue'),
                    meta: {
                      subNav: 'none',
                      headerText: 'Edit Adjustment',
                      headerLogo: 'client',
                      headerBackRoute: 'AdminEditStrategyAdjustments'
                    }
                  }
                ]
              }
            ],
            meta: {
              subNav: 'none',
              headerText: 'Recommendation Admin',
              headerBackRoute: 'AdminStrategies',
              mainContentClass: 'main-content-full-width'
            }
          }
        ]
      }
    ],
    meta: {
      headerText: 'Recommendation Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'recommendation-admin'
    }
  }
]
