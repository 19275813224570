const FileUtilPlugin = {
  install (Vue, options) {
    const fileUtilPlugin = {
      download (url, filename) {
        if (navigator.userAgent.includes('Firefox')) {
          const iframe = document.createElement('iframe')
          iframe.style.display = 'none'
          document.body.appendChild(iframe)

          iframe.onload = function () {
            const anchor = document.createElement('a')
            anchor.href = url
            if (filename) anchor.download = filename

            iframe.contentDocument.body.appendChild(anchor)
            anchor.click()

            // Remove the iframe after a delay to give the download time to start
            setTimeout(() => {
              document.body.removeChild(iframe)
            }, 2000)
          }

          iframe.src = 'about:blank'
        } else {
          const link = document.createElement('a')
          link.href = url
          link.click()
        }
      }
    }

    Vue.prototype.$fileUtil = fileUtilPlugin
  }
}

export default FileUtilPlugin
