// this file is temporary and exists
// only to help split these routes out
// with minimal merge conflicts

export default [
  {
    path: '/support',
    name: 'ZendeskSSO',
    alias: '/zendesk-sso',
    component: () => import('@/views/auth/ZendeskSSO.vue'),
    meta: {
      noAuth: false,
      hideHeader: true,
      hideNav: true
    }
  },

  {
    path: '/deeplink/:topic',
    component: () => import('@/views/Deeplink.vue'),
    meta: { noAuth: true }
  },

  /*
   * Root level pages
   */
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      navItem: 'Dashboard',
      headerText: 'Dashboard'
    }
  },

  {
    path: '/reporting',
    name: 'Reporting',
    component: () => import('@/views/reporting/Index.vue'),
    meta: {
      navItem: 'Reporting',
      headerText: 'Reporting',
      contentExtraClass: 'reporting',
      permRequired: true
    },
    children: [
      {
        path: ':dashboard',
        name: 'ReportingDashboard'
      }
    ]
  },

  /*
   * User pages
   */
  {
    path: '/initial-setup',
    name: 'InitialSetup',
    component: () => import('@/views/user/setup/Index.vue'),
    children: [
      {
        path: 'details',
        alias: '',
        name: 'InitialSetupDetails',
        component: () => import('@/views/user/setup/Details.vue')
      },
      {
        path: 'headshot',
        name: 'InitialSetupHeadshot',
        component: () => import('@/views/user/setup/Headshot.vue')
      },
      {
        path: 'password',
        name: 'InitialSetupPassword',
        component: () => import('@/views/user/setup/Password.vue')
      }
    ],
    meta: {
      hideHeader: true,
      hideNav: true
    }
  },
  {
    path: '/must-reset-password',
    name: 'MustResetPassword',
    component: () => import('@/views/user/setup/MustResetPassword.vue'),
    meta: {
      hideHeader: true,
      hideNav: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/user/Profile.vue'),
    meta: {
      headerText: 'My Profile'
    }
  },

  {
    path: '/new-client',
    name: 'NewClient',
    component: () => import('@/views/client/new/Index.vue'),
    children: [
      {
        path: 'search',
        alias: '',
        name: 'NewClientSearch',
        component: () => import('@/views/client/new/Search.vue')
      },
      {
        path: 'profile',
        name: 'NewClientProfile',
        component: () => import('@/views/client/new/Profile.vue')
      },
      {
        path: 'locations',
        name: 'NewClientLocation',
        component: () => import('@/views/client/new/Locations.vue')
      }
    ],
    meta: {
      navItem: 'My Workspace',
      headerText: 'Add New Client',
      headerBackRoute: 'WorkspaceClientList',
      permRequired: true
    }
  },

  // update for mycampaigns
  {
    path: '/create-campaign/:flow?',
    name: 'NewCampaign',
    component: () => import('@/views/client/view/NewCampaign.vue'),
    meta: {
      // navItem: 'Clients',
      navItem: 'My Workspace',
      headerText: 'Create New Campaign',
      headerLogo: 'client',
      headerBackRoute: 'WorkspaceCampaignList',
      subNav: 'none',
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width'
    }
  },
  {
    path: '/new-campaign/:id',
    name: 'NewCampaignDetails',
    component: () => import('@/views/campaign/new/Index.vue'),
    children: [
      {
        path: 'details',
        name: 'NewCampaignEditDetails',
        component: () => import('@/views/campaign/new/EditDetails.vue')
      },
      {
        path: 'audience',
        name: 'NewCampaignAudience',
        component: () => import('@/views/campaign/new/Audience.vue')
      },
      {
        path: 'geo',
        name: 'NewCampaignGeo',
        component: () => import('@/views/campaign/new/Geo.vue'),
        meta: {
          contentExtraClass: 'no-content-padding',
          mainContentClass: 'main-content-full-width'
        }
      },
      {
        path: 'products',
        name: 'NewCampaignProducts',
        component: () => import('@/views/campaign/new/products/Index.vue'),
        children: [
          {
            path: 'selection',
            name: 'NewCampaignProductSelection',
            component: () => import('@/views/campaign/new/products/Selection.vue')
          },
          {
            path: 'allocation',
            name: 'NewCampaignProductAllocation',
            component: () => import('@/views/campaign/new/products/Allocation.vue')
          },
          {
            path: 'select-options',
            name: 'NewCampaignProductOptions',
            component: () => import('@/views/campaign/new/products/Options.vue')
          }
        ]
      },
      {
        path: 'complete-express',
        name: 'NewCampaignCompleteExpress',
        component: () => import('@/views/campaign/new/EditDetails.vue')
      }
    ],
    meta: {
      // navItem: 'Clients',
      navItem: 'My Workspace',
      headerText: 'Create New Campaign',
      headerLogo: 'client',
      headerBackRoute: 'ClientMyCampaigns',
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width'
    }
  },
  {
    path: '/campaign/:id',
    name: 'CampaignView',
    component: () => import('@/views/campaign/view/Index.vue'),
    children: [
      {
        path: 'summary',
        name: 'CampaignSummary',
        alias: ['', 'info'],
        component: () => import('@/views/campaign/view/CampaignSummary.vue'),
        meta: {
          showLeftMenu: true,
          contentExtraClass: 'no-content-padding'
        }
      },
      {
        path: 'presentation-stats',
        name: 'CampaignPresentationStats',
        component: () => import('@/views/campaign/view/stats/View.vue'),
        meta: {
          headerText: '{{CAMPAIGN}}',
          headerLogo: 'client',
          headerBackRoute: 'CampaignSummary',
          showLeftMenu: true,
          contentExtraClass: 'no-content-padding'
        }
      },
      {
        path: 'order-details',
        name: 'CampaignOrders',
        component: () => import('@/views/campaign/view/external-order/View.vue'),
        meta: {
          headerText: '{{CAMPAIGN}}',
          headerLogo: 'client',
          headerBackRoute: 'CampaignSummary',
          showLeftMenu: true,
          contentExtraClass: 'no-content-padding'
        }
      },
      {
        path: 'files',
        name: 'CampaignFiles',
        component: () => import('@/views/campaign/view/Files/CampaignFile.vue'),
        meta: {
          showLeftMenu: true,
          contentExtraClass: 'no-content-padding'
        }
      },
      {
        path: 'workflow/:submissionId',
        name: 'CampaignWorkflowForm',
        component: () => import('@/views/campaign/view/shared/workflow/Form.vue'),
        meta: {
          showLeftMenu: true,
          contentExtraClass: 'no-content-padding'
        }
      },
      {
        path: 'support/:submissionId',
        name: 'CampaignSupportFormV2',
        component: () => import('@/views/campaign/view/shared/support/Form.vue'),
        meta: {
          showLeftMenu: true,
          contentExtraClass: 'no-content-padding'
        }
      },
      {
        path: 'submitted/:submissionId',
        name: 'CampaignSubmittedForm',
        component: () => import('@/views/campaign/view/shared/submitted/Form.vue'),
        meta: {
          showLeftMenu: true,
          contentExtraClass: 'no-content-padding'
        }
      },
      {
        path: 'edit-geo',
        name: 'CampaignEditGeo',
        component: () => import('@/views/campaign/view/shared/EditGeo.vue'),
        meta: {
          // navItem: 'Clients',
          navItem: 'My Workspace',
          headerText: '{{CAMPAIGN}}',
          headerLogo: 'client',
          headerBackRoute: 'CampaignSummary',
          contentExtraClass: 'no-content-padding',
          mainContentClass: 'main-content-full-width'
        }
      },
      {
        path: 'edit-audience',
        name: 'CampaignEditAudience',
        component: () => import('@/views/campaign/view/shared/EditAudience.vue'),
        meta: {
          // navItem: 'Clients',
          navItem: 'My Workspace',
          headerText: '{{CAMPAIGN}}',
          headerLogo: 'client',
          headerBackRoute: 'CampaignSummary',
          contentExtraClass: 'no-content-padding',
          mainContentClass: 'main-content-full-width'
        }
      },
      {
        path: 'campaign-details',
        name: 'EditCampaignDetails',
        component: () => import('@/views/campaign/view/shared/EditCampaignDetails.vue'),
        meta: {
          // navItem: 'Clients',
          navItem: 'My Workspace',
          headerText: '{{CAMPAIGN}}',
          headerLogo: 'client',
          headerBackRoute: 'CampaignSummary',
          permRequired: true,
          contentExtraClass: 'no-content-padding',
          mainContentClass: 'main-content-full-width'
        }
      },
      {
        path: 'fulfillment',
        name: 'CampaignFulfillment',
        component: () => import('@/views/campaign/view/shared/fulfillment/Index.vue'),
        children: [
          {
            path: 'submit',
            name: 'CampaignFulfillmentSubmit',
            component: () => import('@/views/campaign/view/shared/fulfillment/Submit.vue'),
            meta: {
              // navItem: 'Clients',
              navItem: 'My Workspace',
              headerText: '{{CAMPAIGN}}',
              headerLogo: 'client',
              headerBackRoute: 'CampaignSummary'
            }
          },
          {
            path: 'form/:submissionId',
            name: 'CampaignFulfillmentForm',
            component: () => import('@/views/campaign/view/shared/fulfillment/Form.vue'),
            meta: {
              // navItem: 'Clients',
              navItem: 'My Workspace',
              headerText: '{{CAMPAIGN}}',
              headerLogo: 'client',
              headerBackRoute: 'CampaignFulfillmentSubmit',
              contentExtraClass: 'no-content-padding'
            }
          },
          {
            path: 'crm-mappings/:crmId',
            name: 'SendToExternalSystem',
            component: () => import('@/views/campaign/view/external/PushMappingDetails.vue'),
            meta: {
              navItem: 'My Workspace',
              headerText: '{{CAMPAIGN}}',
              headerLogo: 'client',
              headerBackRoute: 'CampaignFulfillmentSubmit'
            }
          }
        ],
        meta: {
          permRequired: true
        }
      },
      {
        path: 'custom-fields',
        name: 'EditCampaignCustomFields',
        alias: ['campaign-custom-fields'],
        component: () => import('@/views/campaign/view/shared/EditCustomFields.vue'),
        meta: {
          hideHeader: false,
          hideNav: true,
          hideHeaderRight: false,
          headerText: '{{CAMPAIGN}}',
          headerLogo: 'client',
          headerBackRoute: 'CampaignSummary'
        }
      },
      {
        path: 'edit-presentation',
        name: 'CampaignEditPresentation',
        component: () => import('@/views/campaign/view/shared/EditPresentation.vue'),
        meta: {
          hideHeader: false,
          hideNav: true,
          hideHeaderRight: true,
          headerText: '{{CAMPAIGN}}',
          headerLogo: 'client',
          headerBackRoute: 'CampaignSummary',
          contentExtraClass: 'edit-presentation',
          permRequired: true
        }
      },
      {
        path: 'crm-mappings/:crmId',
        name: 'CampaignCrmMappings',
        component: () => import('@/views/campaign/view/external/AdpointPushMappingDetails.vue'),
        meta: {
          navItem: 'My Workspace',
          headerText: '{{CAMPAIGN}}',
          headerLogo: 'client',
          headerBackRoute: 'CampaignSummary'
        }
      },
      {
        path: 'schedules',
        name: 'CampaignSchedules',
        component: () => import('@/views/campaign/view/CampaignSchedules.vue'),
        meta: {
          headerBackRoute: 'CampaignSummary'
        }
      }
    ],
    meta: {
      // navItem: 'Clients',
      navItem: 'My Workspace',
      headerText: '{{CAMPAIGN}}',
      headerLogo: 'client',
      headerBackRoute: 'ClientMyCampaigns'
      // headerBackRoute: 'WorkspaceAllCampaigns'
    }
  },
  {
    path: '/edit-campaign/:id',
    name: 'CampaignEdit',
    component: () => import('@/views/campaign/edit/Index.vue'),
    children: [
      {
        path: 'product-addons',
        name: 'EditCampaignProductAddons',
        component: () => import('@/views/campaign/edit/EditProductAddons.vue')
      },
      {
        path: 'product-allocation',
        name: 'EditCampaignProductAllocation',
        component: () => import('@/views/campaign/edit/EditProductAllocation.vue')
      },
      {
        path: 'product-selection',
        name: 'EditCampaignProductSelection',
        component: () => import('@/views/campaign/edit/EditProductSelection.vue')
      }
    ],
    meta: {
      // navItem: 'Clients',
      navItem: 'My Workspace',
      headerText: '{{CAMPAIGN}}',
      headerLogo: 'client',
      headerBackRoute: null,
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'main-content-full-width'
    }
  },

  /*
   * Product pages
   */
  {
    path: '/products',
    name: 'ProductList',
    component: () => import('@/views/product/List.vue'),
    meta: {
      navItem: 'Products',
      headerText: 'Products'
    }
  },

  {
    path: '/asset-library',
    name: 'AssetLibrary',
    component: () => import('@/views/asset-manager/Index.vue'),
    meta: {
      navItem: 'Asset Library',
      headerText: 'Asset Library'
    }
  },

  /*
   * External deep links
   */
  {
    path: '/ext/campaign-create',
    name: 'ExtCampaignCreate',
    component: () => import('@/views/ext/CampaignCreate.vue')
  },

  /*
   * External pages
   */
  {
    path: '/external',
    name: 'External',
    component: () => import('@/views/external/Index.vue'),
    children: [
      {
        path: 'create-campaign',
        name: 'ExternalCreateCampaign',
        component: () => import('@/views/external/CreateCampaign.vue'),
        meta: {
          navItem: 'ExternalCreateCampaign',
          headerText: 'Create New Campaign: Select Client'
        }
      },
      {
        path: 'view-campaign',
        name: 'ExternalViewCampaign',
        component: () => import('@/views/external/ViewCampaign.vue'),
        meta: {
          navItem: 'ExternalViewCampaign',
          headerText: '{{CRM}}'
        }
      }
    ]
  },

  /*
   * Canned response pages
   */
  {
    path: '/unsupported-browser',
    name: 'UnsupportedBrowser',
    component: () => import('@/views/responses/UnsupportedBrowser.vue'),
    meta: {
      noAuth: true
    }
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('@/views/responses/Forbidden.vue'),
    meta: {
      noAuth: true
    }
  },

  /*
   * Test pages
   */
  {
    path: '/test/form/:id',
    name: 'FormTest',
    component: () => import('@/views/test/Form.vue'),
    meta: {
      headerText: 'Form Test'
    }
  },
  {
    path: '/test/input-types',
    name: 'InputTypesTest',
    component: () => import('@/views/test/InputTypes.vue'),
    meta: {
      headerText: 'Input Types Test'
    }
  },
  {
    path: '/test/icons',
    name: 'IconsTest',
    component: () => import('@/views/test/Icons.vue'),
    meta: {
      headerText: 'Icons Test'
    }
  },

  /*
   * Fall back if no routes are matched
   */
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/responses/NotFound.vue'),
    meta: {
      noAuth: true
    }
  }
]
