import Vue from 'vue'

function load (id) {
  const path = `/deals/${id}`
  return Vue.api.get(path)
}

function update (id, data = {}) {
  const path = `/deals/${id}`
  return Vue.api.patch(path, { data })
}

function list (params = {}) {
  const path = '/deals'
  return Vue.api.get(path, { params })
}

export default {
  load,
  update,
  list
}
