import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  adminListAllTemplates (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/template/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadTemplate (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/template/load/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateTemplate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/template/new', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateTemplate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/template/update/' + data.id, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteTemplate (context, templateId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/template/' + templateId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminSaveTemplateLayoutSections (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/template/save-layout/' + data.templateId, data.sections)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminSetDefaultCategory (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/template/set-default/', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
