<template>
  <div
    ref="appNav"
    class="nav"
  >
    <div
      class="menu d-flex align-items-center justify-content-center"
      @click="toDefaultRoute"
    >
      <img
        src="/images/logos/advisr-icon.png"
        class="logo-icon"
      >
    </div>
    <div
      ref="navBar"
      class="nav-bar d-flex flex-column"
      :class="{ 'bookmark-pane-open': shouldShowBookmarkPane }"
    >
      <div
        v-for="(item, index) in navItems"
        :key="index"
        class="nav-item d-flex justify-content-center align-items-center"
        :class="{ active: isActive(item.text), disabled: item.disabled }"
      >
        <a-link
          :disabled="item.disabled"
          :prevent-default="item.preventDefault"
          :data-test="item.datatest ? item.datatest : null"
          :href="item.href"
          :to="item.route"
          class="icon d-flex justify-content-center align-items-center"
          :tooltip="item.text"
          tooltip-position="right"
          @click="() => { item.click?.() }"
        >
          <component
            :is="item.icon"
            w="24"
            h="24"
            title=" "
            class="d-flex justify-content-center align-items-center"
          />
        </a-link>
      </div>
      <bookmark-pane
        ref="bookmarkPane"
        :show="shouldShowBookmarkPane"
        @close="hideBookmarkPane"
      />
    </div>
    <!--This is the alert to introduce the help center, it will be shown at first visit.  -->
    <support-alert
      v-if="shouldShowSupportAlert"
      :support-icon-index="supportIconIndex"
      :is-nav-expanded="isExpanded"
    />
    <!-- This is the actual Support form to send emails, if set in the company's settings -->
    <support
      :should-show="shouldShowEmailSupport"
      @close="closeSupport"
    />
  </div>
</template>

<script>
import { findLast, findIndex } from 'lodash-es'
import { mapActions } from 'vuex'
import { NavIcons } from '@/components/icons'
import Support from '@/components/support/Support'
import SupportAlert from '@/components/support/SupportAlert'
import SupportUtils from '@/mixins/SupportUtils'
import BookmarkPane from '@/components/bookmarks/BookmarkPane.vue'

export default {
  components: {
    ...NavIcons,
    Support,
    SupportAlert,
    BookmarkPane
  },

  mixins: [SupportUtils],

  data () {
    return {
      isExpanded: false,
      navToggleClicked: false,
      windowWidth: null,
      hideNavContainer: false,
      shouldShowBookmarkPane: false,
      bookmarks: []
    }
  },

  computed: {
    navItems: {
      get () {
        const navItems = []

        if (this.$permission.hasPermission('Dashboard')) {
          navItems.push({
            text: 'Dashboard',
            icon: 'DashboardIcon',
            route: { name: 'Dashboard' },
            disabled: false
          })
        }
        navItems.push({
          text: 'My Workspace',
          icon: 'WorkspaceIcon',
          route: { name: 'Workspace' },
          disabled: false,
          datatest: 'clients'
        })
        navItems.push({
          text: 'Products',
          icon: 'ProductsIcon',
          route: { name: 'ProductList' },
          disabled: false
        })

        if (this.assetManagerAvailableForUser) {
          navItems.push({
            text: 'Asset Library',
            icon: 'MediaAssetIcon',
            route: { name: 'AssetLibrary' },
            disabled: false
          })
        }

        if (this.$permission.hasPermission('Notifications')) {
          navItems.push({
            text: 'Notifications',
            icon: 'NotificationsIcon',
            route: null,
            disabled: true
          })
        }

        if (this.$permission.hasPermission('Reporting')) {
          navItems.push({
            text: 'Reporting',
            icon: 'ReportingIcon',
            route: { name: 'Reporting' },
            disabled: !this.hasReportingDashboards
          })
        }

        if (this.bookmarks.length > 0) {
          navItems.push({
            text: 'Bookmarks',
            icon: 'BookmarkIcon',
            preventDefault: true,
            click: this.toggleBookmarkPane
          })
        }

        if (this.$permission.hasPermission('AdminUIAccess')) {
          navItems.push({
            text: 'Admin',
            icon: 'SettingsIcon',
            route: { name: 'AdminHome' },
            disabled: false
          })
        }

        if (this.shouldShowSupportIcon) {
          navItems.push({
            text: 'Support',
            icon: 'SupportIcon',
            href: '/support',
            disabled: false,
            preventDefault: true,
            click: this.toggleSupport
          })
        }

        return navItems
      }
    },

    activeRouteNavItem: {
      get () {
        const matchedRouteNoAuth = findLast(this.$route.matched, route => {
          return route.meta.navItem
        })
        if (matchedRouteNoAuth) return matchedRouteNoAuth.meta.navItem
        return null
      }
    },

    userIsLoaded () {
      return this.$store.state.User.isLoaded
    },

    user () {
      return this.$store.state.User.user
    },

    company () {
      return this.user.companyGroup.company
    },

    assetManagerEnabledInCompany () {
      return this.$store.state.User.user.companyGroup?.company?.isAssetManagerEnabled ?? false
    },

    assetManagerAvailableForUser: {
      get () {
        return this.assetManagerEnabledInCompany &&
        ((this.$permission.hasPermission('AssetLibraryViewForOwnGroup') || this.$permission.hasPermission('AssetLibraryViewForOwnCompany')) ||
        (this.$permission.hasPermission('AssetLibraryEditForOwnGroup') || this.$permission.hasPermission('AssetLibraryEditForOwnCompany')) ||
        (this.$permission.hasPermission('AssetLibraryEditForAll') || this.$permission.hasPermission('AssetLibraryViewForAll')))
      }
    },

    canViewNotifications () {
      return this.$permission.hasPermission('Notifications')
    },

    canViewReporting () {
      return this.$permission.hasPermission('Reporting')
    },

    hasReportingDashboards () {
      return this.userIsLoaded && this.user.dashboards.length > 0
    },

    supportIconIndex () {
      return findIndex(this.navItems, { text: 'Support' })
    }
  },

  async mounted () {
    await this.loadCompanyDashboardSettings()
    this.bookmarks = await this.listBookmarks()

    window.addEventListener('click', this.handleAllClicks)
    window.addEventListener('resize', this.getWindowWidth)
    this.$nextTick(() => window.addEventListener('load', this.assignWidowWidth))
    if (this.$refs.appNav) this.windowWidth = this.$refs.appNav.parentElement.offsetWidth
  },

  beforeDestroy () {
    window.removeEventListener('click', this.handleAllClicks)
    window.removeEventListener('resize', this.getWindowWidth)
    window.removeEventListener('load', this.assignWidowWidth)
  },

  methods: {
    toggleNav () {
      this.navToggleClicked = true
      this.isExpanded = !this.isExpanded
      if (this.windowWidth && this.windowWidth <= 768) {
        this.isExpanded
          ? this.$refs.navBar.style.left = 0
          : this.$refs.navBar.style.left = '-60px'
      }
      this.shouldHideNavContainer()
    },

    onClick () {
      if (this.windowWidth !== null && this.windowWidth <= 768) {
        this.$refs.navBar.style.left = '-60px'
      }
    },

    handleAllClicks (e) {
      if (!this.shouldShowBookmarkPane) this.shouldShowBookmarkPane = false
      if (this.navToggleClicked) this.navToggleClicked = false
      else if (this.isExpanded) {
        this.onClick()
        this.isExpanded = false
      }
    },

    getWindowWidth (e) {
      this.windowWidth = e.target.innerWidth
      if (this.$refs.navBar) {
        if (this.windowWidth > 768 && !this.isExpanded) {
          this.$refs.navBar.style.left = 0
        } else if (this.windowWidth <= 768 && !this.isExpanded) {
          this.$refs.navBar.style.left = '-60px'
        }
      }
      this.shouldHideNavContainer()
    },

    assignWidowWidth (event) {
      this.windowWidth = event.currentTarget.innerWidth
      this.shouldHideNavContainer()
    },

    isActive (name) {
      return this.activeRouteNavItem === name
    },

    shouldHideNavContainer () {
      if (this.isExpanded) {
        this.hideNavContainer = false
      } else {
        this.hideNavContainer = !(this.windowWidth > 768)
      }
    },

    toDefaultRoute () {
      this.$router.push({
        name: this.$permission.defaultRoute()
      })
    },

    async loadCompanyDashboardSettings () {
      await this.getUserCompanyDashboardSettings()
    },

    toggleBookmarkPane () {
      this.shouldShowBookmarkPane = !this.shouldShowBookmarkPane
    },

    toggleSupport () {
      this.hideBookmarkPane()
      this.openSupportMethod()
    },

    hideBookmarkPane () {
      this.shouldShowBookmarkPane = false
    },

    ...mapActions([
      'listBookmarks',
      'getUserCompanyDashboardSettings'
    ])
  }
}
</script>

<style scoped>

.nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--app-nav-width);
  z-index: 100;
  box-sizing: content-box;
  border-right: 1px solid var(--border-color);
}

.menu {
  background-color: var(--app-nav-background);
  width: var(--app-nav-width);
  height: var(--app-header-height);
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
}

.logo-icon {
  width: 28px;
  height: auto;
}

.nav-bar {
  background-color: var(--app-nav-main-color);
  background: var(--app-nav-background);
  position: fixed;
  left: 0;
  top: var(--app-header-height);
  bottom: 0;
  width: var(--app-nav-width);
}

.nav-item {
  height: 54px;
  width: var(--app-nav-width);
}

.nav-item .icon {
  fill: var(--app-nav-link-color);
  border-radius: 100%;
  width: 38px;
  height: 38px;
  position: relative;
}

.nav-item:not(.disabled) .icon:hover,
.nav-item.active .icon, .bookmark-nav-item .active.icon, .icon:hover:deep(path) {
  fill: var(--app-nav-active-color);
  background-color: var(--app-nav-active-highlight-color);
}

.bookmark-nav-item {
  border-top: 1px solid var(--border-color)
}

.bookmark-pane-open .nav-item a::before,
.bookmark-pane-open .nav-item a::after {
  background-color: transparent;
  border-color: transparent;
}
</style>
