const mutations = {
  /**
   * @param state
   * @param list
   */
  SET_AGENCY_LIST (state, list) {
    state.list = list
  },
  /**
   * @param state
   * @param agency
   */
  SET_AGENCY (state, agency) {
    state.agency = agency
  }
}
export default mutations
