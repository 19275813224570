import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminAddProductFee (context, prodFee) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/product/fees', prodFee)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminUpdateProductFee (context, prodFee) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/product/fees/' + prodFee.id, prodFee)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteProductFee (context, prodFee) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/product/fees/' + prodFee.id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }

}

export default actions
