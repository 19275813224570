export default [
  {
    path: 'presentation',
    name: 'AdminPresentation',
    component: () => import('@/views/admin/presentation/Index.vue'),
    children: [
      {
        path: 'templates',
        alias: '',
        name: 'AdminPresentationTemplates',
        component: () => import('@/views/admin/presentation/Templates.vue')
      },
      {
        path: 'edit-template/:id',
        name: 'AdminPresentationEditTemplate',
        component: () => import('@/views/admin/presentation/EditTemplate.vue'),
        meta: {
          hideHeader: false,
          hideNav: true,
          hideHeaderRight: true,
          subNav: 'none',
          headerText: '{{TEMPLATE}}',
          headerBackRoute: 'AdminPresentationTemplates',
          contentExtraClass: 'edit-presentation'
        }
      },
      {
        path: 'preview-template/:id',
        name: 'AdminPresentationPreviewTemplate',
        component: () => import('@/views/admin/presentation/PreviewTemplate.vue'),
        meta: {
          hideHeader: false,
          hideNav: false,
          hideHeaderRight: false,
          subNav: 'none',
          headerText: '{{TEMPLATE}}',
          headerBackRoute: 'AdminPresentationTemplates'
          // contentExtraClass: 'edit-presentation'
        }
      },
      {
        path: 'slides',
        alias: '',
        name: 'AdminPresentationSlides',
        component: () => import('@/views/admin/presentation/Slides.vue')
      },
      {
        path: 'edit-slide/:templateId',
        name: 'AdminPresentationEditSlide',
        component: () => import('@/views/admin/presentation/EditSlide.vue'),
        meta: {
          hideHeader: false,
          hideNav: true,
          hideHeaderRight: true,
          subNav: 'none',
          headerText: '{{SLIDE}}',
          headerBackRoute: 'AdminPresentationSlides',
          contentExtraClass: 'edit-presentation'
        }
      },
      {
        path: 'layouts',
        alias: '',
        name: 'AdminPresentationLayouts',
        component: () => import('@/views/admin/presentation/Layouts.vue')
      },
      {
        path: 'edit-layout/:templateId',
        name: 'AdminPresentationEditLayout',
        component: () => import('@/views/admin/presentation/EditLayout.vue'),
        meta: {
          hideHeader: false,
          hideNav: true,
          hideHeaderRight: true,
          subNav: 'none',
          headerText: '{{LAYOUT}}',
          headerBackRoute: 'AdminPresentationLayouts',
          contentExtraClass: 'edit-presentation'
        }
      }
    ],
    meta: {
      headerText: 'Presentation Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'presentation-admin'
    }
  }
]
