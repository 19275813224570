<template>
  <div
    class="alert-container"
    :style="containerStyles"
  >
    <div
      class="close-button"
      @click="hideAlert"
    >
      <close-icon
        w="18"
        h="18"
      />
    </div>

    <div class="content d-flex flex-column">
      <h4 class="row">
        Introducing the Advisr Help Center
      </h4>

      <div class="d-flex flex-row row">
        <div class="copy fg">
          Step-by-Step walk-throughs, video tutorials, FAQs and more are now available! Plus, an easy way to directly contact our support team for personalized help. Just click this icon to access the Advisr Help Center.
        </div>

        <div class="image">
          <a
            href="https://support.advisr.com/hc/en-us/articles/360062446693"
            target="_blank"
          >
            <img
              src="/images/support_alert.png"
            >
          </a>
        </div>
      </div>

      <div class="d-flex flex-row row">
        <div class="fg d-flex align-items-center">
          <img
            src="/images/logos/advisr-horizontal-white.png"
            class="advisr-logo"
          >
        </div>

        <a-button
          variant="secondary"
          data-test="support-alert-hide-button"
          @click="hideAlert"
        >
          Got It
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { CloseIcon } from '@/components/icons'

const HEADER_HEIGHT = 60
const NAV_ICON_HEIGHT = 54
const ALERT_CONTAINER_HEIGHT = 300
const NAV_COLLAPSED_WIDTH = 54
const NAV_EXPANDED_WIDTH = 200
const ARROW_WIDTH = 20

export default {
  components: {
    CloseIcon
  },

  props: {
    supportIconIndex: {
      type: Number,
      required: true
    },

    isNavExpanded: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    containerStyles: {
      get () {
        const centerOfSupportIcon = HEADER_HEIGHT +
          (this.supportIconIndex * NAV_ICON_HEIGHT) +
          (NAV_ICON_HEIGHT / 2)

        const top = centerOfSupportIcon - (ALERT_CONTAINER_HEIGHT / 2)
        const left = (this.isNavExpanded ? NAV_EXPANDED_WIDTH : NAV_COLLAPSED_WIDTH) + ARROW_WIDTH

        return {
          top: top + 'px',
          left: left + 'px'
        }
      }
    }
  },

  methods: {
    hideAlert () {
      this.userHasSeenSupportAlert()
    },

    ...mapActions(['userHasSeenSupportAlert'])
  }
}
</script>

<style scoped>
.alert-container {
  position: fixed;
  height: 300px;
  width: 650px;
  background-color: rgba(12, 12, 12, 0.95);
  transition: var(--app-nav-transition-time) all;
}

.alert-container::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -10px;
  border-width: 10px 20px 10px 10px;
  border-style: solid;
  border-color: transparent rgba(12, 12, 12, 0.95) transparent transparent;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  fill: #fff;
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 5px;
  border-radius: 200px;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.content {
  padding: 30px;
  height: 100%;
  width: 100%;
  color: #fff;
}

.row {
  margin-bottom: 30px;
}

.copy {
  font-size: 14px;
  line-height: 24px;
}

.image {
  width: 200px;
  margin-left: 20px;
}

.advisr-logo {
  width: 121px;
  height: 19px;
}
</style>
