<template>
  <a-dialog
    ref="dialog"
    overlay="dark"
    type="info"
    vertical-height="tall"
    horizontal-width="large"
    :primary-button="false"
    @close-dialog="close"
  >
    <template #header>
      {{ title }}
    </template>

    <template #content>
      <div class="d-flex flex-column">
        <a-label class="align-self-end">
          Powered by ChatGPT API
        </a-label>

        <div
          v-for="(result, idx) in orderedResults"
          :key="result.order"
          class="question"
        >
          <div class="question-row d-flex flex-row">
            <div class="avatar">
              <user-avatar
                :size="32"
                :user="user"
              />
            </div>
            <div class="question-box fg d-flex flex-column">
              <div class="d-flex flex-row">
                <span class="fg">{{ result.question }}</span>

                <div
                  v-if="isLoading && idx === orderedResults.length - 1"
                  class="loading-spinner"
                >
                  <loading-spinner
                    :size="16"
                    stroke-width="2"
                  />
                </div>
              </div>

              <a-link
                v-if="!isLoading"
                class="copy-link"
                @click="setQuestion(result.question)"
              >
                Rephase Question
              </a-link>
            </div>
          </div>

          <div class="question-row d-flex flex-row">
            <span class="avatar chatbot">
              <chatbot-icon
                w="32"
                h="30"
              />
            </span>
            <div class="question-box fg d-flex flex-column">
              <div class="question-time d-flex flex-row justify-content-between">
                <span>
                  {{ result.date }}
                </span>
                <span>
                  {{ result.time }}
                </span>
              </div>

              <span class="answer">{{ result.answer }}</span>
              <a-link
                v-if="!(isLoading && idx === orderedResults.length - 1)"
                class="copy-link"
                @click="copyText(result.answer)"
              >
                Copy
              </a-link>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #tertiarybutton>
      <div class="d-flex flex-column fg">
        <div
          v-if="shouldShowQuestionInput"
          class="input-row d-flex flex-row align-items-center"
        >
          <div class="input-container d-flex flex-row fg">
            <a-input
              ref="questionInput"
              v-model="question"
              :disabled="isLoading"
              placeholder="Ask a Question"
              class="question-input fg"
              @keypress.enter="askQuestionStream"
            />

            <a-tooltip
              position="left"
              class="input-tooltip"
            >
              <template #tip>
                Looking for a different format or a shorter answer? Ask follow-up questions based on the answer received.
                <br><br>
                For example, ask ChatGPT to revise a prior answer to:
                <ul>
                  <li>Make it more concise</li>
                  <li>Modify the original question</li>
                  <li>Add bullet points</li>
                  <li>Phrase the answer in a more positive, sales-oriented tone</li>
                </ul>
              </template>
              <template #hover-item>
                <info-icon
                  w="20"
                  h="20"
                />
              </template>
            </a-tooltip>
          </div>

          <a-button
            :disabled="isLoading"
            variant="primary"
            @click="askQuestionStream"
          >
            Submit
          </a-button>
        </div>

        <div class="d-flex flex-row justify-content-center">
          <a-button
            variant="default"
            @click="close"
          >
            Close
          </a-button>
        </div>

        <div class="disclaimer">
          <strong>Disclaimer:</strong> Answers provided by ChatGPT are based on its understanding of the topic as a language model trained on a wide range of data, and should be verified and cross-checked with reliable sources for accuracy and validity.
        </div>
      </div>
    </template>
  </a-dialog>
</template>

<script>
import { orderBy } from 'lodash-es'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

import { api } from '@/config'
import { ChatbotIcon, InfoIcon } from '@/components/icons'

const { apiRoot } = api
const chatbotApiUrl = `${apiRoot}/chatbot/ask-question`
const chatbotStreamApiUrl = `${apiRoot}/chatbot/ask-question-stream`

const textDecoder = new TextDecoder('utf-8')

export default {
  components: {
    ChatbotIcon,
    InfoIcon
  },

  props: {
    initialQuestion: {
      type: String,
      default () {
        return null
      }
    },

    title: {
      type: String,
      default () {
        return 'AdviseMe'
      }
    },

    autoHideInput: {
      type: Boolean,
      default () {
        return false
      }
    }
  },

  data () {
    return {
      question: '',
      results: [],
      isLoading: false,
      shouldShowQuestionInput: true,
      session: null
    }
  },

  computed: {
    orderedResults: {
      get () {
        return orderBy(this.results, 'order', 'asc')
      }
    },

    user: {
      get () {
        return this.$store.state.User.user
      }
    }
  },

  mounted () {
    this.session = this.generateUUID()

    if (this.initialQuestion) {
      if (this.autoHideInput) this.shouldShowQuestionInput = false
      this.question = this.initialQuestion
      this.askQuestionStream()
    }
  },

  methods: {
    async askQuestion () {
      this.isLoading = true
      if (this.autoHideInput) this.shouldShowQuestionInput = false

      try {
        const question = this.question
        this.question = ''

        const response = await this.$http.post(
          chatbotApiUrl,
          {
            question,
            session: this.session
          }
        )

        this.results.push({
          question,
          answer: response.body.answer,
          order: this.results.length + 1,
          date: dayjs().format('M/D/YYYY'),
          time: dayjs().format('h:mm a')
        })
      } catch (error) {
        this.$messaging.addErrorMessage('An error occurred while asking the question')
      }

      this.isLoading = false
    },

    async askQuestionStream () {
      this.isLoading = true
      if (this.autoHideInput) this.shouldShowQuestionInput = false

      try {
        const result = {
          question: this.question,
          answer: '',
          order: this.results.length + 1,
          date: dayjs().format('M/D/YYYY'),
          time: dayjs().format('h:mm a')
        }
        this.results.push(result)
        this.question = ''

        this.$nextTick(() => {
          this.scrollToBottom()
        })

        const response = await fetch(
          `${chatbotStreamApiUrl}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
              question: result.question,
              session: this.session
            })
          }
        )

        const reader = response.body.getReader()
        try {
          while (true) {
            const { done, value } = await reader.read()
            if (done) break

            const textChunk = textDecoder.decode(value)
            result.answer += textChunk

            this.scrollToBottom()
          }
        } finally {
          reader.releaseLock()

          // scroll to bottom after copy link is added
          setTimeout(() => {
            this.scrollToBottom()
            this.focusInput()
          }, 100)
        }
      } catch (error) {
        this.$messaging.addErrorMessage('An error occurred while asking the question')
      }

      this.isLoading = false
    },

    focusInput () {
      if (this.$refs?.questionInput?.inputElement) this.$refs.questionInput.inputElement.focus()
    },

    setQuestion (question) {
      this.question = question
      this.shouldShowQuestionInput = true
      this.focusInput()
    },

    hideQuestion () {
      this.question = ''
      this.shouldShowQuestionInput = false
    },

    close () {
      this.$emit('close')
    },

    copyText (text) {
      navigator.clipboard.writeText(text)
      this.$messaging.addInfoMessage('Answer copied to clipboard')
    },

    scrollToBottom () {
      // Scroll to bottom of dialog
      if (this.$refs.dialog && this.$refs.dialog.dialogContent) {
        this.$refs.dialog.dialogContent.scrollTop = this.$refs.dialog.dialogContent.scrollHeight
      }
    },

    generateUUID () {
      const uuid = uuidv4()
      return uuid
    }
  }
}
</script>

<style scoped>
.question-row {
  margin-top: 10px;
}

.avatar {
  width: 32px;
  margin-right: 10px;
  padding-top: 6px;
}

.avatar.chatbot {
  padding-top: 10px;
}

.question-box {
  border: 1px solid #DDD;
  background-color: #F8FAFD;
  padding: 16px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 1.3rem;
  color: #333;
}

.question-time {
  font-size: 11px;
  color: #4F6C88;
  margin-bottom: 10px;
}

.answer {
  white-space: pre-wrap;
}

.question-input {
  margin-bottom: 0px !important;
  margin-right: 10px;
}

.question-input :deep(input) {
  padding: 7px 35px 7px 10px;
}

.copy-link {
  margin-top: 10px;
}

.disclaimer {
  margin: 10px 120px;
  text-align: center;
  font-size: 11px;
  line-height: 1.3rem;
}

.loading-spinner {
  width: 16px;
  height: 16px;
  margin-top: 2px;
}

.edit-link {
  margin-top: 2px;
}

.input-row {
  margin-bottom: 10px;
}

.input-row :deep(button) {
  padding: 12px 24px;
}

.input-row :deep(input) {
  height: 37px;
}

.input-row :deep(input:disabled) {
  background-color: #F8FAFD;
}

.input-container {
  position: relative;
}

.input-tooltip {
  position: absolute;
  top: 9px;
  right: 20px;
  fill: var(--link-color);
  cursor: pointer;
}

.input-tooltip :deep(.tip) {
  max-width: 300px;
}

.input-tooltip :deep(ul) {
  text-align: left;
}
</style>
