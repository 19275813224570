const mutations = {

  /**
   * @param state
   * @param user
   * @constructor
   */
  SET_USER (state, user) {
    state.user = user
  },

  /**
   * @param state
   * @param isLoaded
   */
  SET_USER_IS_LOADED (state, isLoaded) {
    state.isLoaded = isLoaded
  },

  /**
   * @param state
   * @param email
   */
  SET_USER_EMAIL (state, email) {
    state.user.email = email
  },

  /**
   * @param state
   * @param password
   */
  SET_USER_PASSWORD (state, password) {
    state.user.password = password
  },

  /**
   * @param state
   */
  RESET_USER (state) {
    state.user.email = ''
    state.user.password = ''
  },

  /**
   * @param state
   */
  SET_USER_AVATAR (state, avatar) {
    state.user.avatar = avatar
  },

  /**
   * @param state
   */
  DISABLE_USER_INITIAL_SETUP (state) {
    state.user.showInitialSetup = false
  },

  /**
   * @param state
   */
  ACCEPT_TERMS_OF_SERVICE (state) {
    state.user.acceptedTermsOfService = true
  },

  /**
   * @param state
   */
  USER_HAS_SEEN_SUPPORT_ALERT (state) {
    state.user.hasSeenSupportAlert = true
  },
  /**
 * @param state
 */
  USER_HAS_COMPLETED_WELCOME_TASK (state) {
    state.user.hasCompletedWelcomeTask = true
  }
}

export default mutations
