import Vue from 'vue'

function create ({ contactableId: clientId, contactId, companyId, companyGroupId } = {}) {
  const params = { companyId, companyGroupId }
  return Vue.api.post(`/clients/${clientId}/contacts/${contactId}`, { params })
}

function list ({ contactableId: clientId, companyId, companyGroupId } = {}) {
  const params = { companyId, companyGroupId }
  return Vue.api.get(`/clients/${clientId}/contacts`, { params })
}

function _delete ({ contactableId: clientId, contactId, companyId, companyGroupId } = {}) {
  const params = { companyId, companyGroupId }
  return Vue.api.delete(`/clients/${clientId}/contacts/${contactId}`, { params })
}

function order ({ contactableId: clientId, contactId, order, companyId, companyGroupId }) {
  const data = { order }
  const params = { companyId, companyGroupId }
  return Vue.api.patch(`/clients/${clientId}/contacts/${contactId}/order`, { data, params })
}

export default {
  create,
  list,
  delete: _delete,
  order
}
