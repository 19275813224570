<template>
  <span
    :class="classes"
    v-on="$listeners"
  >
    <svg
      class="circle-icon"
      viewBox="0 0 8 8"
    >
      <circle
        cx="4"
        cy="4"
        r="3"
      />
    </svg>
    <slot />
  </span>
</template>

<script>

const VARIANTS = new Set(['default', 'success', 'error', 'warning', 'info'])

export default {
  props: {
    variant: {
      type: String,
      default: 'success',
      validator: variant => VARIANTS.has(variant)
    }
  },

  computed: {
    classes: {
      get () {
        return VARIANTS.has(this.variant) && this.variant !== 'default'
          ? ['badge', `badge-${this.variant}`]
          : ['badge']
      }
    }
  }
}
</script>

<style scoped>
.circle-icon {
  height: 0.75rem;
  width: 0.75rem;
  margin-left: -0.125rem;
  margin-right: 0.375rem;
}

.badge {
  display: inline-flex;
  border: 1px solid var(--border-color);
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 0.375rem;
  padding: 0.3rem 0.625rem;
  color: var(--badge-text-color);
  background-color: var(--status-badge-default-background-color);
  fill: var(--status-badge-default-circle-color);
}

.badge-success {
  background-color: var(--status-badge-success-background-color);
  fill: var(--status-badge-success-circle-color);
}

.badge-error {
  background-color: var(--status-badge-error-background-color);
  fill: var(--status-badge-error-circle-color);
}

.badge-warning {
  background-color: var(--status-badge-warning-background-color);
  fill: var(--status-badge-warning-circle-color);
}

.badge-info {
  background-color: var(--status-badge-info-background-color);
  border-color: var(--status-badge-info-circle-color);
  color: var(--status-badge-info-circle-color);
  fill: var(--status-badge-info-circle-color);
}
</style>
