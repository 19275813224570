<template>
  <div
    ref="dropdown"
    class="select-container"
    :class="{disabled}"
  >
    <div
      class="false-select default no-border select carat overflow-text"
      :class="{disabled, 'focused': focusAssignSelect}"
      @click="toggleOptions"
    >
      <input
        id="input"
        v-model="shouldCreateTaskLocal"
        type="checkbox"
        class="border text-blue-500 border-gray-300 rounded checkbox"
      >
      {{ placeholderText }}
    </div>

    <div
      v-if="showOptions"
      class="dropdown-container"
    >
      <a-input
        ref="searchStringInput"
        v-model="searchString"
        class="search-box"
        placeholder="Search..."
      />
      <div class="assignee-tabs d-flex flex-row">
        <button
          :class="{ active: activeTab === 'all' }"
          @click="activeTab = 'all'"
        >
          <div class="tab-select">
            All ({{ options.length }})
          </div>
        </button>
        <button
          :disabled="selectedOptions.length===0"
          :class="{ active: activeTab === 'selected' }"
          @click="activeTab = 'selected'"
        >
          <div class="tab-select">
            Selected ({{ selectedOptions.length }})
          </div>
        </button>
      </div>
      <div class="scroll-wrapper">
        <div v-if="activeTab === 'all'">
          <ul>
            <div
              class="title"
            >
              Showing {{ filteredOptions.length }} options
            </div>
            <div
              v-for="(option,index) in filteredOptions"
              :key="option.uId"
              class="label-container d-flex flex-row align-items-center"
              :class="{ 'selected': selectedIndex === index}"
            >
              <div class="checkbox-margin">
                <checkbox
                  v-model="option.isSelected"
                />
              </div>

              <div
                class="assignee-row"
              >
                <div class="left-column">
                  <div
                    class="avatar"
                  >
                    <circle-image
                      class="avatar-image"
                      :image-url="getUserAvatarURL(option)"
                      :size="30"
                    />
                    <div
                      class="avatar-initial"
                    >
                      {{ option.firstName[0] + option.lastName[0] }}
                    </div>
                  </div>
                </div>

                <div class="center-column">
                  <div v-if="option.isCurrentUser">
                    Assign to self
                  </div>
                  <div
                    v-else
                    class="name"
                  >
                    {{ option.firstName }}
                    {{ option.lastName }}
                  </div>
                  <div
                    class="email-text"
                  >
                    {{ option.email }}
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>

        <div v-else-if="activeTab === 'selected'">
          <div
            v-if="selectedOptions.length>0"
            class="title"
          >
            {{ selectedOptions.length }} selected options
          </div>
          <div
            v-for="(option,index) in selectedOptions"
            :key="option.uId"
            class="label-container d-flex flex-row align-items-center"
            :class="{ 'selected': selectedIndex===index }"
          >
            <div class="checkbox-margin">
              <checkbox
                v-model="option.isSelected"
              />
            </div>

            <div
              class="assignee-row"
            >
              <div class="left-column">
                <div
                  class="avatar"
                >
                  <circle-image
                    class="avatar-image"
                    :image-url="getUserAvatarURL(option)"
                    :size="30"
                  />
                  <div
                    class="avatar-initial"
                  >
                    {{ option.firstName[0] + option.lastName[0] }}
                  </div>
                </div>
              </div>

              <div class="center-column">
                <div v-if="option.isCurrentUser">
                  Assign to self
                </div>
                <div
                  v-else
                  class="name"
                >
                  {{ option.firstName }}
                  {{ option.lastName }}
                </div>
                <div
                  class="email-text"
                >
                  {{ option.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="footer-slot"
      >
        <div class="wrapper-close" />
        <a-button
          variant="primary"
          size="small"
          class="save-button"
          @click="clickAssign"
        >
          Assign
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { assets } from '@/config'
import { cloneDeep, findIndex } from 'lodash-es'
const { assetsRoot, userAvatarAssetPath } = assets
export default {
  components: {
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focusAssignSelect: {
      type: Boolean,
      default: false
    },
    initialAssignmentState: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTab: 'all',
      searchString: '',
      showOptions: false,
      selectedIndex: -1,
      localOptions: [],
      shouldCreateTaskLocal: false
    }
  },
  computed: {
    anySelectedOptions () {
      return this.selectedOptions.length > 0
    },
    selectedOptions () {
      return this.localOptions.filter(option => option.isSelected === true)
    },
    placeholderText () {
      if (this.selectedOptions.length === 1) {
        if (this.selectedOptions[0].isCurrentUser) {
          return 'Assign to you'
        }
        return '1 Contact'
      }
      if (this.selectedOptions.length > 1) {
        return this.selectedOptions.length + ' Contacts'
      }

      return 'Select Assignees'
    },
    filteredOptions () {
      if (this.searchString != null && this.searchString !== '') {
        return this.localOptions.filter(user => {
          const fullName = user.firstName.toLowerCase() + user.lastName.toLowerCase()
          return fullName.includes(this.searchString.toLowerCase())
        })
      } else {
        return this.localOptions
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.localOptions = cloneDeep(this.options)
      },
      deep: true
    },
    shouldCreateTaskLocal (newValue) {
      this.$emit('update-task-create', newValue)
    }
  },

  created () {
    this.reset()
  },

  mounted () {
    window.addEventListener('keyup', this.onKeyPress)
    window.addEventListener('keydown', this.onKeyDown)

    this.localOptions = cloneDeep(this.options)
  },
  destroyed () {
    window.removeEventListener('keyup', this.onKeyPress)
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('click', this.handleClickOutside)
  },

  methods: {
    reset () {
      this.shouldCreateTaskLocal = this.initialAssignmentState
      this.$emit('update-task-create', this.shouldCreateTaskLocal)
    },
    updateLocalOption (user, updates) {
      const contactIndex = findIndex(this.localOptions, { uId: user.uId })

      if (contactIndex !== -1) {
        Object.entries(updates).forEach(([key, value]) => {
          this.localOptions[contactIndex][key] = value
        })
      }
    },
    toggleOptions () {
      this.showOptions = !this.showOptions
      if (this.showOptions) {
        this.searchString = null
        this.activeTab = 'all'
        document.addEventListener('click', this.handleClickOutside)
      }
    },
    handleClickOutside (event) {
      const dropdownElement = this.$refs.dropdown
      if (event.target.classList.contains('checked') || event.target.classList.contains('name')) {
        window.removeEventListener('click', this.handleClickOutside)
      } else {
        if (dropdownElement && !dropdownElement.contains(event.target)) {
          this.showOptions = false
        }
      }
    },
    onKeyPress (event) {
      const options = this.filteredOptions
      if ((event.key === 'Enter' || event.key === 'Tab') && this.showOptions) {
        event.preventDefault()
        if (this.selectedIndex !== -1) {
          this.handleSelectIndex(options[this.selectedIndex].uId)
        } else {
          this.handleSelectIndex(options[0].uId)
        }
      }
      if (this.showOptions && event.key === 'ArrowUp') {
        event.preventDefault()
        this.selectPreviousRow()
      }
      if (this.showOptions && event.key === 'ArrowDown') {
        event.preventDefault()
        this.selectNextRow()
      }
    },
    onKeyDown (event) {
      if (this.showOptions && event.key === 'Tab') {
        event.preventDefault()
      }
    },
    getUserAvatarURL (option) {
      if (option.avatar) return assetsRoot + userAvatarAssetPath + option.avatar
      return ''
    },
    selectPreviousRow () {
      if (this.selectedIndex > 0) {
        this.selectedIndex--
        this.scrollToSelectedRow(false)
      }
    },
    selectNextRow () {
      if (this.selectedIndex < this.filteredOptions.length - 1) {
        this.selectedIndex++
        this.scrollToSelectedRow(true)
      }
    },
    scrollToSelectedRow (alignToTop) {
      const selectedRow = this.$el.querySelector('.selected')
      if (selectedRow) {
        selectedRow.scrollIntoView(alignToTop)
      }
    },
    handleSelectIndex (optionId) {
      const idx = this.localOptions.findIndex(x => x.uId === optionId)
      const filteredOption = this.localOptions[idx]
      this.toggleSelectedIndex(idx, filteredOption, !filteredOption.isSelected)
    },
    toggleSelectedIndex (index, option, isSelected) {
      option.isSelected = isSelected
      this.$set(this.filteredOptions, index, option)
    },
    clickAssign () {
      this.shouldCreateTaskLocal = true
      this.showOptions = false
      const selected = this.selectedOptions.map(user => ({
        userId: user.uId
      }))
      this.$emit('assign', selected)
    }
  }
}
</script>

  <style scoped>
  .tab-select{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .assignee-tabs {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgb(208, 208, 208);
  }

  .assignee-tabs button {
    flex:1;
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    border-bottom: 1px solid transparent;
    background-color: transparent;
  }

  .assignee-tabs button.active {
    border-color:var(--text-accent-color);
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 5px 0;
  }

  label {
    display: flex;
    align-items: center;
  }

  input[type=checkbox][disabled]{
    background-color: rgba(67,160,71,0.7) !important;
  }
  input[type="checkbox"][disabled="disabled"] {
    background-color: rgba(67,160,71,0.7) !important;
    accent-color: rgba(67,160,71,0.7) !important;
  }

.dropdown-container{
  position: absolute;
  z-index: 5;
  background: white;
  border-width: 1px;
  border-radius: var(--border-radius);
  border-style: solid;
  border-color: rgb(223, 223, 223);
  font-size: 13px;
  width: 22em;
  max-height:400px;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 7px;
}
@media (max-height: 860px) {
  .dropdown-container {
    max-height: 350px;
}
}
@media (max-height: 760px) {
  .dropdown-container {
    max-height: 330px;
}
}
@media (max-height: 720px) {
  .dropdown-container {
    max-height: 300px;
}
}
@media (max-height: 650px) {
  .dropdown-container {
    max-height: 270px;
}
}
@media (max-height: 600px) {
  .dropdown-container {
    max-height: 250px;
}
}

.overflow-text{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select-container {
  margin-bottom: 20px;
  width: 22em;
}
.select {
  font-family: var(--font-stack);
  border-color: var(--input-border-color);
  border-style: solid;
  appearance: none;
  cursor: pointer;
  color: var(--text-color);
}

.disabled-select {
    opacity: 0.4;
}

.select:not(.multiple) {
  padding-right: 30px;
  background-color: white;
}

.carat:not(.multiple) {
  background-image: url("/images/carat_down.png");
  background-position: center right;
  background-repeat: no-repeat;
  height: 36px;
}

.select:focus {
  border-color: var(--input-focus-border-color);
}

.select-container > .false-select {
  border-width: 1px;
  border-radius: var(--border-radius);
  font-size: 13px;
  padding: 7px 30px 7px 6px;
  line-height: normal;
}

.large .select {
  border-width: 0px 0px 1px 0px;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.card-title .select {
  border-color: transparent;
  background-color: var(--card-background-color);
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}

.no-border .select {
  border-color: transparent;
  background-color: var(--card-background-color);
  padding-left: 0;
  padding-top: 0;
  padding-right: 22px;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 0;
}

.invalid .select,
select[aria-invalid='true'] {
  border-color: var(--text-error-color);
}

.select-container .invalid-input {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 0px;
}

.help-text {
  width: 100%;
  font-size: 12px;
  font-style: italic;
  color: var(--text-help-color);
  margin-top: 6px;
}

.select.warning {
  border: solid var(--text-error-color) 1px;
}
.center-column {
  flex-grow: 2;
  width: 14em;
}
.name {
  font-weight: 500;
  align-self: flex-start;
  line-height: 1.3em;
}
.assignee-row {
  display: flex;
  height: 45px;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
}
.label-container{
  overflow-x: hidden ;
}
.label-container:hover {
  background-color: #f1f1f1;
}
.left-column {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 45px;
  padding-left: 0.3rem;
}
.avatar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(217, 217, 217, 1);
  background-size: cover;
  background-position: 50% 50%;
}

.avatar-initial {
  font-size: 13px;
  line-height: 23px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  z-index: 1;
  text-transform: uppercase;
}
.avatar-image {
  position: absolute;
  top: 0px;
  z-index: 2;
}
.title {
  margin: 10px 10px 5px 10px;
  font-size: 11px;
  font-weight: 400;
  color: rgba(79, 108, 136, 1);
}
.scroll-wrapper {
  flex-grow: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.footer-slot {
  padding: 7px 0;
}
.wrapper-close{
  border-bottom: 1px solid #ccc;
  margin-left: -7px;
  margin-right: -11px;
  margin-bottom:5px;
}
.save-button{
  height: 33px;
  float: right;
}
.text-blue-500 {
  color: var(--link-color);
}
.checkbox {
  accent-color: var(--checkbox-checked-background-color);
}

.checkbox:disabled {
  accent-color: rgba(67,160,71,0.7) !important;
}
.selected {
  background-color: #f1f1f1;
}
.email-text {
  line-height: 1.3em;
  font-size: 10px;
  color: #666;
  overflow: hidden;
  max-width: 90%;
}
.checkbox-margin{
  margin-top: 8px;
}

.disabled{
  pointer-events: none !important;
  cursor: default;
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3)) !important;
  color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
  border-color: rgba(118, 118, 118, 0.3);
}

.focused{
  border-radius: 1px;
  border-color: var(--link-color);
  transition: border-radius 1s, border-color 1s;
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    border-color: var(--link-color);
  }
  50% {
    border-color: var(--border-color);
  }
  100% {
    border-color: var(--link-color);
  }
}
  </style>
