import { merge } from 'lodash-es'

import apiConfig from './api'
import appConfig from './app'
import assetsConfig from './assets'
import brandingConfig from './branding'
import buildConfig from './build'
import googleConfig from './google'
import hubspotConfig from './hubspot'
import lookerConfig from './looker'
import mapboxConfig from './mapbox'
import presentationConfig from './presentation'
import rollbarConfig from './rollbar'
import socketsConfig from './sockets'
import trackingConfig from './tracking'
import walkmeConfig from './walkme'

import local from './local'

import production from './environment/production'
import demo from './environment/demo'
import beta from './environment/beta'
import test from './environment/test'
import development from './environment/development'
import qa from './environment/qa'

const environment = {
  production,
  demo,
  beta,
  test,
  development,
  qa
}[import.meta.env.VITE_APP_ENV]

const config = {
  api: apiConfig,
  app: appConfig,
  assets: assetsConfig,
  branding: brandingConfig,
  build: buildConfig,
  google: googleConfig,
  hubspot: hubspotConfig,
  looker: lookerConfig,
  mapbox: mapboxConfig,
  presentation: presentationConfig,
  rollbar: rollbarConfig,
  sockets: socketsConfig,
  tracking: trackingConfig,
  walkme: walkmeConfig
}

merge(config, environment)
merge(config, local)

export const {
  api,
  app,
  assets,
  branding,
  build,
  google,
  hubspot,
  looker,
  mapbox,
  presentation,
  rollbar,
  sockets,
  tracking,
  walkme
} = config

export default config
