const mutations = {
  /**
   * @param state
   * @param list
   */

  SET_COMPANY_DASHBOARD_SETTINGS (state, setting) {
    state.companyDashboardSettings = setting
  }
}
export default mutations
