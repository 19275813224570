export default [
  {
    path: 'campaigns',
    name: 'AdminCampaigns',
    component: () => import('@/views/admin/campaigns/Index.vue'),
    children: [
      {
        path: 'campaigns/campaign-types',
        alias: '',
        name: 'CampaignType',
        component: () => import('@/views/admin/campaigns/CampaignTypes.vue')
      },
      {
        path: 'campaigns/ui-config',
        alias: '',
        name: 'CampaignUIConfiguration',
        component: () => import('@/views/admin/campaigns/UiConfig.vue')
      },
      {
        path: 'campaigns/deal-config',
        alias: '',
        name: 'CampaignDealConfiguration',
        component: () => import('@/views/admin/campaigns/DealConfiguration.vue')
      }
    ],
    meta: {
      headerText: 'Campaign Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'campaigns-admin'
    }
  }
]
