import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  getUserNotificationsList () {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/user-notification/list')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  getUnreadUserNotificationsCount () {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/user-notification/unread-count')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  clearUnreadUserNotifications () {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/user-notification/clear-unread')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }
}
export default actions
