import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminGetAllIndustryCategories (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/industry/list-categories/' + companyId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGetAllIndustries (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/industry/list/' + companyId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminLoadUnmappedExternalCats (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/industry/unmapped-external-categories/' + companyId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadMappedExternalCats (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/industry/mapped-external-categories/' + companyId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteIndustryCategoryById (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/industry/delete-category/' + params.companyId + '/' + params.industryCategoryId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateNewIndustryCategory (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/industry/new-category/' + data.companyId, data.newCat)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  adminGetIndustryCategory (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/industry/category/' + params.companyId + '/' + params.categoryId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminEditIndustryCategory (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/industry/edit-category/' + data.companyId + '/' + data.categoryId, data.editData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminEditExternalIndustryCategoryMapping (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(apiRoot + '/admin/industry/external-category-mapping/' + data.companyId + '/' + data.externalCategoryId, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  // industry actions
  adminCreateNewIndustry (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/industry/new/' + data.companyId, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminEditIndustry (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/industry/edit/' + data.companyId + '/' + data.industryId, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteIndustryById (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/industry/delete/' + params.companyId + '/' + params.industryId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminClientIndustryListWithCategories (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/industry/list-client-industry', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
