<template>
  <div
    :class="buttonClasses"
    :style="buttonStyles"
    :data-test="datatest"
    @click="disabled ? null : $emit('click')"
  >
    <icon-link
      :w="iconSize"
      :h="iconSize"
      :icon="type"
      :title="type"
      @click="disabled ? null : $emit('click')"
    />
  </div>
</template>
<script>
import { includes } from 'lodash-es'

const VARIANTS = ['default', 'primary', 'secondary', 'danger']
const ACTIONS = ['Add', 'Edit', 'Remove', 'Info', 'Close', 'Delete']
export default {
  name: 'ActionButton',

  props: {
    datatest: {
      type: String,
      default: ''
    },

    variant: {
      type: String,
      default: 'default',
      validator: type => includes(VARIANTS, type)
    },
    type: {
      type: String,
      required: true,
      validator: type => includes(ACTIONS, type)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonSize: {
      type: String,
      default: '32'
    },
    iconSize: {
      type: String,
      default: '24'
    }
  },
  computed: {
    buttonClasses () {
      const classes = ['action-button']
      if (this.disabled) classes.push('disabled')
      if (this.variant) classes.push('action-button-' + this.variant)
      else classes.push('action-button-default')
      return classes
    },

    buttonStyles () {
      const styles = {
        height: this.buttonSize + 'px',
        width: this.buttonSize + 'px',
        padding: ((this.buttonSize - this.iconSize) / 2) + 'px'
      }
      return styles
    }
  }
}
</script>

<style scoped>
.action-button {
  border-radius: 50%;
  cursor: pointer;
}

.action-button-default {
  background-color: var(--add-button-default-background-color);
}

.action-button-primary {
  background-color: var(--add-button-primary-background-color);
}

.action-button-secondary {
  background-color: var(--add-button-secondary-background-color);
}

.action-button-danger {
  background-color: var(--add-button-danger-background-color);
}

.action-button-default :deep(svg) {
  fill: var(--add-button-default-fill-svg-color);
}

.action-button-secondary :deep(svg),
.action-button-primary :deep(svg),
.action-button-danger :deep(svg) {
  fill: var(--add-button-fill-svg-color);
}

.action-button-default.disabled,
.action-button-default.disabled :deep(a .icon),
.action-button-default.disabled :deep(svg) {
  cursor: not-allowed;
}

.action-button-default.disabled:hover {
  background-color: var(--add-button-default-background-color);
}

.action-button-default:hover {
  background-color: var(--add-button-default-hover-background-color);
}

.action-button-primary:hover {
  background-color: var(--add-button-primary-hover-background-color);
}

.action-button-secondary:hover {
  background-color: var(--add-button-secondary-hover-background-color);
}

.action-button-danger:hover {
  background-color: var(--add-button-danger-hover-background-color);
}

.action-button-default:hover :deep(svg) {
  fill: var(--add-button-default-fill-svg-color);
}

.action-button-primary:hover :deep(svg),
.action-button-secondary:hover :deep(svg),
.action-button-danger:hover :deep(svg) {
  fill: var(--add-button-fill-svg-color);
}
</style>
