export default [
  {
    path: 'tags',
    name: 'AdminTags',
    component: () => import('@/views/admin/tags/Edit.vue'),
    meta: {
      headerText: 'Tag Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'none'
    }
  }
]
