import { mapMutations } from 'vuex'

export default {
  computed: {
    shouldShowEmailSupport: {
      get () {
        return this.$store.state.Support.shouldShowEmailSupport
      }
    },
    userIsLoaded: {
      get () {
        return this.$store.state.User.isLoaded
      }
    },
    user: {
      get () {
        return this.$store.state.User.user
      }
    },
    shouldShowSupportIcon: {
      get () {
        if (
          !this.userIsLoaded ||
            !this.user ||
            !this.user.companyGroup ||
            !this.user.companyGroup.company
        ) return false

        return this.user.companyGroup.company.supportSystem !== 'none'
      }
    },
    shouldShowSupportAlert: {
      get () {
        if (
          !this.userIsLoaded ||
          !this.user ||
          !this.user.companyGroup ||
          !this.shouldShowSupportIcon
        ) return false
        if (this.user.hasSeenSupportAlert) return false
        if (
          this.user.companyGroup.supportSystem === 'zendesk' &&
          this.user.companyGroup.enableSupportAlert
        ) return true
        else if (
          this.user.companyGroup.supportSystem === 'company' &&
          this.user.companyGroup.company.supportSystem === 'zendesk' &&
          this.user.companyGroup.company.enableSupportAlert
        ) return true
        return false
      }
    }
  },

  methods: {
    showSupport () {
      if (!this.shouldShowEmailSupport) {
        this.setShouldShowEmailSupport(true)
      }
    },
    closeSupport () {
      if (this.shouldShowEmailSupport) {
        this.setShouldShowEmailSupport(false)
      }
    },
    openSupportMethod () {
      if (
        this.user.companyGroup.supportSystem === 'email' ||
          (
            this.user.companyGroup.supportSystem === 'company' &&
            this.user.companyGroup.company.supportSystem === 'email'
          )
      ) {
        this.showSupport()
      } else if (window.WalkMePlayerAPI) {
        if (window.WalkMePlayerAPI.isMenuOpen() === false) {
          window.WalkMePlayerAPI.toggleMenu()
        }
      } else {
        if (this.user.isAdvisrEmployee) {
          window.open(
            'https://support.advisr.com/hc/en-us',
            '_blank'
          )
        } else {
          const route = this.$router.resolve({
            name: 'ZendeskSSO',
            query: {
              return_to: 'https://support.advisr.com/hc/en-us'
            }
          })
          window.open(
            route.href,
            '_blank'
          )
        }
      }
    },

    ...mapMutations({
      setShouldShowEmailSupport: 'SET_SHOULD_SHOW_EMAIL_SUPPORT'
    })
  }
}
