import Vue from 'vue'

function create (contactId, data = {}) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api.post(`/contacts/${contactId}/contact-methods`, { params, data })
}

function update (contactId, id, data = {}) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api.patch(`/contacts/${contactId}/contact-methods/${id}`, { params, data })
}

function order (contactId, contactMethodId, data = {}) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api.patch(`/contacts/${contactId}/contact-methods/${contactMethodId}/order`, { params, data })
}

function _delete (contactId, id) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api.delete(`/contacts/${contactId}/contact-methods/${id}`, { params })
}

export default {
  create,
  update,
  order,
  delete: _delete
}
