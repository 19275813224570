import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  listExternalCRM (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/external-crm/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  loadExternalCRM (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/external-crm/load/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  loadExternalCRMAccount (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/external-crm/load-account/' + params.id, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  createExternalCRMOpportunity (context, bodyParams) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/external-crm/create-opportunity', bodyParams)
        .then(response => {
          context.commit('SET_EXTERNAL_OPPORTUNITY', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  createExternalCRMAccount (context, bodyParams) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/external-crm/create-account', bodyParams)
        .then(response => {
          context.commit('SET_EXTERNAL_ACCOUNT', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  loadExternalCRMOpportunity (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/external-crm/load-opportunity/' + params.id, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  loadExternalCRMOpportunityCampaigns (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/external-crm/load-opportunity-campaigns/' + params.id, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  getExternalCRMs (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/external-crm/get-crms', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
