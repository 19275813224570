export default [
  {
    path: 'exports',
    name: 'AdminExports',
    component: () => import('@/views/admin/export/Index.vue'),
    children: [
      {
        path: 'insertion-order',
        alias: '',
        name: 'AdminInsertionOrder',
        component: () => import('@/views/admin/export/InsertionOrder.vue')
      },
      {
        path: 'campaign',
        alias: '',
        name: 'AdminCampaignExport',
        component: () => import('@/views/admin/export/CampaignExport.vue')
      },
      {
        path: 'vendor',
        alias: '',
        name: 'AdminVendorExport',
        component: () => import('@/views/admin/export/VendorExport.vue')
      },
      {
        path: 'styling',
        alias: '',
        name: 'AdminExportStyling',
        component: () => import('@/views/admin/export/ExportStyling.vue')
      }
    ],
    meta: {
      headerText: 'Exports Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'exports-admin'
    }
  }
]
