<template>
  <div>
    <transition name="slide-from-left">
      <div
        v-if="shouldShow"
        class="menu d-flex flex-column"
      >
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click="close"
        >
          <span aria-hidden="true">
            &times;
          </span>
        </button>

        <h5 class="content-center">
          Contact Support
        </h5>

        <div class="sub-header text-center">
          Have a request, issue or a comment?
          Please provide as much information below as possible,
          including screenshots if applicable. Thank you for your feedback.
        </div>
        <a-input
          v-model="emailSubject"
          label="Subject:"
          :required="true"
          :invalid="invalidSubject"
          invalid-text="Please enter a subject"
        />

        <rich-text-input
          v-model="emailMessage"
          label="Message:"
          class="email-body-text"
          :invalid="invalidMessage"
          invalid-text="Please enter a message"
          :allow-links="true"
        />

        <a-label class="attachment">
          Attachment:
        </a-label>

        <file-upload
          v-model="selectedFiles"
          :multiple="true"
          :show-filename="false"
          :allowed-types="['image', 'document']"
          text="Upload..."
          class="text-center"
          variant="primary"
        />

        <div
          class="d-flex flex-column"
        >
          <attachment
            v-for="(file, index) in allSelectedFiles"
            :key="index"
            :file-name="file.name"
            @remove="removeFile"
          >
            {{ file.name }}
          </attachment>
        </div>

        <div class="grid-row cancel-send-buttons">
          <div class="col-12 content-center">
            <a-button
              variant="default"
              @click="cancel"
            >
              Cancel
            </a-button>
            <a-button
              variant="secondary"
              @click="send"
            >
              Send
            </a-button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="shouldShow"
        class="hidden-overlay"
        @click="close"
      />
    </transition>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import Attachment from '@/components/support/Attachment'
import { isEmpty } from 'lodash-es'

export default {
  components: {
    Attachment
  },

  props: {
    shouldShow: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      emailMessage: null,
      placeHolders: null,
      selectedFiles: [],
      allSelectedFiles: [],
      subject: null,
      invalidSubject: false,
      invalidMessage: false
    }
  },

  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    },

    emailSubject: {
      get () {
        if (this.subject !== null) {
          return this.subject
        }

        let subject = `Advisr : Support Inquiry - ${this.user.firstName} ${this.user.lastName}`

        if (this.user.companyGroup) {
          subject += `, ${this.user.companyGroup.company.name}` +
              `, ${this.user.companyGroup.name}`
        }

        return subject
      },

      set (newVal) {
        this.subject = newVal
      }
    }
  },

  watch: {
    selectedFiles (newFiles, oldFiles) {
      if (newFiles && newFiles.length > 0) {
        const addFiles = newFiles.filter(newFile => {
          const index = this.allSelectedFiles.findIndex(file => {
            return file.name === newFile.name
          })

          return index < 0
        })

        if (addFiles && addFiles.length > 0) {
          this.allSelectedFiles.push(...addFiles)
        }
      }
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    validateForm () {
      if (isEmpty(this.emailSubject)) {
        this.invalidSubject = true
      } else {
        this.invalidSubject = false
      }

      if (isEmpty(this.emailMessage)) {
        this.invalidMessage = true
      } else {
        this.invalidMessage = false
      }

      if (this.invalidSubject || this.invalidMessage) {
        return false
      }

      return true
    },

    async send () {
      try {
        if (!this.validateForm()) {
          return
        }

        const formData = new FormData()

        if (this.allSelectedFiles && this.allSelectedFiles.length > 0) {
          this.allSelectedFiles.forEach(file => {
            formData.append('files', file, file.name)
          })
        }

        const userName = `${this.user.firstName} ${this.user.lastName}`
        const userEmail = this.user.email
        const companyName = this.user.companyGroup.company.name
        const groupName = this.user.companyGroup.name
        const pagePath = this.$route.fullPath

        formData.set('userName', userName)
        formData.set('userEmail', userEmail)
        formData.set('companyName', companyName)
        formData.set('groupName', groupName)
        formData.set('pagePath', pagePath)
        formData.set('emailSubject', this.emailSubject)
        formData.set('emailMessage', this.emailMessage)

        await this.emailSupport(formData)
        this.allSelectedFiles = []
        this.selectedFiles = []
        this.emailMessage = ''
        this.$messaging.addSuccessMessage('Your support email has been sent')
        this.close()
      } catch (err) {
        this.$messaging.addErrorMessage('Error submitting email')
      }
    },

    cancel () {
      this.close()
    },

    removeFile (fileName) {
      const index = this.allSelectedFiles.findIndex(file => {
        return (file.name === fileName)
      })

      if (index >= 0) {
        this.allSelectedFiles.splice(index, 1)
        this.selectedFiles = [...this.allSelectedFiles]
      }
    },

    ...mapActions(['emailSupport'])
  }
}

</script>

<style scoped>

.menu {
  height: 100%;
  width: 700px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
  padding: 30px 20px;
  overflow-y: auto;
}

.hidden-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #000;
  opacity: .75;
}

.close {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 7px;
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 32px;
  opacity: 1;
  font-weight: 500;
  cursor: pointer;
}

.sub-header {
  font-size: 13px;
  line-height: 1.4em;
  margin: 10px 20px 40px 20px;
}

.required-label:after {
  content: '*';
  color: var(--text-error-color);
  font-weight: bold;
  font-size: 11px;
  margin-right: 3px;
}

.attachment {
  margin-bottom: 10px;
}

.email-body {
  margin-bottom: 20px;
  line-height: 1.4em;
}

.email-body-text {
  height: 260px;
  margin-bottom: 20px;
}

div :deep(.file-upload) {
  margin-top: 0px;
}

.cancel-send-buttons {
  margin-top: 30px;
}

</style>
