import Vue from 'vue'

function complete (id) {
  const path = `/user-tasks/${id}/complete`
  return Vue.api.post(path)
}

function find (params = {}) {
  return Vue.api.get('/user-tasks', { params })
}

export default {
  complete,
  find
}
