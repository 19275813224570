export default [
  {
    path: 'sso',
    name: 'AdminSSOProvider',
    component: () => import('@/views/admin/sso/Index.vue'),
    children: [
      {
        path: 'list',
        alias: '',
        name: 'AdminSSOProviderList',
        component: () => import('@/views/admin/sso/List.vue'),
        meta: {
          headerText: 'SSO Provider Admin',
          headerBackRoute: 'AdminHome'
        }
      },
      {
        path: 'new',
        alias: '',
        name: 'AdminSSOProviderNew',
        component: () => import('@/views/admin/sso/New.vue'),
        meta: {
          headerText: 'New SSO Provider',
          headerBackRoute: 'AdminSSOProviderList'
        }
      },
      {
        path: 'edit/:id',
        alias: '',
        name: 'AdminSSOProviderEdit',
        component: () => import('@/views/admin/sso/Edit.vue'),
        meta: {
          headerText: 'Edit SSO Provider',
          headerBackRoute: 'AdminSSOProviderList'
        }
      }
    ]
  }
]
