export default [
  {
    path: '/deal/:dealId',
    name: 'DealCampaigns',
    component: () => import('@/views/deal/Index.vue'),
    meta: {
      headerBackRoute: 'CampaignSummary',
      headerText: '{{CLIENT}}'
    }
  }
]
