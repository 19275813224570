import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminCreateCampaignVersionType (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-version-types', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminUpdateCampaignVersionType (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .patch(apiRoot + '/admin/campaign-version-types/' + data.id, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminDeleteCampaignVersionType (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .delete(apiRoot + '/admin/campaign-version-types/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminUpdateCampaignVersionTypeSettings (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .patch(apiRoot + '/admin/campaign-version-types/' + data.companyId + '/settings', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminListCampaignVersionType (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/campaign-version-types/', { params: { companyId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
