import Vue from 'vue'

const actions = {
  adminFetchNotificationStrategyConfigurations (context, { companyId, type } = { }) {
    const path = '/admin/notification-strategy-configurations'
    const params = { companyId, type }
    return Vue.api.get(path, { params })
  },

  adminCreateNotificationStrategyConfiguration (context, { companyId, type, isEnabled } = { }) {
    const path = '/admin/notification-strategy-configurations'
    const data = { type, isEnabled }
    const params = { companyId }
    return Vue.api.post(path, { data, params })
  },

  adminUpdateNotificationStrategyConfiguration (context, { companyId, type, isEnabled, id } = { }) {
    const path = `/admin/notification-strategy-configurations/${id}`
    const data = { type, isEnabled }
    const params = { companyId }
    return Vue.api.patch(path, { data, params })
  }
}

export default actions
