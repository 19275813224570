import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  getClientList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/list', {
          params
        })
        .then(response => {
          context.commit('SET_CLIENT_LIST', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getClientsForCopyCampaigns (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/list-for-copy-campaigns', {
          params
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getMyClientsList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/list-for-my-clients', {
          params
        })
        .then(response => {
          context.commit('SET_CLIENT_LIST', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getClientListForCompany (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/list-for-company', {
          params
        })
        .then(response => {
          context.commit('SET_CLIENT_LIST', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getClientListForGroup (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/list-for-group', {
          params
        })
        .then(response => {
          context.commit('SET_CLIENT_LIST', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getAllClientsForGroup (context, companyGroupId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/list-for-group', {
          params: {
            limit: 100000,
            companyGroupId
          }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getClientListForAll (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/list-for-all', {
          params
        })
        .then(response => {
          context.commit('SET_CLIENT_LIST', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  findClientByName (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/find-by-name', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadClient (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/load/' + id)
        .then(response => {
          context.commit('SET_CLIENT', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadPotentialOwners (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/load-potential-owners', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  loadPotentialTeamMembers (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/load-potential-team-members', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  loadClientUserRoles (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/load-user-roles', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  uploadNewClientLogo (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/upload-new-client-logo', formData)
        .then(response => {
          context.commit('SET_NEW_CLIENT_LOGO', response.body.logo)
          resolve(response.body.logo)
        })
        .catch(error => reject(error.body))
    })
  },

  downloadNewClientLogo (context, url) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/download-new-client-logo', { url })
        .then(response => {
          context.commit('SET_NEW_CLIENT_LOGO', response.body.logo)
          resolve(response.body.logo)
        })
        .catch(error => reject(error.body))
    })
  },

  downloadClientLogo (context, url) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/download-new-client-logo', { url })
        .then(response => {
          context.commit('SET_CLIENT_LOGO', response.body.logo)
          resolve(response.body.logo)
        })
        .catch(error => reject(error.body))
    })
  },

  saveNewClient (context) {
    const client = Object.assign({}, context.state.new)
    const { geoProfiles } = client
    if (geoProfiles) {
      client.geoProfiles = geoProfiles.map(geo => {
        return {
          name: geo.name,
          type: geo.type,
          data: geo[geo.type],
          default: geo.default
        }
      })
    }
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/new', { client })
        .then(response => {
          context.commit('SET_CLIENT', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  uploadClientLogo (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/upload-logo', formData)
        .then(response => {
          context.commit('SET_CLIENT_LOGO', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  deleteClientForUser (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/delete-for-user', {
          id
        })
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  addClientForUser (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/add-for-user', {
          id
        })
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  showClientOnUsersList (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/show-on-users-list', {
          id
        })
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  removeClientFromUsersList (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/remove-from-users-list', {
          id
        })
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  editClient (context, params) {
    const data = Object.assign({}, params.data)
    delete data.industryId
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/edit', {
          id: params.id,
          data
        })
        .then(response => {
          context.commit('SET_CLIENT', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  addClientLocation (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/add-location', params)
        .then(response => {
          context.commit('ADD_LOCATION_TO_CLIENT', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  deleteClientLocation (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/delete-location', params)
        .then(response => {
          context.commit('REMOVE_LOCATION_FROM_CLIENT', params.addressIds)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  removeClientGeo (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/delete-geo', params)
        .then(response => {
          context.commit('REMOVE_GEO_FROM_CLIENT', params.geoProfileId)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  editClientLocation (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/edit-location', params)
        .then(response => {
          context.commit('REPLACE_LOCATION_ON_CLIENT', {
            id: params.addressId,
            newLocation: response.body
          })
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  editClientGeo (context, params) {
    const { clientId, geoProfiles } = params
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/edit-client-geo', { clientId, geoProfiles })
        .then(response => {
          context.commit('EDIT_CLIENT_GEO', response.body.geoProfiles)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  updateClientBillingDetails (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/update-billing-details', params)
        .then(response => {
          const { billingDetails, termsAndConditions } = response.body
          context.commit('SET_CLIENT_BILLING_DETAILS', billingDetails)
          context.commit('SET_CLIENT_TERMS_AND_CONDITIONS', termsAndConditions)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadClientIndustryCategories (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/industry-tree', { params: { id } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadAllBusinessLocations (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/load-all-business-location', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadAllBusinessLocationsDetails (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/load-all-business-location-details', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadClientExternalLinks (context, id) {
    context.commit('SET_CLIENT_EXTERNAL_LINKS', [])
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/load-external-links/' + id)
        .then(response => {
          context.commit('SET_CLIENT_EXTERNAL_LINKS', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadClientExternalLink (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/load-external-link/' + params.id, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  updateClientExternalLink (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/update-external-link', params)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  unlinkClientExternalLink (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/unlink-external-link', params)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  listExternalClients (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/list-external-clients', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadExternalAccounts (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/client/load-external-accounts/' + params.id, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  updateExternalAccounts (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/client/update-external-accounts', params)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
