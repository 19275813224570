<template>
  <div :class="classes">
    <a-label
      v-if="label !== ''"
      :class="warningMessage ? ' label-with-warning' : required ? ' required-label': ''"
    >
      {{ label }}
      <icon-link
        v-if="warningMessage"
        class="warning-icon"
        icon="Warning"
        w="12"
        h="12"
        :tooltip="warningMessage"
      />
    </a-label>

    <select
      v-model="localValue"
      :disabled="disabled"
      :multiple="multiple || null"
      :aria-invalid="invalid"
      :class="selectClasses"
      :data-test="datatest"
      @change="onChange"
    >
      <option
        v-for="( option, index ) in formOptions"
        :key="index"
        :class="(showDisabledOptionsAsInactive && option.disabled) ? 'inactive-option' : ''"
        :value="option.value"
        :disabled="showDisabledOptionsAsInactive ? false : option.disabled"
      >
        {{ option.text }}
      </option>
    </select>

    <invalid-input
      v-if="invalid && invalidText !== ''"
      :center-text="centerText"
    >
      {{ invalidText }}
    </invalid-input>

    <div
      v-if="helpText"
      class="help-text"
    >
      {{ helpText }}
    </div>
  </div>
</template>

<script>
import { includes, isArray, isObject, keys, filter } from 'lodash-es'

const VARIANTS = [
  'default', 'large', 'card-title', 'no-border'
]

export default {
  props: {
    datatest: {
      type: String,
      default: ''
    },

    value: {
      required: true,
      validator: value => true
    },

    options: {
      type: [Array, Object],
      default () {
        return []
      }
    },

    showDisabledOptionsAsInactive: {
      type: Boolean,
      default: false
    },

    warningMessage: {
      type: String,
      default: null
    },

    required: {
      type: Boolean,
      default: false
    },

    variant: {
      type: String,
      default: 'default',
      validator: type => includes(VARIANTS, type)
    },

    multiple: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    invalid: {
      type: Boolean,
      default: false
    },

    invalidText: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    centerText: {
      type: Boolean,
      default: false
    },

    removeCarat: {
      type: Boolean,
      default: false
    },

    helpText: {
      type: String,
      default: null
    },

    bottomMargin: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data () {
    return {
      localValue: this.value
    }
  },

  computed: {
    classes: {
      get () {
        const classes = ['select-container', this.variant]
        if (this.invalid) classes.push('invalid')
        if (this.centerText) classes.push('text-center')
        if (this.multiple) classes.push('multiple')
        if (this.disabled) classes.push('disabled-select')
        if (this.bottomMargin) classes.push('bottom-margin')
        return classes
      }
    },

    selectClasses: {
      get () {
        const classes = ['select']
        if (!this.removeCarat) classes.push('carat')
        if (!this.removeCarat && !this.multiple) classes.push('carat')
        if (this.warningMessage) classes.push('warning')
        return classes
      }
    },

    formOptions: {
      get () {
        if (isArray(this.options)) {
          return this.options.map(option => {
            const defaultValue = (isObject(option))
              ? (option.value !== undefined) ? option.value : option
              : option
            return this.formatOption(option, defaultValue)
          })
        }

        return keys(this.options).map(key => {
          const option = this.options[key] || {}
          const defaultValue = (isObject(option))
            ? (option.value !== undefined) ? option.value : key
            : key

          return this.formatOption(option, defaultValue)
        })
      }
    }
  },

  watch: {
    value (newVal, oldVal) {
      if (newVal !== oldVal && newVal !== this.localValue) {
        this.localValue = newVal
      }
    },

    localValue (newVal, oldVal) {
      this.$emit('input', this.localValue)
      this.$emit('change')
    }
  },

  methods: {
    formatOption (option, defaultValue = null) {
      if (isObject(option)) {
        return {
          value: (option.value !== undefined) ? option.value : defaultValue,
          text: (option.text !== undefined) ? option.text : defaultValue,
          disabled: (option.disabled !== undefined) ? option.disabled : false
        }
      }

      return {
        value: defaultValue,
        text: option || defaultValue,
        disabled: false
      }
    },

    onChange (event) {
      const selectedOptions = filter(event.target.options, option => option.selected)
        .map(option => {
          return this.formOptions[option.index].value
        })

      if (this.multiple) this.localValue = selectedOptions
      else {
        if (selectedOptions.length > 0) [this.localValue] = selectedOptions
        else this.localValue = null
      }
    }
  }
}
</script>

<style scoped>
.bottom-margin {
  margin-bottom: 20px;
}

.select {
  font-family: var(--font-stack);
  width: 100%;
  border-color: var(--input-border-color);
  border-style: solid;
  appearance: none;
  cursor: pointer;
  color: var(--text-color);
}

.disabled-select {
    opacity: 0.4;
}

.select:not(.multiple) {
  padding-right: 30px;
  background-color: white;
}

.carat:not(.multiple) {
  background-image: url("/images/carat_down.png");
  background-position: center right;
  background-repeat: no-repeat;
}

.select:focus {
  border-color: var(--input-focus-border-color);
}

.default .select {
  border-width: 1px;
  border-radius: var(--border-radius);
  font-size: 13px;
  padding: 7px 30px 7px 6px;
}

.large .select {
  border-width: 0px 0px 1px 0px;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.card-title .select {
  border-color: transparent;
  background-color: var(--card-background-color);
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}

.no-border .select {
  border-color: transparent;
  background-color: var(--card-background-color);
  padding-left: 0;
  padding-top: 0;
  padding-right: 22px;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 0;
}

.invalid .select,
select[aria-invalid='true'] {
  border-color: var(--text-error-color);
}

.select-container .invalid-input {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 0px;
}

.help-text {
  width: 100%;
  font-size: 12px;
  font-style: italic;
  color: var(--text-help-color);
  margin-top: 6px;
}

select.warning {
  border: solid var(--text-error-color) 1px;
}

.label-with-warning {
  display: flex !important;
}

.warning-icon {
  margin-left: 5px;
  height: 11px;
}

.warning-icon :deep(.icon),
.warning-icon :deep(.icon:hover) {
  fill: var(--text-warning-color) !important;
}

.required-label:after {
  content: '*';
  color: var(--text-error-color);
  font-weight: bold;
  font-size: 11px;
  margin-right: 3px;
}

.inactive-option {
  color: var(--text-help-color);
}

.select.disabled,
.select[disabled] {
  cursor: not-allowed;
}

</style>
