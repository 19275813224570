<template>
  <transition-group name="messaging-transition">
    <div
      v-for="(message) in getMessages"
      :key="message.key"
      class="message"
    >
      <message
        :message="message"
        @close="dismissMessage($event)"
      />
    </div>
  </transition-group>
</template>

<script>
import Message from './Message'
import { each, sortBy } from 'lodash-es'
import { mapMutations } from 'vuex'

export default {
  components: {
    Message
  },

  computed: {
    messages: {
      get () {
        return this.$store.state.messages
      }
    },

    getMessages: {
      get () {
        const messages = []
        each(this.messages, message => {
          if (message.length > 0) {
            messages.push(...message)
          }
        })
        return sortBy(messages, msg => msg.key)
      }
    }
  },

  methods: {
    ...mapMutations({
      // addErrorMessage: 'SET_ERROR_MESSAGES',
      // addInfoMessage: 'SET_INFO_MESSAGES',
      // addSuccessMessage: 'SET_SUCCESS_MESSAGES',
      dismissMessage: 'DISMISS_MESSAGE'
    })
  }
}
</script>

<style scoped>
.message {
  z-index: 9999999999999999;
  position: relative;
  margin-bottom: 5px;
  top: 10px;
  left: 0;
  right: 0;
  display: block;
}

.messaging-transition-enter-active,
.messaging-transition-leave-active {
  transition: all .3s ease-in;
}
.messaging-transition-enter,
.messaging-transition-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}
</style>
