import Vue from 'vue'
import { filter } from 'lodash-es'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  loadGroup (context, companyGroupId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/group/get', {
          params: { id: companyGroupId }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  retrieveAllGroups (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/group/list-for-company', {
          params: { companyId }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadProducts (context) {
    return new Promise((resolve, reject) => {
      context.commit('SET_PRODUCTS', null)

      Vue.http
        .get(apiRoot + '/product/list')
        .then(response => {
          const products = response.body
          context.commit('SET_PRODUCTS', filter(products, p => p.isDisabled === false))
          resolve(products)
        })
        .catch(error => reject(error))
    })
  },

  loadProducts2 (context, params) {
    return new Promise((resolve, reject) => {
      context.commit('SET_PRODUCTS', null)

      Vue.http
        .get(apiRoot + '/product/list-2', { params })
        .then(response => {
          const products = response.body
          context.commit('SET_PRODUCTS', filter(products, p => p.isDisabled === false))
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadProduct (context, productId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/product/load/' + productId)
        .then(response => {
          context.commit('SET_PRODUCT', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }

}

export default actions
