const mutations = {
  /**
   * @param state
   * @param shouldShowEmailSupport
   */
  SET_SHOULD_SHOW_EMAIL_SUPPORT (state, shouldShowEmailSupport) {
    state.shouldShowEmailSupport = shouldShowEmailSupport
  }
}

export default mutations
