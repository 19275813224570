import Vue from 'vue'

function list (params = {}) {
  const path = '/admin/recommendations/strategies'
  return Vue.api.get(path, { params })
}

function load (id) {
  const path = `/admin/recommendations/strategies/${id}`
  return Vue.api.get(path)
}

function create (data = {}) {
  const path = '/admin/recommendations/strategies'
  return Vue.api.post(path, { data })
}

function update (id, data = {}) {
  const path = `/admin/recommendations/strategies/${id}`
  return Vue.api.patch(path, { data })
}

function getRecommendation (id, data = {}) {
  const path = `/admin/recommendations/strategies/${id}/recommendations`
  return Vue.api.post(path, { data })
}

function updateRules (id, data = {}) {
  const path = `/admin/recommendations/strategies/${id}/rules`
  return Vue.api.patch(path, { data })
}

function updateFactors (id, data = {}) {
  const path = `/admin/recommendations/strategies/${id}/factors`
  return Vue.api.patch(path, { data })
}

const adjustments = {
  create (strategyId, data = {}) {
    const path = `/admin/recommendations/strategies/${strategyId}/adjustments`
    return Vue.api.post(path, { data })
  },

  updateOrder (strategyId, data = {}) {
    const path = `/admin/recommendations/strategies/${strategyId}/adjustments`
    return Vue.api.patch(path, { data })
  },

  load (strategyId, id) {
    const path = `/admin/recommendations/strategies/${strategyId}/adjustments/${id}`
    return Vue.api.get(path)
  },

  update (strategyId, id, data = {}) {
    const path = `/admin/recommendations/strategies/${strategyId}/adjustments/${id}`
    return Vue.api.patch(path, { data })
  },

  delete (strategyId, id) {
    const path = `/admin/recommendations/strategies/${strategyId}/adjustments/${id}`
    return Vue.api.delete(path)
  }
}

export default {
  list,
  load,
  create,
  update,
  getRecommendation,
  updateRules,
  updateFactors,
  adjustments
}
