import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminApiSubmissionsTaskList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/submission/api-tasks/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminLoadTaskLogs (context, taskId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/tasks/logs/' + taskId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminRerunTask (context, taskId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/tasks/add-to-queue/' + taskId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
