const state = {
  permissions: [],
  permissionGroups: [],

  company: null,
  showNewCompanyPane: false,

  group: null,
  showNewGroupPane: false,

  tags: [],
  tagGroups: [],
  datasetGroup: null,
  companies: null,
  groups: null,

  strategyPreview: {
    strategyId: null,
    filters: {
      customFields: []
    }
  }
}

export default state
