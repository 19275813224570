import Vue from 'vue'
const GlobalEventEmitter = new Vue()

const topics = new Set()

function emit (event, data) {
  GlobalEventEmitter.$emit(event, data)
}

function ready (event, data) {
  if (hasListener(event)) {
    emit(event, data)
  } else {
    addEventListener('listenerAdded', function (listener) {
      if (listener === event) {
        emit(event, data)
        removeEventListener('listenerAdded', this)
      }
    })
  }
}

function hasListener (event) {
  return topics.has(event)
}

function addEventListener (event, cb) {
  topics.add(event)
  GlobalEventEmitter.$on(event, cb)
  emit('listenerAdded', event)
}

function removeEventListener (event, cb) {
  topics.delete(event)
  GlobalEventEmitter.$off(event, cb)
  emit('listenerRemoved', event)
}

const GlobalEventsPlugin = {
  install (Vue, options) {
    Vue.prototype.$globalEvent = {
      emit,
      ready,
      addEventListener,
      removeEventListener
    }

    Vue.prototype.$bus = {
      $emit: emit,
      $ready: ready,
      $on: addEventListener,
      $off: removeEventListener
    }
  },

  emitGlobalEvent: emit
}

export default GlobalEventsPlugin
