import Vue from 'vue'

function load () {
  const path = '/admin/backend-config'
  return Vue.api.get(path)
}

function loadLive (params = {}) {
  const path = '/admin/backend-config/live'
  return Vue.api.get(path, { params })
}

function createLive (data = {}) {
  const path = '/admin/backend-config/live'
  return Vue.api.post(path, { data })
}

function deleteLive (data = {}) {
  const path = '/admin/backend-config/live'
  return Vue.api.delete(path, { data })
}

export default {
  load,
  loadLive,
  createLive,
  deleteLive
}
