import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  /*
   * Group admin actions
   */
  adminUploadGroupLogo (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/group/upload-logo', formData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateGroup (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/group/update', data)
        .then(response => {
          context.commit('SET_ADMIN_GROUP', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminGroupList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/group/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminRetrieveAllGroups (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/group/list-for-company', {
          params: { companyId }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadGroupToAdmin (context, groupId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/group/get', {
          params: { id: groupId }
        })
        .then(response => {
          context.commit('SET_ADMIN_GROUP', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  adminLoadGroupInfoToAdmin (context, groupId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/group/get', {
          params: { id: groupId }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminListGroupUserRoles (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/group/user-roles', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminCreateGroup (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/group/create', data)
        .then(response => {
          context.commit('SET_ADMIN_GROUP', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
