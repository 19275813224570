import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  adminLoadCampaignField (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/campaign-fields/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListAllCampaignFields (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/campaign-fields/list', { params })
        .then(response => {
          context.commit('SET_CAMPAIGN_FIELDS', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateCampaignFieldSettings (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/campaign-fields/update', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
