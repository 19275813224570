export default {
  google: {
    maps: {
      apiKey: 'AIzaSyCGXMgM2THiVdCaNk3_TdHrge11LaJb8YA'
    },
    analytics: {
      apiKey: 'G-WG185ECMVS'
    },
    oauth: {
      clientId: '409352233542-o8ejoeb099eh25svjro70moinje3sm1j.apps.googleusercontent.com'
    }
  },
  mapbox: {
    apiKey: 'pk.eyJ1IjoiYWR2aXNyIiwiYSI6ImNsbzRoZnEwdzAxb2oyam54YnNzZ2R0dTEifQ.VPvMN8Mx-W73IVoaoThjQw'
  },
  rollbar: {
    accessToken: '688eb23ca4e249708000bee2415352a3'
  }
}
