import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  loadTagObjectsForPresentationSlide (context, slideId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/tags/load-slide-tags', { params: { slideId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  loadTagObjectsForPresentationSlides (context, slideIds) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/tags/load-tags-for-slides', { slideIds })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  addTagsToSlides (context, { userId, slides, tags }) {
    return new Promise((resolve, reject) => {
      Vue.http.post(apiRoot + '/tags/tag-slides', { userId, slides, tags })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  createTag (context, tag) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/tags/create-tag', tag)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  editTag (context, tag) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/tags/edit-tag', tag)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  deleteTag (context, tag) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/tags/delete-tag', tag)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  deleteTagGroup (context, tagGroup) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/tags/delete-tag-group', tagGroup)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  createTagGroup (context, tagGroup) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/tags/create-tag-group', tagGroup)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  editTagGroup (context, tagGroup) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/tags/edit-tag-group', tagGroup)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },
  listTags (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/tags/list-tags', {
          params: { companyId }
        })
        .then(response => {
          context.commit('SET_TAGS', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  listTagGroups (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/tags/list-tag-groups', {
          params: { companyId }
        })
        .then(response => {
          context.commit('SET_TAG_GROUPS', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  loadTagsWithTagGroup (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/tags/list-tag-with-group',
          { params: { companyId } })
        .then(response => {
          context.commit('SET_TAG_GROUPS', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
