export default [
  {
    path: 'external-crm',
    name: 'AdminExternalCRM',
    component: () => import('@/views/admin/externalcrm/Index.vue'),
    children: [
      {
        path: 'list',
        alias: '',
        name: 'AdminExternalCRMList',
        component: () => import('@/views/admin/externalcrm/List.vue'),
        meta: {
          headerText: 'External System Admin',
          headerBackRoute: 'AdminHome'
        }
      },
      {
        path: 'new',
        name: 'AdminExternalCRMNew',
        component: () => import('@/views/admin/externalcrm/New.vue'),
        meta: {
          headerText: 'New External System',
          headerBackRoute: 'AdminExternalCRMList'
        }
      },
      {
        path: 'edit/:id',
        name: 'AdminExternalCRMEdit',
        component: () => import('@/views/admin/externalcrm/Edit.vue'),
        meta: {
          headerText: 'Edit External System',
          headerBackRoute: 'AdminExternalCRMList'
        }
      }
    ]
  }
]
