import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  adminListFulfillmentModule (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/fulfillment-module/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadFulfillmentModule (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/fulfillment-module/load/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminCreateFulfillmentModule (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/fulfillment-module/create', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminUpdateFulfillmentModule (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/fulfillment-module/update', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminDeleteFulfillmentModule (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/fulfillment-module/delete', { id })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  }
}

export default actions
