<template>
  <button-drop-down
    v-if="canCreateClientForCompany && companyGroupOptionsForClientCreation.length > 1"
    v-model="selectedCompanyGroupIdForClientCreation"
    :options="companyGroupOptionsForClientCreation"
    variant="default"
    label="Client"
    class="add-new-client-button-dropdown"
    align-left
    @input="addClientAndEmitClose"
  >
    <spoof-user-icon
      h="24"
      w="24"
      class="mr-2 spoof-user-icon"
    />Client
  </button-drop-down>
  <a-link
    v-else-if="companyGroupOptionsForClientCreation.length === 1"
    class="quick-link ml-3 font-medium d-flex items-center my-2"
    @click="addClientAndEmitClose"
  >
    <spoof-user-icon
      h="24"
      w="24"
      class="mr-2 spoof-user-icon"
    /> Client
  </a-link>
</template>

<script>
import {
  SpoofUserIcon
} from '@/components/icons'
import AddClientMixin from '@/mixins/AddClient'
export default {
  name: 'AddClient',

  components: {
    SpoofUserIcon
  },

  mixins: [AddClientMixin],

  methods: {
    addClientAndEmitClose () {
      this.addClient('quickCreate')
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.spoof-user-icon {
  fill: currentColor;
}
.add-new-client-button-dropdown >>> button {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  background: none;
  padding: 0;
  margin: 0.5rem 0.75rem;
  color: var(--link-color)
}
.add-new-client-button-dropdown >>> button:hover {
  background: none;
  color: var(--link-active-color)
}

.add-new-client-button-dropdown >>> ul.options {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-100%); /* Move to the left by its full width */
}
</style>
