export default [
  {
    path: 'user-roles',
    name: 'AdminUserRoles',
    component: () => import('@/views/admin/userrole/List.vue'),
    meta: {
      headerText: 'User Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'user-admin'
    }
  },
  {
    path: 'user-roles/new',
    name: 'AdminNewUserRole',
    component: () => import('@/views/admin/userrole/New.vue'),
    meta: {
      headerText: 'New User Role',
      headerBackRoute: 'AdminUserRoles',
      subNav: 'none'
    }
  },
  {
    path: 'user-roles/edit/:id',
    name: 'AdminEditUserRole',
    component: () => import('@/views/admin/userrole/Edit.vue'),
    meta: {
      headerText: 'Edit User Role',
      headerBackRoute: 'AdminUserRoles',
      subNav: 'none'
    }
  }
]
