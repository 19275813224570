import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  /**
   * returns a presentation object
   * @param { number } campaignId - the ID of the campaign
   * @returns { Presentation }
   */
  loadPresentationForCampaign (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/presentation/load-for-campaign', { params: { id: campaignId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadPresentationCodeForCampaign (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/presentation/load-code-for-campaign', { params: { id: campaignId } })
        .then(response => {
          resolve(response.body.code)
        })
        .catch(error => reject(error))
    })
  },

  loadIdByCampaignCode (context, linkCode) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/presentation/load-id-by-campaign-code', { params: { code: linkCode } })
        .then(response => {
          resolve(response.body.id)
        })
        .catch(error => reject(error))
    })
  },

  loadPresentationIdForCampaign (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/presentation/load-id-for-campaign', { params: { id: campaignId } })
        .then(response => {
          resolve(response.body.id)
        })
        .catch(error => reject(error))
    })
  },

  savePresentationForCampaign (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/presentation/save-for-campaign', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  uploadPresentationImage (context, data) {
    const formData = new FormData()
    formData.append('image', data.file, data.file.name)
    formData.append('campaignId', data.campaignId)
    formData.append('filename', data.file.name)

    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/presentation/upload-image', formData)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  downloadNewPresentationImage (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/presentation/download-image', data)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  addExistingImageToPresentation (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/presentation/add-existing-image', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadPresentationTemplatesForCampaign (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/presentation/load-templates-for-campaign', {
          params: {
            id: campaignId
          }
        })
        .then(response => {
          const templates = response.body
          context.commit('SET_PRESENTATION_TEMPLATES', templates)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadPresentationSlidesForCampaign (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/presentation/load-slides-for-campaign', {
          params: {
            id: campaignId
          }
        })
        .then(response => {
          const slides = response.body
          context.commit('SET_PRESENTATION_SLIDES', slides)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  resetCampaignPresentation (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/presentation/reset-for-campaign', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  createPresentationSlide (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/presentation/create-slide', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadPresentationLayoutsForCampaign (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/presentation/load-layouts-for-campaign', {
          params: {
            id: campaignId
          }
        })
        .then(response => {
          const slides = response.body
          context.commit('SET_PRESENTATION_LAYOUTS', slides)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
