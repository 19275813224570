<template>
  <div>
    <div class="current-version">
      <span>Build version: {{ buildVersion }}</span>
      <span>Current version: {{ currentVersion }}</span>
    </div>
    <div
      v-if="!isCurrent"
      class="new-version-alert"
    >
      <strong>The Advisr platform has been updated!</strong><br>
      Please <a-link @click="refreshWindow">
        refresh your browser
      </a-link> to update<br>
      to the latest version.
      <span v-if="shouldShowAutorefreshTime"><br><br>Page will auto refresh in {{ timeUntilAutoRefreshFormatted }}</span>
    </div>

    <a-dialog
      v-if="isRedirectedFromIO"
      button-text="Ok"
      type="info"
      vertical-height="short"
      :button-action="refreshWindow"
      @close-dialog="clearIoRedirect"
    >
      <template #header>
        Advisr.io is now Advisr.com
      </template>
      <template #content>
        <div class="text-center redirect-subtext">
          We have updated our domain name from <strong>advisr.io</strong> to <strong>advisr.com</strong>.
        </div>
        <div class="text-center redirect-subtext">
          You can use your existing login credentials, and your campaign data is the same as before.
          Please update your Advisr bookmarks.
        </div>
      </template>
    </a-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { isNil } from 'lodash-es'

// Build version
import { build } from '@/config'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

const AUTO_REFRESH_TIMEOUT = 300000

export default {
  data () {
    return {
      isCurrent: true,
      checkInterval: 10000,
      checkVersionTimer: null,
      lastInteractionTimestamp: null,
      currentTimeInterval: null,
      currentTime: new Date().getTime()
    }
  },

  computed: {
    isRedirectedFromIO () {
      return this.$store.state.ioRedirected
    },

    buildVersion () {
      return build.version
    },

    currentVersion () {
      return this.$store.state.currentVersion
    },

    timeUntilAutoRefresh () {
      return Math.max(
        AUTO_REFRESH_TIMEOUT - (this.currentTime - this.lastInteractionTimestamp),
        0
      )
    },

    timeUntilAutoRefreshFormatted () {
      return dayjs.duration(this.timeUntilAutoRefresh).format('mm:ss')
    },

    shouldShowAutorefreshTime () {
      return this.timeUntilAutoRefresh <= 120000
    }
  },

  created () {
    if (!isNil(this.buildVersion)) this.checkVersionTimer = setInterval(this.checkLatestVersion, this.checkInterval)
  },

  destroyed () {
    clearInterval(this.checkVersionTimer)
    if (this.currentTimeInterval) clearInterval(this.currentTimeInterval)
    window.removeEventListener('click', this.updateLastInteraction)
  },

  methods: {
    async checkLatestVersion () {
      try {
        await this.getCurrentVersion()
      } catch (error) {}

      if (this.buildVersion !== this.currentVersion) {
        if (this.currentVersion === -1) {
          // App transitioned to maintenance mode
          // Refresh app to show temporary page
          this.refreshWindow()
        } else if (this.buildVersion === -1) {
          // App was in maintenance mode but is no longer
          // Refresh app to reload app
          this.refreshWindow()
        } else if (this.isCurrent) {
          this.currentTimeInterval = setInterval(this.checkIfPageShouldAutoRefresh, 1000)
          window.addEventListener('click', this.updateLastInteraction)
          this.updateLastInteraction()
          this.isCurrent = false
        }
      }
    },

    updateLastInteraction () {
      this.lastInteractionTimestamp = new Date().getTime()
    },

    checkIfPageShouldAutoRefresh () {
      this.currentTime = new Date().getTime()
      if (!this.isCurrent) {
        if (this.timeUntilAutoRefresh <= 0) this.refreshWindow()
      }
    },

    refreshWindow () {
      location.reload()
    },

    clearIoRedirect () {
      this.setIoRedirected(false)
    },

    ...mapActions(['getCurrentVersion']),

    ...mapMutations({
      setIoRedirected: 'SET_IO_REDIRECTED'
    })
  }
}
</script>

<style scoped>
.new-version-alert {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 5000;
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.redirect-subtext {
  /* margin-bottom: 15px; */
  line-height: 1.4em;
}

.current-version {
  display: none;
}
</style>
