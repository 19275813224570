const mutations = {
  SET_TAGS (state, tags) {
    state.tags = tags
  },
  SET_TAG_GROUPS (state, tagGroups) {
    state.tagGroups = tagGroups
  }
}

export default mutations
