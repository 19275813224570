import { pick } from 'lodash-es'

import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  loadWeeklyCampaignReport (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/reports/weekly-campaign', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadProductReport (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/reports/product', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getLookerEmbededURL (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/reports/embed-looker-url', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadUserSellerScoreReport (context, params = {}) {
    const queryParams = pick(params, [
      'date',
      'period'
    ])

    return Vue.http
      .get(`${apiRoot}/reports/user-seller-score`, { params: queryParams })
      .then(response => {
        if (response.code === 204) {
          context.commit('SET_USER_SELLER_SCORE', null)
        } else if (response.body) {
          context.commit('SET_USER_SELLER_SCORE', response.body)
        } else {
          throw new Error('Could not load user seller score')
        }
      })
  },

  loadUserSellerScoreReports (context, params = {}) {
    const queryParams = pick(params, [
      'date',
      'period',
      'limit',
      'page'
    ])

    return Vue.http
      .get(`${apiRoot}/reports/user-seller-scores`, { params: queryParams })
  },

  loadGroupSellerScoreReport (context, params = {}) {
    const queryParams = pick(params, [
      'date',
      'period'
    ])

    return Vue.http
      .get(`${apiRoot}/reports/group-seller-score`, { params: queryParams })
      .then(response => {
        if (response.code === 204) {
          context.commit('SET_GROUP_SELLER_SCORE', null)
        } else if (response.body) {
          context.commit('SET_GROUP_SELLER_SCORE', response.body)
        } else {
          throw new Error('Could not load group seller score')
        }
      })
  }
}

export default actions
