import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminPresentationTemplateList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/template/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationTemplateListForCompany (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/template/list-all', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationTemplateDisable (context, templateId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/template/update', {
          id: templateId,
          isActive: false
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationTemplateEnable (context, templateId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/template/update', {
          id: templateId,
          isActive: true
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationTemplateSetDefaultCategory (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/template/set-default', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationTemplateLoad (context, templateId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/template/load/' + templateId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationTemplateLoadPreview (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/template/load-preview', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationTemplateCreate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/template/create', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminPresentationTemplateUpdate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/template/update', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminPresentationTemplateSavePresentation (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/template/save-presentation', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminPresentationTemplateUploadImage (context, data) {
    const formData = new FormData()
    formData.append('image', data.file, data.file.name)
    formData.append('id', data.id)
    formData.append('filename', data.file.name)

    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/template/upload-image', formData)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  adminPresentationTemplateDownloadImage (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/template/download-image', data)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  adminPresentationSlideList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/slide/list-with-tags', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationSlideLoad (context, templateId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/slide/load/' + templateId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationSlideCreate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/slide/create', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminPresentationSlideUpdate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/slide/update', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminPresentationSlideSaveLayout (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/slide/save-layout', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminPresentationSlideUploadImage (context, data) {
    const formData = new FormData()
    formData.append('image', data.file, data.file.name)
    formData.append('id', data.id)
    formData.append('filename', data.file.name)

    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/slide/upload-image', formData)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  adminPresentationSlideDownloadImage (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/slide/download-image', data)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  adminPresentationSlideLoadForTemplate (context, templateId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/slide/load-for-template/' + templateId)
        .then(response => {
          const slides = response.body
          context.commit('SET_PRESENTATION_SLIDES', slides)
          resolve(slides)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationSlideSaveCondition (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/slide/save-condition', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminGeneratePresentationSlideExport (context, params) {
    return new Promise((resolve, reject) => {
      const serverRequest = async () => {
        try {
          const response = await Vue.http
            .get(apiRoot + '/admin/presentation/slide/generate-export', { params })

          if (response.status === 200) {
            const fileUrl = `${apiRoot}/admin/presentation/slide/download-export?id=${params.id}&type=${params.type}`
            return resolve(fileUrl)
          } else if (response.status === 202) setTimeout(serverRequest, 1000)
          else reject(new Error('Error requesting slide export: ' + response.body))
        } catch (error) {
          if (error instanceof Error) reject(error)
          else reject(new Error(`Error requesting slide export, status: ${error.status}, url ${error.url}`))
        }
      }

      serverRequest()
    })
  },

  adminPresentationLayoutList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/layout/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationLayoutLoad (context, templateId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/layout/load/' + templateId)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationLayoutCreate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/layout/create', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminPresentationLayoutUpdate (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/layout/update', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminPresentationLayoutSaveLayout (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/layout/save-layout', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminPresentationLayoutLoadForTemplate (context, templateId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/layout/load-for-template/' + templateId)
        .then(response => {
          const layouts = response.body
          context.commit('SET_PRESENTATION_LAYOUTS', layouts)
          resolve(layouts)
        })
        .catch(error => reject(error.body))
    })
  },

  adminPresentationLayoutUploadImage (context, data) {
    const formData = new FormData()
    formData.append('image', data.file, data.file.name)
    formData.append('id', data.id)
    formData.append('filename', data.file.name)

    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/layout/upload-image', formData)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  adminPresentationLayoutDownloadImage (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/presentation/layout/download-image', data)
        .then(result => {
          resolve(result.body)
        })
        .catch(reject)
    })
  },

  adminPresentationLayoutLoadForSlideTemplate (context, slideTemplateId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/presentation/layout/load-for-slide-template/' + slideTemplateId)
        .then(response => {
          const layouts = response.body
          context.commit('SET_PRESENTATION_LAYOUTS', layouts)
          resolve(layouts)
        })
        .catch(error => reject(error.body))
    })
  }

}

export default actions
