import Vue from 'vue'

const actions = {
  adminFetchExternalIntegrationInstallations (context, { companyId, type } = { }) {
    const path = '/admin/external-integration-installations'
    const params = { companyId, type }
    return Vue.api.get(path, { params })
  },

  adminDeleteExternalIntegrationInstallations (context, { companyId, id } = { }) {
    const path = `/admin/external-integration-installations/${id}`
    const params = { companyId }
    return Vue.api.delete(path, { params })
  }
}

export default actions
