import { assets } from '@/config'
const { assetsRoot, clientLogoAssetPath } = assets

const getters = {

  /**
   * @param state
   */
  getClientLogoURL: state => {
    if (state.logo) return assetsRoot + clientLogoAssetPath + state.logo
    return null
  }

}

export default getters
