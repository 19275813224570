import Vue from 'vue'
import { pick, omit } from 'lodash-es'

function create (params = {}) {
  const { commentableId: requestForProposalId } = params
  const data = omit(params, ['companyId', 'companyGroupId', 'commentableId'])
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api.post(`/request-for-proposals/${requestForProposalId}/comments`, { params, data })
}

function list (params = {}) {
  const { requestForProposalId } = params
  const data = omit(params, ['companyId', 'companyGroupId', 'requestForProposalId'])
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api.get(`/request-for-proposals/${requestForProposalId}/comments`, { params, data })
}

function _delete (params = {}) {
  const { commentableId: requestForProposalId, id } = params
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api.delete(`/request-for-proposals/${requestForProposalId}/comments/${id}`, { params })
}

export default {
  create,
  list,
  delete: _delete
}
