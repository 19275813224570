const mutations = {
  SET_PRESENTATION_IMAGE_UPLOAD (state, data) {
    state.imageUploadMethod = data.uploadMethod
    state.imageDownloadMethod = data.downloadMethod
    state.imageUploadParams = data.params
  },

  SET_PRESENTATION_TEMPLATES (state, templates) {
    state.templates = templates
  },

  SET_PRESENTATION_SLIDES (state, slides) {
    state.slides = slides
  },

  SET_PRESENTATION_DELETED_SLIDES (state, deletedSlides) {
    state.deletedSlides = deletedSlides
  },

  SET_PRESENTATION_PRODUCT_IMAGES (state, images) {
    state.productImages = images
  },

  SET_TEMPLATE_NAME (state, name) {
    state.templateName = name
  },

  SET_SLIDE_NAME (state, name) {
    state.slideName = name
  },

  SET_PRESENTATION_LAYOUTS (state, layouts) {
    state.layouts = layouts
  }
}

export default mutations
